import type { SizeStr } from "@/util/shorthand";

type Weights = | "thin" | "extralight" | "light" | "normal" | "medium" | "semibold" | "bold" | "extrabold" | "black";

export interface FontArgs
{
    family: string;
    size?: `${number}%`,
    line?: `${number}%`,
    spacing?: `${number}%`,
    color?: string,
    variant?: Weights | "italics" | `${Weights}-italics`;
    transform?: "none" | "uppercase" | "lowercase" | "capitalize" | "full-width";
    shadow?: {
        x: SizeStr,
        y: SizeStr,
        blur: SizeStr,
        color: string,
    },
    decoration?: {
        style: "solid" | "double" | "dotted" | "dashed" | "wavy",
        position: "above" | "below" | "behind" | "through",
        color: string,
    },
}

export interface Font
{
    apply(props?: React.CSSProperties): React.CSSProperties,
}

const weights = {
    thin: "100",
    extralight: "200",
    light: "300",
    normal: "normal",
    medium: "500",
    semibold: "600",
    bold: "bold",
    extrabold: "800",
    black: "900",
} as const satisfies Record<Weights, string>;

export const buildFont = (font: FontArgs): Font =>
{
    const weight = (() =>
    {
        if (!font.variant) return;

        const split = font.variant.indexOf("-");
        const weight = split < 0? font.variant: font.variant.substring(0, split);

        return weights[weight as Weights];
    })();

    const skew = font.variant && (font.variant.endsWith("italics") ? "italic": "normal");

    const spacing = (() =>
    {
        const f = parseFloat(font.spacing ?? "") / 100;

        return isNaN(f)? undefined: `calc(1em * ${f})`;
    })();

    const shadow = font.shadow && [
        font.shadow.x,
        font.shadow.y,
        font.shadow.blur,
        font.shadow.color,
    ].join(" ");

    const { decoration, offset } = (() =>
    {
        let decoration: string | undefined;
        let offset: string | undefined;

        if (font.decoration)
        {
            switch (font.decoration.position)
            {
                case "above":
                    decoration = "overline";

                    break;
                case "below":
                    decoration = "underline";

                    break;
                case "behind":
                    decoration = "underline";
                    offset = "-0.6ex";

                    break;
                case "through":
                    decoration = "line-through";

                    break;
            }

            decoration = [
                decoration,
                font.decoration.style,
                font.decoration.color
            ].join(" ");
        }

        return { decoration, offset };
    })();

    const style: React.CSSProperties & { ["--font-theme"]?: string } = {
        ["--font-theme"]: font.family,
        fontSize: font.size,
        lineHeight: font.line,
        letterSpacing: spacing,
        color: font.color,
        fontWeight: weight,
        fontVariant: skew,
        textTransform: font.transform,
        textShadow: shadow,
        textDecoration: decoration,
        textUnderlineOffset: offset,
    };

    return {
        apply: (props) => ({
            ...style,
            ...props,
        })
    };
};
