/* eslint-disable no-console */
import Logo from "@/assets/img/magazine/logo.png";
import Barcode from "@/assets/img/magazine/barcode.png";

/**
 * Singleton class that loads the magazine assets
 * @class MagazineAssets
 * @singleton
 * @private
 */
class MagazineAssets
{
    private static instance: MagazineAssets|null = null;

    public logo: string|null = null;

    public barCode: string|null = null;

    private constructor ()
    {
        // Do Nothing
        this.loadAssets();
    }

    private async imgToBlob (url: string)
    {
        return fetch(url).then(async (res) => await res.blob());
    }

    private async blobToDataUrl (blob: Blob)
    {
        return new Promise<string>((resolve, reject) =>
        {
            const reader = new FileReader();

            reader.onload = () => void resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    private loadAssets ()
    {
        // Load the logo
        this.imgToBlob(Logo).then(async (blob) =>
        {
            this.logo = await this.blobToDataUrl(blob);
        })
            .catch(console.error);

        // Load the barcode
        this.imgToBlob(Barcode).then(async (blob) =>
        {
            this.barCode = await this.blobToDataUrl(blob);
        })
            .catch(console.error);
    }

    public static getInstance ()
    {
        return this.instance ?? (this.instance = new MagazineAssets());
    }
}

/**
 * Singleton instance of the MagazineAssets class
 * @type {MagazineAssets}
 */
export default MagazineAssets.getInstance();
