import { twMerge } from "tailwind-merge";

export default function NotchedBlock({
    children,
    className,
}: {
    children: React.ReactNode
    className?: string;
})
{
    return (
        <div
            className={
                twMerge(Wind.style({
                    backgroundColor: "bg-parchment",
                    height: "h-auto",
                    paddingY: "py-3",
                    paddingX: "px-4",
                    minWidth: "min-w-8",
                }).class, className)}
            style={{
                clipPath: "polygon(0% 100%, 100% 100%, 100% 1.5rem, calc(100% - 1.5rem) 0%, 0% 0%)",
                WebkitClipPath: "polygon(0% 100%, 100% 100%, 100% 1.5rem, calc(100% - 1.5rem) 0%, 0% 0%)",
            }}
        >
            {children}
        </div>
    );
}
