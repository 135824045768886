export type Shorthand<T> =
    | []
    | [s: T]
    | [y: T, x: T]
    | [t: T, x: T, b: T]
    | [t: T, r: T, b: T, l: T];

export type Expanded<T> = [top: T, right: T, bottom: T, left: T];

export function shorthand<T, U>(
    list: Shorthand<T>,
    fallback: T,
    transform: (i: T) => U,
): Expanded<U>;

export function shorthand<T>(
    list: Shorthand<T>,
    fallback: T,
): Expanded<T>;

export function shorthand<T, U = void>(
    list: Shorthand<T>,
    fallback: T,
    transform?: (i: T) => U,
): Expanded<U> | Expanded<T>
{
    /* eslint-disable @typescript-eslint/no-magic-numbers */

    let bottom, left, right, top;

    switch (list.length)
    {
        case 0:
            bottom = left = right = top = fallback;

            break;
        case 1:
            [top] = list;
            bottom = left = right = top;

            break;
        case 2:
            [top, left] = list;
            bottom = top;
            right = left;

            break;
        case 3:
            [top, right, bottom] = list;
            left = right;

            break;
        case 4:
            [top, right, bottom, left] = list;

            break;
    }

    return transform
        ? [ transform(top), transform(right), transform(bottom), transform(left) ]
        : [ top, right, bottom, left ];

    /* eslint-enable @typescript-eslint/no-magic-numbers */
}

export type Size = number | "px";

export type Sizes = Shorthand<Size>;

export type SizeStr = `${number}rem` | "1px";

export const string2size = (s: string): Size =>
{
    s = s.trim();

    if (s === "px") return s;

    const n = parseFloat(s);

    if (isNaN(n)) throw new Error(`not a number: "${s}"`);

    return n;
};

export const string2sizes = (str: string): Sizes =>
{
    const split = str.split(/\s+/u).map(string2size);

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (split.length > 4) throw new Error("Too many elements");

    return split as Sizes;
};

// 4: tw to em
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const size2string = (n: Size): SizeStr => (n === "px") ? "1px" : `${n/4}rem`;

export const sizes2string = (s: Sizes): string => s.map(size2string).join(" ");

export const sizes2strings = (s: Sizes) => shorthand(s, 0, size2string);
