import type { ReactState } from "@/game/types/ReactTypes";
import type { ReportedSubmissionDataType } from "./ReportTypes";
import type React from "react";
import { useState } from "react";
// import DialogButton from "@/assets/img/symbolic/dialog-button.svg?react";

type Props = {
} & ReactState<"reportStage", number> & ReactState<"reportSubmissionData", ReportedSubmissionDataType>;

const ReportDetails: React.FC<Props> = ({
    reportStage,
    setReportStage,
    reportSubmissionData,
    setReportSubmissionData
}) =>
{
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (event: React.SyntheticEvent) =>
    {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            email: { value: string };
            message: { value: string }
        };

        setReportSubmissionData({
            ...reportSubmissionData,
            reporterEmail: target.email.value,
            message: target.message.value
        });

        setReportStage(reportStage + 1);
    };

    const textInputStyles = Wind.style({
        display: "block",
        fontSize: "text-base",
        backgroundColor: "bg-[#1B2F4E]",
        color: "text-white",
        width: "w-full",
        marginBottom: "mb-4",
        paddingY: "py-2",
        paddingLeft: "pl-4",
        borderBottomColor: "border-b-[#2E4B78]",
        borderBottomWidth: "border-b",
        "::placeholder": {
            color: "placeholder:text-[#607BA6]",
            opacity: "placeholder:opacity-100"
        },
        ":focus": {
            outlineStyle: "focus:outline-none",
        }
    }).class;

    return (
        <form
            id='report-form'
            onSubmit={event =>
            {
                if (isSubmitting) return;
                setIsSubmitting(true);
                handleSubmit(event);
            }}
        >
            <p
                className={Wind.style({
                    color: "text-[#B3C2DB]",
                    marginBottom: "mb-8",
                    fontSize: "text-base",
                    fontFamily: "font-default",
                    fontWeight: "font-normal",
                }).class}
            >
                Give us any additional details about your report.
            </p>
            <label
                htmlFor="reporter-email"
                className={Wind.style({
                    screenReaders: "sr-only"
                }).class}
            >
                Your email (optional)
            </label>
            <input
                id="reporter-email"
                name='email'
                placeholder="Your email (optional)"
                type='email'
                className={`
                    ${textInputStyles} 
                    ${Wind.style({

        }).class}
                `}
            />

            <label
                htmlFor="reporter-message"
                className={Wind.style({
                    screenReaders: "sr-only"
                }).class}
            >
                Enter any additional details (optional)
            </label>
            <input
                id="reporter-message"
                placeholder="Enter any additional details (optional)"
                name='message'
                type='text'
                className={`
                    ${textInputStyles} 
                    ${Wind.style({
            marginBottom: "mb-8"
        }).class}
                `}
            />
            <div
                className={`
                submit-wrapper 
                ${Wind.style({
            width: "w-full",
            textAlign: "text-center"
        }).class}
            `}
            >

                <input
                    className={Wind.style({
                        marginBottom: "mb-3",
                        padding: "p-2",
                        width: "w-[11.25rem]",
                        backgroundColor: "bg-[url('/src/assets/img/symbolic/dialog-button.svg')]",
                        backgroundRepeat: "bg-no-repeat",
                        backgroundSize: "bg-contain",
                        backgroundPosition: "bg-center",
                        fontWeight: "font-semibold",
                        fontSize: "text-base",
                        fontFamily: "font-default",

                    }).class}
                    type='submit'
                    value='Send Report'
                />
            </div>

        </form>
    );
};

export default ReportDetails;
