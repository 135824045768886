// FIXME: fix these
/* eslint-disable react-compiler/react-compiler */

import GameNode from "./GameNode";
import { useGameContext } from "@/game/GameContext";
import React from "react";
import LowerLobbyControls from "./components/LowerLobbyControls";
import { twMerge } from "tailwind-merge";
import ThemeContext from "@/game/themes/ThemeContext";
import useTheme from "@/game/themes/useTheme";
import Container from "./components/Container";
import { EventState } from "@/game/events/ProcessedEventMap";
import ReportDialog from "./components/ReportSystem/ReportDialog";
import type { ProcessedEvent } from "@/game/events/ProcessedEvent";
import type { Type } from "@/realize-shared/model/Event";
// import { useAppContext } from "@/game/AppContext";

const GameContent = ({
    className = "",
    hideRoomCode,
}: {
    className?: string;
    hideRoomCode?: boolean;
}) =>
{
    const { events, room, rendered, theme } = useGameContext();

    // const { setWaiting } = useAppContext()
    const {
        slices,
        container,
    } = useTheme().Root;

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const latestRef = React.useRef<HTMLDivElement>(null);

    const [lastNode, setLastNode] = React.useState<HTMLDivElement | null>(null);
    const [scroll, setScroll] = React.useState(true);
    const [reportDialogEvent, setDialogEvent] = React.useState<ProcessedEvent<Type>|null>(null);

    const { nodes, latest } = events.reduce((current, event, index) =>
    {
        if (index <= rendered)
        {
            current.nodes.push((
                <ThemeContext.Provider
                    value={event.theme.data}
                    key={`${room.id}/${index}/${event.type}`}
                >
                    <GameNode
                        {...event}
                        setDialogEvent={setDialogEvent}
                        eventRef={latestRef}
                    />
                </ThemeContext.Provider>
            ));

            if (latestRef.current !== null) current.latest = latestRef.current;
        }

        return current;
    }, {
        nodes: new Array<React.ReactNode>(),
        latest: null as HTMLDivElement | null,
    });

    if (lastNode !== latest)
    {
        setLastNode(latest);

        if (scroll)
        {
            Logger.log("scrolling to bottom", scroll);

            scrollRef.current?.scrollTo({
                top: scrollRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }

    if (events[0] as unknown === undefined)
    {
        return <div />;
    }

    return (
        <>
            <Container
                slices={slices}
                {...container}
                outer={{
                    style: {
                        "--font-theme": theme.data.Root.font,
                    } as React.CSSProperties,
                    className: twMerge(Wind.style({
                        width: "w-full",
                        height: "h-full",
                        overflow: "overflow-hidden",
                        overscrollBehavior: "overscroll-none",
                        position: "relative",
                    }).class, className)
                }}
                inner={{
                    id: "scroll-region",
                    ref: scrollRef,
                    className: twMerge(Wind.style({
                        height: "h-full",
                        overflow: "overflow-clip",
                        overscrollBehavior: "overscroll-none",
                        flex: "flex-[1_1_100%]"
                    }).class, "overflow-y-auto", className),
                    onScroll: () =>
                    {
                        if (!scrollRef.current) return;

                        Logger.log(
                            "scrolling",
                            scrollRef.current.scrollTop,
                            scrollRef.current.clientHeight,
                            scrollRef.current.scrollHeight
                        );

                        const currentScroll = scrollRef.current.scrollTop + scrollRef.current.clientHeight;

                        const threshold = 50;

                        // If the user scrolls up, we should stop auto-scrolling
                        if (currentScroll < scrollRef.current.scrollHeight - threshold)
                        {
                            Logger.log("stopping auto-scroll");
                            setScroll(false);
                        }
                        else
                        {
                            Logger.log("resuming auto-scroll");
                            setScroll(true);
                        }
                    }
                }}
            >
                {nodes}
            </Container>
            {events[0].state !== EventState.STATIC && (
                <LowerLobbyControls
                    key={`lobby-controls-${room.id}`}
                    locked={events[0].state !== EventState.ACTIVE}
                    hideRoomCode={hideRoomCode}
                />
            )}
            {reportDialogEvent && (
                <ReportDialog
                    reportedContent={{
                        user: "user@fakemail.com",
                        object_id: "",
                        timestamp: "2024-04-23 4:30PM ET"
                    }}
                    onClose={() => void setDialogEvent(null)}
                />
            )}
        </>
    );
};

export default GameContent;
