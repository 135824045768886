import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";
import useTheme from "@/game/themes/useTheme";
import Container from "./Container";
import TextContainer from "./TextContainer";

const PlayerStatusMessage = ({
    text, ...props
}: {
    text: string;
    className?: string;
} & GameNodeProps<Type.PLAYER_JOIN | Type.PLAYER_LEAVE | Type.PHOTO_SUBMIT>) =>
{
    const {player: {avatar, name}} = props;

    const { Callout, Avatar } = useTheme().System;

    return (
        <TextContainer
            theme={Callout}
            prefix={
                <Container
                    {...Avatar.container}
                    slices={Avatar.slices}
                    outer={{
                        className: Wind.style({
                            height: "h-[1.5em]",
                            width: "w-[1.5em]",
                        }).class,
                    }}
                >
                    <img
                        src={avatar.path}
                        alt={name}
                    />
                </Container>
            }
            message={text.replace("$PLAYER", `**${name}**`)}
        />
    );
};

export default PlayerStatusMessage;

