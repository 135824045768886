import type { WindTypes } from "@/util/Tailwind";

/**
 * @param cancelButton if the android back button should operate on this button
 */
const RoundishButton = ({
    cancelButton,
    color,
    background,
    onClick,
    children,
    text,
}: React.PropsWithChildren<{
    cancelButton?: boolean;
    color: WindTypes["color"];
    background: WindTypes["backgroundColor"];
    onClick: () => unknown;
    text?: string;
}>) => (
    <button
        className={`
            rounded
            lowercase
            text-base
            font-default
            font-normal
            p-2
            ${color}
            ${background}
            ${cancelButton? "cancelButton": ""}
        `}
        onClick={() => void onClick()}
    >
        {text ?? children}
    </button>
);

export default RoundishButton;
