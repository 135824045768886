import WinnerList from "@/components/game/WinnerList";

export default function GameWinnerWebView()
{
    return (
        <div>
            <WinnerList />
        </div>
    );
}
