import * as Util from "@/util/Util";
import {
    isKey,
    Dataset,
    splitKey,
} from "./Dataset";

class AutoComplete
{
    static #instance: AutoComplete | undefined;

    private readonly dataset: Dataset;

    private readonly templates: readonly string[][];

    private readonly cache = new Map<string, string[]>();

    // ----------
    // Private stuff
    // ----------

    private constructor ()
    {
        if (!Dataset.instance)
        {
            throw Error("Dataset has not been requested. This should not be possible");
        }

        this.dataset = Dataset.instance;

        this.templates = this.dataset.templates;
    }

    private static get instance ()
    {
        if (!AutoComplete.#instance) AutoComplete.#instance = new AutoComplete();

        return AutoComplete.#instance;
    }

    private readonly rebuildTemplate = (template: readonly string[]) =>
    {
        const rebuilt = [
            ...template,
        ];

        const shortList = this.dataset.MAX_OPTS !== Infinity;
        const shuffledList = shortList;
        const sortedList = !shuffledList;

        if (shuffledList) Util.shuffle(rebuilt);

        if (shortList) rebuilt.length = Math.min(this.dataset.MAX_OPTS, rebuilt.length);

        const removePrefix = (input: string, prefixList: string[]) => prefixList
            .map((str) => `${str} `)
            .reduce((str, prefix) =>
            {
                if (str.startsWith(prefix)) return str.substring(prefix.length);

                return str;
            }, input);

        if (sortedList)
        {
            rebuilt
                .map((str) => ({
                    "full":     str,
                    "stripped": str.substring(1, str.length - 1).toLocaleLowerCase(),
                }))
                .map((str) => ({
                    "full":     str.full,
                    "stripped": removePrefix(str.stripped, [
                        "a", "an", "the",
                    ]),
                }))
                .sort((a, b) =>
                {
                    if (a.stripped < b.stripped) return -1;

                    if (a.stripped > b.stripped) return 1;

                    return 0;
                })
                .forEach((str, i) =>
                {
                    rebuilt[i] = str.full;
                });
        }

        return rebuilt;
    };

    // //////// -- Public stuff

    public static load = async () => Dataset.getInstance();

    public static get templates ()
    {
        return AutoComplete.instance.templates;
    }

    public static get (section: string)
    {
        const auto = AutoComplete.instance;

        if (!isKey(section)) return false;

        const split = splitKey(section);
        const keyList = split.join("|");
        const cached = auto.cache.get(keyList);

        if (cached) return cached;

        const rebuilt = auto.rebuildTemplate(split.flatMap((key) => auto.dataset.get(key)));

        auto.cache.set(keyList, rebuilt);

        return rebuilt;
    }

    public static rebuildTemplates = () =>
    {
        AutoComplete.instance.cache.clear();
    };
}

export default AutoComplete;
