import StyledText from "./StyledText";

import Container, { type ContainerProps } from "./Container";
import type { Font } from "@/game/themes/Font";
import type { WindTypes } from "@/util/Tailwind";

const TextContainer = ({
    prefix,
    message,
    suffix,
    theme: { container, slices, Text, Accent, },
    style = {},
}: {
    theme: {
        container: Omit<ContainerProps, "slices">,
        slices: ContainerProps["slices"],
        Text?: Font,
        Accent?: Font,
    }
    prefix?: React.ReactNode;
    message: string;
    suffix?: React.ReactNode;
    style?: WindTypes;
}) =>
{
    return (
        <Container
            {...container}
            slices={slices}
            inner={{
                className: Wind.style({
                    display: "flex",
                    flexWrap: "flex-nowrap",
                    justifyContent: "justify-center",
                    gap: "gap-1",
                    alignItems: "items-center",
                    textWrap: "text-pretty",
                }).class,
                style: Text?.apply(),
            }}
            outer={{ className: Wind.style(style).class }}
        >
            {prefix}
            <StyledText
                accent={Accent?.apply()}
                text={message}
            />
            {suffix}
        </Container>
    );
};

export default TextContainer;
