import {
    createTools, type Tailwindest,
} from "tailwindest";
import { twMerge } from "tailwind-merge";
import type { PickByType } from "@/util/Util";

import type Config from "@/../tailwind.config";

export type WindTypes = Tailwindest<
    {
        color: keyof typeof Config["theme"]["extend"]["colors"];
        screens: keyof typeof Config["theme"]["extend"]["screens"];
    },
    {
        fontFamily: keyof typeof Config["theme"]["fontFamily"]
    }
>;

type BasicWindTypes = PickByType<WindTypes, string>;

export type WindClasses = Exclude<BasicWindTypes[keyof BasicWindTypes], undefined>;

const tools = createTools<WindTypes>();

const classlist = () =>
{
    const data = {
        class: "",
        wind (...classes: WindClasses[])
        {
            return join(classes);
        },
        misc (...classes: string[])
        {
            return join(classes);
        },
    };

    const join = (classes: string[]) =>
    {
        data.class = twMerge(data.class, classes.join(" "));

        return data;
    };

    return data;
};

const Tailwind = {
    ...tools,
    list: classlist,
} as const;

export default Tailwind;
