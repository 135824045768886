import * as RoomManager from "../game/events/RoomManager";
import HTTPStatus from "@/realize-shared/HTTPStatus";
import * as Types from "@/realize-shared/Types";
import * as Cache from "@/util/Cache";
import { AUTH_HEADER, type EmptyRequest, type EmptyResponse } from "@/realize-shared/model/RequestTypes";

// TODO: Use new settings loader
// Server information read from environmental variable 'VITE_APP_SERVER'
export const ADDRESS = (() =>
{
    const url = (input: unknown) =>
    {
        try
        {
            return new URL(String(input)).href;
        }
        catch
        {
            return "";
        }
    };

    const eggHost = url(Cache.get<string>("server", null, "realize"));

    if (!eggHost) Cache.remove("server");

    const envHost = url(import.meta.env.VITE_APP_SERVER);
    const apiHost = url(import.meta.env.VITE_DEFAULT_APP_SERVER);

    const server = url(eggHost || envHost || apiHost);

    AssertTrue(server.length > 0, "Neither VITE_APP_SERVER nor VITE_DEFAULT_APP_SERVER set!");

    return server;
})();

let fileStoreRoot: null | string = null;

export const setFileStoreEndpoint = (root: string) =>
{
    if (fileStoreRoot !== null) throw new Error("FileStore root already set");

    fileStoreRoot = root;
};

export const fileStore = (...path: string[]) =>
{
    if (fileStoreRoot === null) throw new Error("File store root NOT set");

    return `${fileStoreRoot}${path.join("/")}`;
};

type Body = Record<string, unknown>;

interface Response
{
    "status": HTTPStatus;
    "body"?: Body;
}

let auth = "";

export const sendRequest = async (method: string, route: string, data?: {
    body?: unknown;
    headers?: Body;
}) =>
{
    const request: Body = {};
    const headers = data?.headers? data.headers: {};

    auth ||= Cache.get<string>("auth") ?? "";

    route = ADDRESS + route;

    if (data?.body)
    {
        headers["Content-Type"] = "application/json";
        request.body = JSON.stringify(data.body);
    }

    headers[AUTH_HEADER] = auth;

    request.method = method;
    request.headers = headers;
    request.credentials = "include";

    return await (async () =>
    {
        const response = await fetch(route, request);
        const status = response.status as HTTPStatus;

        let responseBody: Body | undefined;

        if (status === HTTPStatus.OK) responseBody = Types.asJSONObject(await response.json());

        return {
            status,
            "body": responseBody,
        };
    })();
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const receive = <ResponseType>(active: boolean) => ({ status, body }: Response) =>
{
    if (status !== HTTPStatus.OK) throw new Error(`Received ${status}`);

    if (active) RoomManager.reset();

    return Types.asType<ResponseType>(body);
};

export const get = async <
    ResponseType = EmptyRequest,
>(
    active: boolean,
    route: string,
    headers?: Body,
) => sendRequest("GET", route, { headers })
    .then(receive<ResponseType>(active));

export const post = async <
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    RequestType = EmptyRequest,
    ResponseType = EmptyResponse,
>(
    active: boolean,
    endpoint: string,
    body: RequestType,
    headers?: Body,
) => sendRequest("POST", endpoint, { body, headers })
    .then(receive<ResponseType>(active));
