import React, { useEffect, useRef, useState } from "react";
import ChevronLeft from "@/assets/img/symbolic/chevron-left.svg?react";
import ReportIcon from "@/assets/img/layout/report/report-icon.svg?react";
import NotificationMask from "@/assets/img/layout/report/ending-alert-bg.svg";
import ReportCategory from "./ReportCategory";
import ReportDetails from "./ReportDetails";
import { sendReport } from "@/api/HumanRequests";
import type { ReportedContentType, ReportedSubmissionDataType } from "@/realize-shared/model/RequestTypes";
import { useGameContext } from "@/game/GameContext";
import { twMerge } from "tailwind-merge";

interface Props
{
    reportedContent: ReportedContentType;
    onClose: () => void;
}

const FINAL_STAGE = 3;

const ReportDialog: React.FC<Props> = ({ onClose, reportedContent }) =>
{
    const [reportSubmissionData, setReportSubmissionData] = useState<ReportedSubmissionDataType>({
        reporterEmail: "",
        category: "",
        message: "",
        reportedContent: reportedContent
    });

    const { room } = useGameContext();

    const [reportStage, setReportStage] = useState(1);

    const reportDialogRef = useRef(null);
    const backdropRef = useRef<HTMLDivElement|null>(null);

    useEffect(() =>
    {
        Logger.log("Report Submission Data: ", reportSubmissionData);

        sendReport(room.id, reportSubmissionData).catch((error) =>
            void Logger.error(error));
    }, [reportSubmissionData, room.id]);

    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() =>
    {
        const TIMEOUT = 3000;

        if (reportStage === FINAL_STAGE)
        {
            const timer = setTimeout(() =>
            {
                setFadeOut(true);

                setTimeout(() =>
                {
                    onClose();
                }, 1000);
            }, TIMEOUT);

            return () => void clearTimeout(timer);
        }

        return () =>
        {
            // DO NOTHING
        };
    }, [onClose, reportStage]);


    if (reportStage === FINAL_STAGE)
    {
        return (
            <div
                className={`${Wind.style({
                    position: "fixed",
                    bottom: "bottom-[calc(var(--safe-area-bottom)_+_3.75rem)]",
                    left: "left-4",
                    right: "right-4",
                    zIndex: "z-50",
                }).class} animate__animated ${fadeOut ? "animate__fadeOutDown" : "animate__bounceInUp"}`}
                style={{
                    filter: "drop-shadow(2px 2px 8px rgb(0 0 0 / 0.6))",
                }}
            >
                <div
                    className={Wind.style({
                        backgroundColor: "bg-white",
                    }).class}
                    style={{
                        maskSize: "100% auto",
                        maskImage: `url(${NotificationMask})`,
                    }}
                >
                    <div
                        className={Wind.style({
                            height: "h-[6.75rem]",
                            paddingX: "px-4",
                            paddingY: "py-3",
                        }).class}

                    >
                        <h1
                            className={Wind.style({
                                display: "flex",
                                gap: "gap-2",
                                alignItems: "items-center",
                                fontSize: "text-base",
                                fontWeight: "font-semibold",
                                color: "text-dark-blue",
                                marginBottom: "mb-2",
                            }).class}
                        >
                            <ReportIcon className="w-6 h-6" />
                            Report Sent!
                        </h1>
                        <p
                            className={Wind.style({
                                fontSize: "text-sm",
                                fontWeight: "font-normal",
                                lineHeight: "leading-[130%]",
                                color: "text-dark-blue",
                            }).class}
                        >
                            Thank you for your cooperation. Our moderators will take a look at this message.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={
                Wind.style({
                    position: "fixed",
                    bottom: "bottom-0",
                    left: "left-0",
                    right: "right-0",
                    zIndex: "z-50",
                    top: "top-[calc(0px_-_var(--safe-area-top))]",
                    backgroundColor: "bg-[rgba(0,0,0,0.75)]",
                }).class
            }
            ref={backdropRef}
            onClick={(e) =>
            {
                if (backdropRef.current !== null)
                {
                    if (e.target === backdropRef.current)
                    {
                        onClose();
                    }
                }
            }}
        >

            <div
                ref={reportDialogRef}
                className={twMerge(
                    "report-dialog",
                    Wind.style({
                        backgroundColor: "bg-[#1B2F4E]",
                        color: "text-white",
                        padding: "p-4",
                        paddingTop: "pt-6",
                        borderTopRadius: "rounded-t-3xl",
                        position: "fixed",
                        bottom: "bottom-[calc(var(--safe-area-keyboard)_-_var(--safe-area-bottom))]",
                        paddingBottom: "pb-[--safe-area-bottom]",
                        minHeight: "min-h-[30vh]",
                        width: "w-full",
                        zIndex: "z-[150]",
                    }).class
                )}
            >
                <button
                    className={Wind.style({
                        fontSize: "text-sm",
                        display: "flex",
                        alignItems: "items-center",
                        marginBottom: "mb-5",
                        height: "h-full",
                        position: "relative",
                    }).class}
                    onClick={() =>
                    {
                        Logger.log("Button Pressed");

                        if (reportStage > 1)
                        {
                            setReportStage(reportStage - 1);
                        }
                        else
                        {
                            onClose();
                        }
                    }}
                >
                    <ChevronLeft
                        className={Wind.style({
                            color: "text-[#F9DC5C]",
                            marginLeft: "-ml-1.5",
                            marginRight: "mr-2",
                            height: "h-6",
                            width: "w-auto"
                        }).class}
                    />
                    <span
                        className={Wind.style({
                            color: "text-[#B3C2DB]",
                            fontFamily: "font-default",
                            fontWeight: "font-normal",
                            fontSize: "text-base",
                        }).class}
                    >
                        Back
                    </span>
                </button>
                <h2
                    className={Wind.style({
                        paddingBottom: "pb-1",
                        fontFamily: "font-default",
                        fontWeight: "font-semibold",
                        fontSize: "text-xl",
                    }).class}
                >
                    Report Message
                </h2>
                {reportStage === 1 && (
                    <ReportCategory
                        reportStage={reportStage}
                        setReportStage={setReportStage}
                        reportSubmissionData={reportSubmissionData}
                        setReportSubmissionData={setReportSubmissionData}
                    />
                )}

                {reportStage === 2 && (
                    <ReportDetails
                        reportStage={reportStage}
                        setReportStage={setReportStage}
                        reportSubmissionData={reportSubmissionData}
                        setReportSubmissionData={setReportSubmissionData}
                    />
                )}

            </div>
        </div>
    );
};

export default ReportDialog;
