import React from "react";
import { twMerge } from "tailwind-merge";
import * as Cache from "@/util/Cache";

import MagazineAssets from "@/game/magazine/MagazineAssetLoader";
import {
    drawMagazine,
    type MagazineOptions,
} from "@/game/magazine/MagazineGenerator";

const Magazine = (props: MagazineOptions & {
    className?: string;
    style?: React.CSSProperties;
}) =>
{
    const [image, setImage] = React.useState<string>("");

    const {
        username,
        headline,
        photoPath,
        issueNumber,
        timestamp,
        className,
        isWinner,
        style
    } = props;

    const {
        logo, barCode,
    } = MagazineAssets;

    React.useEffect(() =>
    {
        let active = true;

        load().catch(Logger.error);

        return () =>
        {
            active = false;
        };

        async function load()
        {
            // Check for cached image
            const cachedImage = Cache.get<string>(`magazine-${issueNumber}-${headline}`, undefined, "magazine-cover");

            if (cachedImage)
            {
                if (!active) return;
                setImage(cachedImage);
            }
            else
            {
                const base64Image = await drawMagazine({
                    issueNumber: issueNumber,
                    timestamp: timestamp,
                    username: username,
                    headline: headline,
                    photoPath: photoPath,
                    isWinner: isWinner,
                    logo: logo,
                    barCode: barCode,
                }).catch(() => null);

                Cache.set(`magazine-${issueNumber}-${headline}`, base64Image, 60 * 1000, "magazine-cover");

                if (!active) return;
                setImage(base64Image ?? "");
            }
        }
    }, [barCode, headline, isWinner, issueNumber, logo, photoPath, timestamp, username]);

    return (
        <img
            src={image}
            className={twMerge("w-16 h-auto", className)}
            style={style}
        />
    );
};

export default Magazine;
