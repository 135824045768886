import type { Round } from "@/game/types/Game";
import HangTightBG from "@/web/img/hang-tight-background.svg?react";
import StatusBackground from "@/web/img/status-background.svg?react";
import YellowDots from "@/web/img/yellow-dots.svg?react";

export default function GameBeingSetup({
    round,
}: {
    round?: Round;
})
{
    return (
        <div>
            <div
                className={Wind.style({
                    position: "relative",
                    filterDropShadow: "drop-shadow-[0px_4px_64px_rgba(244,233,181,0.20)]",
                }).class}
            >
                <h1
                    className={Wind.style({
                        fontFamily: "font-default",
                        fontWeight: "font-black",
                        fontSize: "text-8xl",
                        color: "text-white",
                        marginBottom: "mb-[5.375rem]",
                        position: "relative",
                    }).class}
                >
                    <HangTightBG
                        className={Wind.style({
                            position: "absolute",
                            left: "left-0",
                            bottom: "bottom-0",
                            transformTranslateX: "-translate-x-[1.5rem]",
                            transformTranslateY: "translate-y-1/2",
                        }).class}
                    />
                    <div className="relative">
                        Hang Tight
                    </div>
                    <YellowDots
                        className={Wind.style({
                            position: "absolute",
                            left: "left-1/2",
                            top: "top-0",
                            transformTranslateX: "-translate-x-1/2",
                            transformTranslateY: "-translate-y-1/2",
                        }).class}
                    />
                </h1>
                {!round && (
                    <div
                        className={Wind.style({
                            position:"relative",
                        }).class}
                    >
                        <StatusBackground
                            className={Wind.style({
                                position: "absolute",
                                top: "top-1/2",
                                left: "left-1/2",
                                width: "w-[22.5rem]",
                                height: "h-14",
                                transformTranslateX: "-translate-x-1/2",
                                transformTranslateY: "-translate-y-1/2",
                            }).class}
                        />
                        <span
                            className={Wind.style({
                                position: "relative",
                                display: "block",
                                textAlign: "text-center",
                                fontFamily: "font-default",
                                fontWeight: "font-normal",
                                fontSize: "text-xl",
                                lineHeight: "leading-6",
                                color: "text-[#9AAEC6]",
                            }).class}
                        >
                            Room is still setting up
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
