// eslint-disable @typescript-eslint/no-unsafe-argument

import * as React from "react";

import * as Cache from "@/util/Cache";
import ReactDOM from "react-dom/client";
import { ADDRESS, setFileStoreEndpoint } from "@/api/Requests";
import * as FontLoader from "../util/FontLoader";
import AutoComplete from "@/game/AutoComplete";
import * as AppRequests from "@/api/AppRequests";
import Assets from "@/data/Assets.out";
import RealizeViewer from "./RealizeViewer";
import { Icons } from "@/game/Icons";
import { Themes } from "@/game/themes/Themes";
import "cordova-plugin-purchase";

// Next line using console to ensure Logger is not the problem when debugging
// eslint-disable-next-line no-console
console.log("Realize starting :)");

void (async () =>
{
    document.title = "initializing";

    Logger.debug({
        "server": ADDRESS,
    });

    document.title = "loading assets";

    await FontLoader.loadDefaults();

    const blocking = [
        new URL("../assets/img/layout/login/login-mountain.webp", import.meta.url).href,
    ];

    const loadMany = async (assets: string[]) =>
    {
        for await (const asset of assets)
        {
            await fetch(asset).catch(Logger.warn);
        }
    };

    // blocking assets
    await loadMany(blocking);

    // non-blocking assets

    loadMany(Assets).catch(Logger.error);

    document.title = "contacting server";

    const { canConnect, fileStore } = await AppRequests.hello();

    Logger.info({
        server: ADDRESS,
        fileStore,
    });

    if (!canConnect)
    {
        document.title = "cannot connect to server";

        Cache.remove("server");

        return;
    }

    setFileStoreEndpoint(fileStore);

    document.title = "updating assets";

    await AutoComplete.load();
    await Icons.load();

    await Themes.load();
    document.title = "setting up";

    const container = document.createElement("div");

    container.id = "root";

    const root = ReactDOM.createRoot(container);

    document.title = "loading viewer";

    document.body.prepend(container);

    root.render(React.createElement(RealizeViewer));

    document.title = "Realize Spectate";
})().catch(Logger.error);
