import Formatted from "@/components/shared/Formatted";
import React from "react";

const StyledText = ({text, accent}: {
    text: string,
    accent: React.CSSProperties | undefined,
}): React.ReactNode => (
    <Formatted
        components={{
            code: {},
            em: {},
            pre: {},
            strong: { style: {...accent} },
            del: {},
            blockquote: {},
            li: {},
        }}
    >
        {text}
    </Formatted>
);

export default StyledText;
