import React from "react";
import CloseIcon from "@/assets/img/symbolic/close.svg?react";
import YellowButtonBG from "@/assets/img/layout/jagged-button-poppy.svg?react";
import RedButtonBG from "@/assets/img/layout/jagged-button-red.svg?react";
import type { Type } from "@/realize-shared/model/Event";
import { useGameContext } from "@/game/GameContext";
import * as PlayerRequests from "@/api/PlayerRequests";
import { EventState } from "@/game/events/ProcessedEventMap";
import type { GameNodeProps } from "../GameNodeProps";
import useTheme from "@/game/themes/useTheme";
import Container from "../components/Container";
import ExpandIcon from "@/assets/img/chat/expand.svg?react";
import EditorIcon from "@/assets/img/editor-symbol.svg?react";
import CollapseIcon from "@/assets/img/chat/collapse.svg?react";
import { Sticky as StickyStyle } from "../util/Sticky";
import Polaroid from "../components/Polaroid";

const VoteStart = ({
    submissions,
    state,
    eventRef,
}: GameNodeProps<Type.VOTE_START>) =>
{
    const { room } = useGameContext();

    const {
        CallToAction,
        Voting,
    } = useTheme();

    const [expanded, setExpanded] = React.useState(false);

    const [viewed, setViewed] = React.useState(-1);

    const [hasStartedSelection, setHasStartedSelection] = React.useState(false);

    const startSelection = () => void setHasStartedSelection(true);

    const confirmSelection = () =>
    {
        setHasStartedSelection(false);
        setViewed(-1);

        void PlayerRequests.selectWinner(room.id, submissions[viewed].player);
    };

    const toggleExpand = () => void setExpanded(state => !state);

    const canVote = state === EventState.ACTIVE;
    const sticky = state !== EventState.STATIC;

    if (viewed > -1)
    {
        return (
            <div
                className={Wind.style({
                    position: "fixed",
                    zIndex: "z-[100]",
                    backgroundColor: "bg-black",
                    inset: "inset-0",
                    paddingTop: "pt-[--safe-area-top]",
                    paddingBottom: "pb-[--safe-area-bottom]",
                    top: "-top-[--safe-area-top]"
                }).class}
                ref={eventRef}
            >
                <button
                    className="
                        w-8
                        h-8
                        p-1
                        bg-[rgba(255,255,255,0.20)]
                        rounded
                        ml-4
                        flex
                        items-center
                        justify-center
                        absolute
                    "
                    onClick={() => void setViewed(-1)}
                >
                    <CloseIcon className="w-5 h-5" />
                </button>
                <img
                    src={submissions[viewed].photo}
                    className="w-full h-full object-contain"
                    onClick={() => void setViewed(-1)}
                />
                {canVote && (
                    <button
                        className={`
                            absolute
                            font-semibold
                            font-default
                            text-xl
                            leading-[130%]
                            w-auto
                            mb-[unset]
                            aspect-[224/72]
                            h-[72px]
                            pb-2
                            right-0
                            bottom-[--safe-area-bottom]
                            ${hasStartedSelection? "text-dark-blue": "text-white"}
                    `}
                        onClick={hasStartedSelection? confirmSelection: startSelection}
                    >
                        {hasStartedSelection
                            ? <YellowButtonBG className="absolute inset-0" />
                            : <RedButtonBG className="absolute inset-0" />}
                        <span className="relative">
                            {hasStartedSelection ? "Confirm Winner?" : "Choose Winner"}
                        </span>
                    </button>
                )}
            </div>
        );
    }

    const { Heading, Photos} = canVote
        ? Voting.Active
        : sticky
            ? Voting.Sticky
            : Voting.Static;

    const stickyTop = Heading.stickyMargin;
    const stickyBottom = (canVote? CallToAction.Active: Voting.Sticky.Photos).stickyMargin;

    return (
        <div
            className={StickyStyle.wind(sticky).class}
            style={StickyStyle.style(sticky, stickyTop, stickyBottom)}
            ref={eventRef}
        >
            <Container
                {...Heading.container}
                slices={Heading.slices}
                outer={{
                    className: Wind.style({ zIndex: "z-[1]" }).class,
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        alignItems: "items-center",
                        justifyItems: "justify-items-center",
                        justifyContent: "justify-center",
                        alignContent: "content-center",
                        position: "relative",
                        verticalAlign: "align-middle"
                    }).class,
                    style: Heading.Text?.apply({}),
                }}
            >
                <div
                    className={Wind.style({
                        flexGrow: "grow",
                        textAlign: "text-center",
                    }).class}
                >
                    {"Photos are in!"}
                </div>
                <Container
                    {...Heading.Button.container}
                    slices={Heading.Button.slices}
                    outer={{
                        onClick: toggleExpand,
                        style: Heading.Button.Text?.apply(),
                    }}
                >
                    {expanded
                        ? <CollapseIcon className="w-5 h-5" />
                        : <ExpandIcon className="w-5 h-5" />}
                </Container>
            </Container>
            <Container
                {...Photos.container}
                slices={Photos.slices}
                outer={{
                    className: Wind.style({ zIndex: "z-[1]" }).class,
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        flexWrap: "flex-nowrap",
                        justifyContent: "justify-center",
                    }).class,
                }}
            >
                <div
                    className={Wind.toggle({
                        base: {
                            gap: "gap-2",
                            width: "w-fit",
                            maxWidth: "max-w-full",
                            padding: "p-2",
                        },
                        falsy: {
                            display: "flex",
                            flexWrap: "flex-nowrap",
                            overflow: "overflow-x-auto",
                        },
                        truthy: {
                            display: "flex",
                            flexWrap: "flex-wrap",
                            overflow: "overflow-x-auto",
                            justifyContent: "justify-center",
                        },
                    }).class(expanded)}
                >
                    {submissions.map((submission, index) => (
                        <Polaroid
                            key={index}
                            {...(sticky? {photo: submission.photo}: submission)}
                            styles={{
                                width: expanded? "w-[calc(33%_-_1rem)]": "w-20",
                                flexShrink: expanded? undefined: "shrink-0",
                            }}
                            onClick={() => void setViewed(index)}
                        />
                    ))}
                </div>
            </Container>

            {canVote && (
                <Container
                    {...CallToAction.Active.container}
                    slices={CallToAction.Active.slices}
                    outer={{
                        className: Wind.style({ width: "w-full" }).class,
                        onClick: () =>
                        {
                            // TODO: flesh this out
                            setViewed(Math.floor(Math.random() * submissions.length));
                        }
                    }}
                    inner={{
                        className: Wind.style({
                            display: "flex",
                            alignItems: "items-center",
                            justifyContent: "justify-center",
                            gap: "gap-2",
                            fontFamily: "font-default",
                        }).class,
                        style: CallToAction.Active.Text?.apply({}),
                    }}
                >
                    <EditorIcon
                        className={Wind.style({
                            width: "w-[1em]",
                            height: "h-[1em]",
                        }).class}
                    />
                    {"Select Winning Photo"}
                </Container>
            )}
        </div>
    );
};

export default VoteStart;
