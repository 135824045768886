import type { SizeStr } from "@/util/shorthand";
import type { WindTypes } from "@/util/Tailwind";
import type { CSSProperties } from "react";

const windStatic: WindTypes = {};

const windSticky: WindTypes = {
    zIndex: "z-10",
    position: "sticky",
};

export const Sticky = {
    wind: (sticky: boolean, classes?: WindTypes) => Wind.style({
        ...classes,
        ...(sticky? windSticky: windStatic),
    }),
    style: (
        sticky: boolean,
        top: SizeStr,
        bottom: SizeStr,
        styles?: CSSProperties,
    ): CSSProperties => ({
        ...(sticky? {top, bottom}: undefined),
        ...styles,
    }),
} as const;
