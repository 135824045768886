import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";
import Container from "../components/Container";
import useTheme from "@/game/themes/useTheme";
import type { Reason } from "@/realize-shared/model/Types";

const reasons: Record<Reason, string | null> = {
    "admin skip": "Round has been skipped by an admin",
    "no choice made": "No Photographs submitted",
    "no headline": "Editor did not submit a headline",
    "too few players": null,
    "too few submissions": "Not enough photos submitted",
    "completed": null,
    "inactivity": null,
    "kicked": null,
    "left": null,
    "unknown": null,
};

const RoundSkip = ({
    reason,
    eventRef,
}: GameNodeProps<Type.ROUND_SKIP>) =>
{
    const {
        System: { Chip }
    } = useTheme();

    const text = reasons[reason];

    if (text === null) return;

    return (
        <div
            className={Wind.style({
                display: "flex",
                justifyContent: "justify-center",
            }).class}
            ref={eventRef}
        >
            <Container
                {...Chip.container}
                slices={Chip.slices}
                outer={{
                    className: Wind.style({
                        width: "w-min",
                    }).class,
                }}
                inner={{
                    className: Wind.style({
                        textWrap: "text-nowrap",
                    }).class,
                    style: Chip.Text?.apply()
                }}
            >
                {text}
            </Container>
        </div>
    );
};

export default RoundSkip;
