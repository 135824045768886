import JaggedButtonSVG from "@/assets/img/layout/raw-jagged-button.svg?react";
import SquarishButtonSVG from "@/assets/img/layout/how-to-button-bg.svg?react";
import type { WindTypes } from "@/util/Tailwind";
import type React from "react";
import { twMerge } from "tailwind-merge";

type ThemeName = "default" | "deep-gray" | "sharp-red" | "poppy-yellow" | "parchment" | "dull-green";
type ShapeName = "jagged" | "square-ish";

type Theme = Record<"button"|"text", WindTypes["color"]>;
type Shape = (className: string) => React.ReactNode;

const THEMES: Record<ThemeName, Theme> = {
    "default": {
        button: "text-black",
        text: "text-white",
    },
    "deep-gray": {
        button: "text-[#1D2735]",
        text: "text-white",
    },
    "sharp-red": {
        button: "text-sharp-red",
        text: "text-white",
    },
    "poppy-yellow": {
        button: "text-poppy-yellow",
        text: "text-dark-blue",
    },
    "parchment": {
        button: "text-parchment",
        text: "text-dark-blue",
    },
    "dull-green": {
        button: "text-[#689962]",
        text: "text-white",
    },
};

const SHAPES: Record<ShapeName, Shape> = {
    "jagged": (c) => <JaggedButtonSVG className={c} />,
    "square-ish": (c) => <SquarishButtonSVG className={c} />,
};

export const DialogButton = ({
    onClick,
    text,
    color,
    disabled,
    className,
    shape,
    scale,
}: {
    onClick: () => void;
    text: string|React.ReactNode;
    color?: Theme | ThemeName;
    disabled?: boolean;
    className?: string;
    shape?: Shape | ShapeName;
    scale?: {
        text?: WindTypes["transformScale"]
        button?: WindTypes["transformScale"]
    }
}) =>
{
    color = (typeof color === "object")? color: THEMES[color ?? "default"];
    shape = (typeof shape === "function")? shape: SHAPES[shape ?? "jagged"];

    scale ??= {};
    scale.text ??= "scale-100";
    scale.button ??= "scale-100";

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={twMerge(Wind.style({
                width: "w-[7.5rem]",
                height: "h-10",
                position: "relative",
                fontFamily: "font-default",
                fontWeight: "font-semibold"
            }).class, className)}
        >
            {shape(Wind.style({
                color: color.button,
                transformScale: scale.button,
                transition: "transition-colors ease-in-out",
                width: "w-full",
                height: "h-full",
            }).class)}
            <span
                className={Wind.style({
                    transformScale: scale.text,
                    position: "absolute",
                    inset: "inset-0",
                    display: "flex",
                    alignItems: "items-center",
                    justifyContent: "justify-center",
                    transition: "transition-colors ease-in-out",
                    color: color.text,
                }).class}
            >
                {text}
            </span>
        </button>
    );
};
