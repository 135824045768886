import { useGameContext } from "@/game/GameContext";
import type { Type } from "@/realize-shared/model/Event";
import { GameUIState } from "@/game/GameUIState";
import { EventState } from "@/game/events/ProcessedEventMap";
import StyledText from "../components/StyledText";
import type { GameNodeProps } from "../GameNodeProps";
import useTheme from "@/game/themes/useTheme";
import CameraIcon from "@/assets/img/symbolic/camera.svg?react";
import Container from "../components/Container";
import type { Round } from "@/game/types/Game";
import Headline from "../components/Headline";
import { Sticky } from "../util/Sticky";

const PlayerList = ({round}: { round: Round }) =>
{
    const {
        PhotoSubmit: { Avatar: { Submitted, Waiting } },
        CallToAction: { Static },
    } = useTheme();

    AssertIsset(round, "Round is null");

    const players = [...round.players.values()]
        .filter(player => player !== round.editor)
        .map(player => ({
            ...player,
            slug: player.name.toLowerCase(),
            submitted: round.submissions.has(player.id),
        }))
        .sort(
            ({slug: a}, {slug: b}) => ((a<b)? 1: ((a===b)? 0: -1))
        );

    return (
        <>
            {players.map(({avatar, name, submitted}, index) => (
                <Container
                    {...(submitted? Submitted: Waiting).container}
                    slices={(submitted? Submitted: Waiting).slices}
                    key={`avatar-${index}`}
                    inner={{
                        className: Wind.style({
                            width: "w-6",
                            height: "h-6",
                        }).class
                    }}
                >
                    <img
                        className={Wind.style({
                            filterSaturate: submitted? undefined: "saturate-0",
                            opacity: submitted? undefined: "opacity-40",
                        }).class}
                        src={avatar.path}
                        alt={name}
                    />
                </Container>
            ))}
            <StyledText
                accent={Static.Accent?.apply()}
                text="Submitted"
            />
        </>
    );
};

const HeadlineCreate = ({
    state,
    headline,
    round,
    eventRef,
}: GameNodeProps<Type.HEADLINE_CREATE>) =>
{
    const { room } = useGameContext();

    const { PhotoSubmit, CallToAction } = useTheme();

    AssertIsset(round, "Round is null");

    const active = state === EventState.ACTIVE;
    const sticky = state !== EventState.STATIC;

    const Outer = ({children}: { children?: React.ReactNode }) =>
    {
        const topMargin = (active? PhotoSubmit.Active: PhotoSubmit.Sticky).stickyMargin;
        const bottomMargin = (active? CallToAction.Active: CallToAction.Static).stickyMargin;

        return (
            <div
                className={Sticky.wind(sticky, {
                    display: "flex",
                    position: "relative",
                    width: "w-full",
                    flexDirection: "flex-col",
                }).class}
                style={Sticky.style(sticky, topMargin, bottomMargin)}
                ref={eventRef}
            >
                {children}
            </div>
        );
    };

    const Header = () =>
    {
        const Box = active? PhotoSubmit.Active: sticky? PhotoSubmit.Sticky: PhotoSubmit.Static;

        return (
            <Container
                {...Box.container}
                slices={Box.slices}
                outer={{
                    className: Wind.style({
                        position: "relative",
                        zIndex: "z-[1]"
                    }).class,
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        justifyContent: "justify-center",
                        alignItems: "items-center",
                    }).class,
                }}
            >
                <Headline text={headline} />
            </Container>
        );
    };

    const Action = () =>
    {
        if (!sticky) return;

        const Button = active? CallToAction.Active: CallToAction.Static;

        return (
            <Container
                {...Button.container}
                slices={Button.slices}
                outer={{
                    onClick: active
                        ? () => void room.data.write({gameUIState: GameUIState.CAMERA}, true)
                        : undefined
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        alignItems: "items-center",
                        justifyContent: "justify-center",
                        gap: "gap-2",
                        fontFamily: "font-default",
                    }).class,
                    style: Button.Text?.apply({}),
                }}
            >
                { active
                    ? (
                        <>
                            <CameraIcon
                                className={Wind.style({
                                    width: "w-[1em]",
                                    height: "h-[1em]",
                                }).class}
                            />
                            {"Take a photo!"}
                        </>
                    )
                    : <PlayerList round={round} /> }
            </Container>
        );
    };

    return (
        <Outer>
            <Header />
            <Action />
        </Outer>
    );
};

export default HeadlineCreate;
