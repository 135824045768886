import * as React from "react";

import * as Util from "@/util/Util";
import RoundishButton from "@/components/shared/RoundishButton";

import IconCaret from "@/assets/img/symbolic/caret.svg?react";

const SHOW_CANCEL = false as boolean;

const SettingsPopup = ({
    value,
    values,
    title,
    prefix,
    suffix,
    submit,
}: {
    value: number;
    values: number[];
    title: string;
    prefix: string;
    suffix: string;
    submit: (value: number | null) => unknown;
}) =>
{
    // Auto scroll to value
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const getScrollData = React.useCallback(() =>
    {
        const container = scrollRef.current;
        const first = container?.firstElementChild;

        if (!(first && first instanceof HTMLDivElement)) return false;

        const padding = first.offsetTop;
        const height = first.offsetHeight;
        const scroll = container.scrollTop;
        const last = values.length - 1;

        return {
            first,
            container,
            padding,
            height,
            scroll,
            last,
        };
    }, [values]);

    React.useEffect(() =>
    {
        const data = getScrollData();

        if (!data) return;

        const index = Util.clamp(0, values.indexOf(value), data.last);

        const top = data.height * index;

        data.container.scrollTo({
            top,
            "behavior": "instant",
        });

        document.body.classList.add("pop-over");

        return () => void document.body.classList.remove("pop-over");
    }, [getScrollData, value, values]);

    const onSelect = (e: React.MouseEvent<HTMLDivElement>) =>
    {
        const data = getScrollData();
        const self = e.currentTarget;

        if (!data) return;

        // Scroll to the element
        data.container.scrollTo({
            "top":      self.offsetTop - data.padding,
            "behavior": "auto",
        });
    };

    const onConfirm = () =>
    {
        const data = getScrollData();

        if (!data) return;

        const index = Util.clamp(
            0,
            Math.floor(data.scroll / data.height),
            data.last,
        );

        submit(values[index]);
    };

    return (
        <div
            className={Wind.style({
                position: "fixed",
                inset: "inset-0",
                zIndex: "z-[75]",
                backgroundColor: "bg-black/60",
            }).class}
            onClick={(e) =>
            {
                if (e.target === e.currentTarget) onConfirm();
            }}
        >
            <div
                className={Wind.style({
                    backgroundColor: "bg-parchment",
                    position: "absolute",
                    bottom: "bottom-0",
                    left: "left-0",
                    right: "right-0",
                    padding: "p-3",
                    display: "flex",
                    flexDirection: "flex-col",
                }).class}
            >
                <h1
                    className={Wind.style({
                        color: "text-dark-blue",
                        fontSize: "text-lg",
                        flexShrink: "shrink-0",
                    }).class}
                >
                    {title}
                </h1>
                <div
                    className={Wind.style({
                        display: "flex",
                        flexDirection: "flex-row",
                        alignItems: "items-center",
                        justifyContent: "justify-center",
                        height: "h-32",
                        position: "relative",
                        fontWeight: "font-semibold",
                        color: "text-dark-blue",
                    }).class}
                >
                    <div
                        className={Wind.style({
                            position: "absolute",
                            height: "h-8",
                            width: "w-full",
                            backgroundColor: "bg-sharp-red/10",
                            top: "top-1/2",
                            transformTranslateY: "-translate-y-1/2",
                            pointerEvents: "pointer-events-none",
                        }).class}
                    />
                    <div>
                        {prefix}
                    </div>
                    <div
                        ref={scrollRef}
                        className={`${Wind.style({
                            scrollBehavior: "scroll-smooth",
                            scrollSnapType: "snap-y",
                            overflow: "overflow-y-scroll",
                            height: "h-24",
                            paddingY: "py-8",
                        }).class} no-scrollbar`}
                    >
                        {values.map((text, key) => (
                            <div
                                key={key}
                                className={Wind.style({
                                    scrollSnapAlign: "snap-center",
                                    paddingX: "px-8",
                                    height: "h-8",
                                    display: "flex",
                                    alignItems: "items-center",
                                    justifyContent: "justify-center",
                                }).class}
                                onClick={onSelect}
                            >
                                {text}
                            </div>
                        ))}
                    </div>
                    <div>
                        {suffix}
                    </div>
                </div>
                <div
                    className={Wind.style({
                        display: "grid",
                        gridTemplateColumns: "grid-cols-3",
                        gap: "gap-2",
                    }).class}
                >
                    <div />
                    {SHOW_CANCEL
                        ? (
                            <RoundishButton
                                color="text-white"
                                background="bg-dark-blue"
                                onClick={() => void submit(null)}
                                text="cancel"
                            />
                        )
                        : <div />}
                    <RoundishButton
                        color="text-white"
                        background="bg-sharp-red"
                        onClick={onConfirm}
                        text="confirm"
                    />
                </div>
                <div
                    className={Wind.style({
                        height: "h-[--safe-area-bottom]",
                        flexShrink: "shrink-0",
                    }).class}
                />
            </div>
        </div>
    );
};

const GameSettingsSelection = ({
    icon,
    values,
    value,
    title,
    prefix,
    suffix,
    onSubmit,
    locked,
    style,
}: {
    locked?: boolean,
    icon: React.ReactNode;
    values: number[];
    value: number;
    title: string;
    prefix: string;
    suffix: string;
    onSubmit: (value?: number) => void;
    style: {
        foreground: string,
        background: string,
        hint: string,
        accent: string,
    }
}) =>
{
    const enabled = !locked;

    const [isOpen, setIsOpen] = React.useState(false);

    const submit = (value: number | null) =>
    {
        setIsOpen(false);

        if (typeof value === "number") onSubmit(value);
    };

    return (
        <div
            className={Wind.style({
                display: "grid",
                gap: "gap-3",
                fontSize: "text-base",
                fontFamily: "font-sans",
                lineHeight: "leading-6",
                fontWeight: "font-bold",
            }).class}
        >
            <div
                onClick={() =>
                {
                    if (enabled) setIsOpen(!isOpen);
                }}
                className={Wind.style({
                    borderRadius: "rounded-lg",
                    paddingY: "py-1",
                    paddingX: "px-2",
                    display: "flex",
                    alignItems: "items-center",
                    justifyContent: "justify-center",
                    gap: "gap-1",
                }).class}
                style={{
                    backgroundColor: style.background,
                    color: style.foreground,
                }}
            >
                <div
                    className={Wind.style({
                        display: "flex",
                        gap: "gap-1",
                        alignItems: "items-center",
                    }).class}
                >
                    {icon}
                    {value}
                </div>
                {enabled && <IconCaret style={{ color: style.hint }} />}
            </div>
            {isOpen && <SettingsPopup {...{title, prefix, suffix, value, values, submit}} />}
        </div>
    );
};

export default GameSettingsSelection;
