import type { GameContext, Scoreboard } from "@/game/types/Game";

const buildScoreboard = (game: Readonly<GameContext>): Scoreboard =>
{
    const { round, rules, host } = game;

    AssertIsset(round, "round is null");

    const players = [...round.players.values()];

    if (rules.editor === "static")
    {
        players.splice(players.indexOf(host), 1);
    }

    return players
        .sort(({name: n1, score: s1}, {name: n2, score: s2}) =>
        {
            if (s1 !== s2) return s1 - s2;

            if (n1 < n2) return -1;
            if (n1 > n2) return 1;

            return 0;
        })
        .map((player) =>
        {
            const { score } = player;

            return {player, score};
        })
        .reverse();
};

export default buildScoreboard;
