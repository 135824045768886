import type { Player } from "@/game/types/Game";
import { twMerge } from "tailwind-merge";
import crown from "src/assets/img/crown.svg";

const Polaroid = ({
    className,
    imagePath,
    player,
    style,
}: {
    imagePath: string;
    className?: string;
    style?: React.CSSProperties;
    player?: Player;
}) =>
{
    return (
        <div
            className={twMerge(Wind.style({
                position: "relative",
                display: "flex",
                flexDirection: "flex-col",
                justifyContent: "justify-center",
                alignItems: "items-center",
                backgroundColor: "bg-white",
                paddingTop: "pt-4",
                paddingX: "px-4",
                maxWidth: "max-w-[24.375rem]",
                marginTop: "mt-12"
            }).class, !player && ("pb-14"), className)}
            style={style}
        >
            {player && (
                <img
                    src={crown}
                    className={Wind.style({
                        position: "absolute",
                        width: "w-[7.266rem]",
                        top: "-top-10",
                        right: "-right-10",
                        transformTranslate: "translate-[-50%,-50%]", // Adjust this if necessary
                    }).class}
                />
            )}
            <img
                src={imagePath}
                className={Wind.style({
                    width: "w-full",
                    height: "h-full",
                    objectFit: "object-cover",
                }).class}
            />
            {player && (
                <div
                    className={Wind.style({
                        display: "flex",
                        gap: "gap-2",
                        alignItems: "items-center",
                        paddingY: "py-2"
                    }).class}
                >
                    <img
                        src={player.avatar.path}
                        alt={`Avatar of ${player.name}`}
                        className={Wind.style({
                            width: "w-11",
                            height: "h-11",
                            borderRadius: "rounded-full",
                        }).class}
                    />
                    <span
                        className={Wind.style({
                            color: "text-[#031E3E]",
                            fontFamily: "font-cursive",
                            fontWeight: "font-normal",
                            fontSize: "text-2xl",
                            lineHeight: "leading-[83%]",
                            textAlign: "text-center",
                        }).class}
                    >
                        {player.name}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Polaroid;
