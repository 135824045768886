import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";

const RuleChange = (
    _event: GameNodeProps<Type.RULE_CHANGE>,
) =>
{
    return null;
};

export default RuleChange;
