export type Upper = `${Uppercase<string>}${string}`;

export type Lower = `${Lowercase<string>}${string}`;

export type Node = {
    [name in Upper]: (Node|undefined)[];
} & {
    [name in Lower]: string | undefined;
};

const isUpper = (s: string): s is Upper => Boolean(/^[A-Z]/u.exec(s));

const asUpper = (s: string) =>
{
    if (!isUpper(s)) throw new Error(`Illegal uncapitalized element ${s}`);

    return s;
};

const isLower = (s: string): s is Lower => Boolean(/^[a-z]/u.exec(s));

const asLower = (s: string) =>
{
    if (!isLower(s)) throw new Error(`Illegal capitalized attribute ${s}`);

    return s;
};

export default function toObject(element: Element)
{
    const unused = new Set<string>();

    const _toObject = (element: Element, stack: string) =>
    {
        stack += element.tagName;

        unused.add(stack);

        const o: Node = {};

        for (const attr of element.attributes)
        {
            const name = asLower(attr.name);

            if (name.includes(":")) continue;

            unused.add(`${stack}.${name}`);

            o[asLower(name)] = attr.value;
        }

        for (const child of element.children)
        {
            const name = asUpper(child.tagName);

            if (!(name in o)) o[name] = [];

            o[name].push(_toObject(child, stack + "."));
        }

        return new Proxy(o, {
            get(target, name: string, receiver)
            {
                const optional = name.endsWith("$");

                if (optional) name = name.substring(0, name.length-1);

                const present = Reflect.has(target, name);

                unused.delete(stack);
                unused.delete(`${stack}.${name}`);

                if (present)
                {
                    return Reflect.get(target, name, receiver) as (Node|undefined)[] | string;
                }

                ((optional)? Logger.debug: Logger.warn)(`${stack}.${name} not found`);

                return isUpper(name)? []: undefined;
            }
        });
    };

    const obj = _toObject(element, "") as Node & { _unused: () => string[] };

    obj._unused = () => [...unused.keys()];

    return obj;
}
