
import * as FontLoader from "@/util/FontLoader";
import { Debug } from "@/data/Environment";
import {
    type Anchor,
    type Repeat,
    type Sizing,
    type Slice,
    type Slices,
} from "@/pages/chat/components/Container";
import {
    size2string,
    string2size,
    string2sizes,
    type Sizes,
} from "@/util/shorthand";
import toObject, { type Lower, type Node } from "@/util/toObject";
import trackUsage from "@/util/trackUsage";
import { buildFont } from "./Font";

async function _processTheme(rootNode: Element, urlTransformer: (url: string) => string)
{
    // this is needed because the lint rule is buggy with this use case
    // see https://github.com/typescript-eslint/typescript-eslint/issues/9796
    // if there is no eslint-disable below this line, it means the bug was fixed and the rule was auto-removed
    // /* eslint-disable @typescript-eslint/no-unnecessary-condition */

    const root = toObject(rootNode);

    const vars = new Map<string, { key: string, val: string, used: boolean }>();
    const fonts = new Map<string, Promise<unknown>>();
    const assets = new Map<string, Promise<unknown>>();

    const req = {
        node([ n ]: (Node | undefined)[]): Node
        {
            if (typeof n === "undefined") throw new Error("Node not found");

            return n;
        },
        nodes(n: (Node | undefined)[])
        {
            if (n.length < 1) throw new Error("Node not found");

            return n as [Node, ...Node[]];
        },
        string(s: string | undefined)
        {
            AssertIsset(s, "Value not set");

            if (s.startsWith("$"))
            {
                const t = vars.get(s);

                AssertIsset(t, `Var '${s}' not set `);

                if (!t.used) vars.set(t.key, {...t, used: true});

                s = t.val;
            }

            return s;
        },
        enum<T extends readonly string[]>(s: string | undefined, v: T): T[number]
        {
            AssertIsset(s);
            AssertTrue(v.includes(s), `${s} is invalid. expected: ${v.join("|")}`);

            return s;
        },
        asset(s?: string)
        {
            const url = urlTransformer(this.string(s));

            if (!assets.has(url))
            {
                assets.set(url, new Promise((resolve, reject) =>
                {
                    const img = new Image();

                    img.onload = () => void resolve(url);

                    img.onerror = () =>
                    {
                        reject(new Error(`Unable to load ${s}`));
                    };

                    img.src = url;
                }));
            }

            return url;
        },
        color(str?: string)
        {
            /* eslint-disable @typescript-eslint/no-magic-numbers */

            AssertIsset(str, "Value not set");

            const [components, alpha] = ((str: string) =>
            {
                const [ primary1, ...alpha1 ] = str.split("/");
                const [ primary2, ...alpha2 ] = this.string(primary1).split("/");

                let components = primary2.toLowerCase().trim();

                components = (/^#[\da-f]+$/u.exec(components))
                    ? components.substring(1)
                    : "";

                const expand = (s: string) => s.split("")
                    .map(s => s+s)
                    .join("");

                switch (components.length)
                {
                    case 3: components = expand(components) + "ff";

                        break;
                    case 4: components = expand(components);

                        break;
                    case 6: components = components + "ff";

                        break;
                    case 8:

                        break;
                    default:
                        throw new Error("not a valid color");
                }

                const alpha = [...alpha2, ...alpha1].map(s =>
                {
                    if ((/^\d+%$/u.exec(s))) return s.slice(0, -1);

                    throw new Error("not a valid color");
                })
                    .map(s => parseFloat(s) / 100)
                    .reduce((acc, curr) => acc * curr, 1);

                return [
                    components,
                    alpha,
                ];
            })(str.toLowerCase().trim());


            const part = (a: number, b: number) => parseInt(components.slice(a, b), 16);

            const r = part(0, 2);
            const g = part(2, 4);
            const b = part(4, 6);

            let a = 100 * alpha * part(6, 8)/255;

            a = Math.min(Math.max(0, Math.round(a)), 100);

            return `rgb(${r} ${g} ${b} / ${a}%)`;

            /* eslint-enable @typescript-eslint/no-magic-numbers */
        },
        colors<T extends readonly Lower[]>(
            node: Node,
            colors: T,
        ): { [key in T[number]]: ReturnType<typeof this.color>; }
        {
            const data: ReturnType<typeof this.colors> = {};

            for (const name of colors)
            {
                data[name] = this.color(node[name]);
            }

            return data;
        },
        sizes(s?: string)
        {
            return string2sizes(this.string(s));
        },
        size(s?: string)
        {
            return size2string(string2size(this.string(s)));
        },
        number(s?: string)
        {
            return parseFloat(this.string(s));
        },
        fontFamily(s?: string)
        {
            AssertIsset(s);

            switch (s)
            {
                case "sans": return "var(--font-sans)";
                case "serif":return "var(--font-serif)";
                case "cursive": return "var(--font-cursive)";
                case "fantasy": return "var(--font-fantasy)";
                case "mono": return "var(--font-mono)";
                default:
                    AssertTrue(fonts.has(s), `Font '${s}' not defined`);

                    return s;
            }
        },
        font(node?: Node)
        {
            AssertIsset(node);

            return buildFont({
                family: opt.fontFamily(node.family$) ?? rootFont,
                size: opt.string(node.size$) as `${number}%` | undefined,
                line: opt.string(node.line$) as `${number}%` | undefined,
                spacing: opt.string(node.spacing$) as `${number}%` | undefined,
                color: opt.color(node.color$),
                variant: opt.enum(
                    node.variant$,
                    [
                        "thin", "thin-italics",
                        "extralight", "extralight-italics",
                        "light", "light-italics",
                        "normal", "normal-italics", "italics",
                        "medium", "medium-italics",
                        "semibold", "semibold-italics",
                        "bold", "bold-italics",
                        "extrabold", "extrabold-italics",
                        "black", "black-italics",
                    ] as const,
                ),
                transform: opt.enum(
                    node.casing$,
                    [ "none", "uppercase", "lowercase", "capitalize", "full-width" ] as const,
                ),
                decoration: (() =>
                {
                    const t = opt.node(node.Decoration$);

                    return t && ({
                        style: req.enum(
                            t.style,
                            ["solid", "double", "dotted", "dashed", "wavy"] as const,
                        ),
                        position: req.enum(
                            t.position,
                            ["above", "below", "behind", "through"] as const,
                        ),
                        color: req.color(t.color),
                    });
                })(),
                shadow: (() =>
                {
                    const t = opt.node(node.Shadow$);

                    return t && ({
                        x: req.size(t.x),
                        y: req.size(t.y),
                        blur: req.size(t.blur),
                        color: req.color(t.color),
                    });
                })(),
            });
        }
    };

    const opt = {
        node([ n ]: (Node | undefined)[])
        {
            return n;
        },
        nodes(n: (Node | undefined)[])
        {
            return n as Node[];
        },
        enum<T extends readonly string[]>(s: string | undefined, v: T): T[number] | undefined
        {
            return (typeof s === "undefined")? s: req.enum<T>(s, v);
        },
        string(s?: string)
        {
            return (typeof s === "undefined")? s: req.string(s);
        },
        asset(s?: string)
        {
            return (typeof s === "undefined")? s: req.asset(s);
        },
        color(s?: string)
        {
            return (typeof s === "undefined")? s: req.color(s);
        },
        colors<T extends readonly Lower[]>(
            node: Node,
            colors: T,
        ): { [key in T[number]]: ReturnType<typeof this.color>; }
        {
            const data: ReturnType<typeof this.colors> = {};

            for (const name of colors)
            {
                data[name] = this.color(node[`${name}$`]);
            }

            return data;
        },
        fontFamily(s?: string)
        {
            return (typeof s === "undefined")? s: req.fontFamily(s);
        },
        font(node?: Node)
        {
            return (!node)? node: req.font(node);
        },
        number(s?: string)
        {
            return (typeof s === "undefined")? s: req.number(s);
        },
        size(s?: string)
        {
            return (typeof s === "undefined")? s: req.size(s);
        },
        sizes(s?: string)
        {
            return (typeof s === "undefined")? s: req.sizes(s);
        }
    };

    AssertSimilar<typeof req, typeof opt>(true);

    const rootFont = req.fontFamily(req.node(root.Root).font);

    const Definitions = req.node(root.Definitions);

    const setVar = (node: Node) =>
    {
        const key = node.key;
        const val = node.val;

        AssertIsset(key);
        AssertIsset(val);

        AssertFalse(vars.has(key), `Duplicate var '${key}'`);

        vars.set(key, { key, val, used: false });
    };

    const addFont = (node: Node) =>
    {
        const family = node.family;
        const fallback = node.fallback;

        AssertIsset(family);
        AssertIsset(fallback);

        const name = FontLoader.getGoogleFontName(family);

        AssertFalse(fonts.has(name), `Duplicate font '${name}'`);

        fonts.set(name, FontLoader.loadGoogleFonts([family, fallback as FontLoader.Fallback]));
    };

    opt.nodes(Definitions.Color$).forEach(setVar);
    opt.nodes(Definitions.Font$).forEach(addFont);

    const parseSlice = (node?: Node): Slice =>
    {
        if (!node) return "";

        // TODO: validate
        return {
            img: req.asset(node.img),
            anchor: opt.string(node.anchor$) as Anchor | undefined,
            repeat: opt.string(node.repeat$) as Repeat | undefined,
            size: opt.string(node.size$) as Sizing | undefined,
        };
    };

    const parseSlices = (slices: Node[]) => slices.map((slice): Slices => ({
        images: [
            [
                parseSlice(opt.node(slice.TopLeft$)),
                parseSlice(opt.node(slice.Top$)),
                parseSlice(opt.node(slice.TopRight$)),
            ], [
                parseSlice(opt.node(slice.Left$)),
                parseSlice(opt.node(slice.Center$)),
                parseSlice(opt.node(slice.Right$)),
            ], [
                parseSlice(opt.node(slice.BottomLeft$)),
                parseSlice(opt.node(slice.Bottom$)),
                parseSlice(opt.node(slice.BottomRight$)),
            ]
        ],
        insets: req.sizes(slice.insets),
        sizes: req.sizes(slice.slices),
        layer: opt.string(slice.layer$) === "above"? 1: 0,
        filter: opt.string(slice.filter$) ?? "",
        transform:opt.string(slice.transform$) ?? "",
    }));

    const parseContainer = (node: Node) =>
    {
        const Container = req.node(node.Container);
        const Slices = opt.nodes(node.Slices$);

        const Fill = opt.node(Container.Fill$);
        const Border = opt.node(Container.Border$);
        const Shadow = opt.node(Container.Shadow$);
        const Effect = opt.node(Container.Effect$);

        const background = Fill? req.color(Fill.color): "";

        const border = (() =>
        {
            if (!Border) return "";

            const [width, style, color] = [
                opt.size(Border.width$),
                opt.enum(Border.style$, ["dashed", "dotted", "solid"] as const),
                opt.color(Border.color$),
            ];

            return [
                width,
                style ?? "none",
                color,
            ].filter((s): s is string => !!s).join(" ");
        })();

        const radius: Sizes = opt.sizes(Border?.radius$) ?? [];

        const shadow = Shadow? [
            req.string(Shadow.x),
            req.string(Shadow.y),
            req.string(Shadow.blur),
            req.string(Shadow.spread),
            req.color(Shadow.color),
        ].join(" "): "";

        const filter = Effect? req.string(Effect.filter): "";
        const transform = Effect? req.string(Effect.transform): "";

        return {
            container: {
                padding: req.sizes(Container.padding),
                margin: req.sizes(Container.margin),
                background,
                shadow,
                border,
                radius,
                filter,
                transform,
            },
            slices: parseSlices(Slices),
        };
    };

    const parseSticky = (node: Node) => ({
        stickyMargin: req.size(node.stickyMargin),
    });

    const parseContainerSticky = (node: Node) => ({
        ...parseSticky(node),
        ...parseContainer(node),
    });

    const parseTextContainer = (node: Node) => ({
        Text: opt.font(opt.node(node.Text$)),
        Accent: opt.font(opt.node(node.Accent$)),
        ...parseContainer(node),
    });

    const parseTextContainerSticky = (node: Node) => ({
        ...parseSticky(node),
        ...parseTextContainer(node),
    });

    const parseHeadingWithButton = (node: Node) => ({
        ...parseTextContainerSticky(node),
        Button: parseTextContainer(req.node(node.Button)),
    });

    const colors = {
        bg: ["background"],
        fg_bg: ["foreground", "background"],
        fg_bg_in_out: ["foreground", "background", "inner", "outer"],
        bg_acc: ["background", "accent"],
        fg_bg_acc: ["foreground", "background", "accent"],
        fg_bg_hnt: ["foreground", "background", "hint"],
        fg_bg_hnt_acc: ["foreground", "background", "hint", "accent"],
    } as const;

    const parseChat = (node: Node) =>
    {
        const Layout = req.node(node.Layout);
        const Content = req.node(node.Content);

        return {
            Layout: {
                Header: parseContainer(req.node(Layout.Header)),
                Single: parseContainer(req.node(Layout.Single)),
                Below: parseContainer(req.node(Layout.Below)),
                Above: parseContainer(req.node(Layout.Above)),
                Between: parseContainer(req.node(Layout.Between)),
                width: req.enum(Layout.width, ["full", "part", "join", "auto"] as const),
            },
            Content: {
                Message: parseTextContainer(req.node(Content.Message)),
                Avatar: ((node?: Node) =>
                {
                    if (!node) return node;

                    return {
                        ...parseContainer(node),
                        display: req.enum(req.node(Content.Avatar).display, ["start", "end"] as const),
                    };
                })(opt.node(Content.Avatar$)),
                Name: opt.font(opt.node(Content.Name$)),
                Time: opt.font(opt.node(Content.Time$)),
                Title:  ((node?: Node) =>
                {
                    if (!node) return node;

                    return {
                        ...req.font(node),
                        text: req.string(node.content),
                    };
                })(opt.node(Content.Title$))
            },
        };
    };

    const theme = {
        Meta: ((node: Node) => ({
            title: req.string(node.title),
            artist: req.string(node.artist),
            description: req.string(node.description),
            baseColor: req.color(node.baseColor),
        }))(req.node(root.Meta)),

        Root: ((node: Node) => ({
            font: rootFont,
            mode: req.enum(node.mode, ["light", "dark"] as const),
            ...parseContainer(node),
        }))(req.node(root.Root)),

        Header: ((node: Node) => ({
            ...req.colors(node, colors.bg_acc),
            Normal: ((node: Node) => ({
                ...req.colors(node, colors.fg_bg_in_out),
            }))(req.node(node.Normal)),
            Active: ((node: Node) => ({
                ...req.colors(node, colors.fg_bg_in_out),
            }))(req.node(node.Active)),
            Popup: ((node: Node) => ({
                ...req.colors(node, colors.fg_bg_acc),
            }))(req.node(node.Popup)),
        }))(req.node(root.Header)),

        System: ((node: Node) => ({
            Chip: parseTextContainer(req.node(node.Chip)),
            Callout: parseTextContainer(req.node(node.Callout)),
            Avatar: parseContainer(req.node(node.Avatar)),
        }))(req.node(root.System)),

        CallToAction: ((node: Node) => ({
            GameStart: parseTextContainer(req.node(node.GameStart)),
            GameWaiting: parseTextContainer(req.node(node.GameWaiting)),
            Active: parseTextContainerSticky(req.node(node.Active)),
            Static: parseTextContainerSticky(req.node(node.Static)),
        }))(req.node(root.CallToAction)),

        Chat: ((node: Node) => ({
            Sent: parseChat(req.node(node.Sent)),
            Received: parseChat(req.node(node.Received)),
        }))(req.node(root.Chat)),

        Headline: ((node: Node) => ({
            ...req.colors(node, colors.fg_bg_acc),

            Text: req.font(req.node(node.Text)),
            Accent: req.font(req.node(node.Accent)),
            Initial: ((node: Node) => ({
                transform: opt.string(node.transform$),
                ...req.font(node),
            }))(req.node(node.Initial)),

            Stamp: ((node?: Node) =>
            {
                if (!node) return node;

                return {
                    img: req.asset(node.img),
                    width: req.size(node.width),
                    height: req.size(node.height),
                    transform: req.string(node.transform),
                };
            })(opt.node(node.Stamp$)),
        }))(req.node(root.Headline)),

        HeadlineSubmit: ((node: Node) => ({
            Active: parseTextContainerSticky(req.node(node.Active)),
            Sticky: ((node: Node) => ({
                ...parseTextContainerSticky(node),
                stickyMarginBottom: req.size(node.stickyMarginBottom),
            }))(req.node(node.Sticky)),
        }))(req.node(root.HeadlineSubmit)),

        PhotoSubmit: ((node: Node) => ({
            Active: parseTextContainerSticky(req.node(node.Active)),
            Sticky: ((node: Node) => ({
                ...parseTextContainerSticky(node),
                stickyMarginBottom: req.size(node.stickyMarginBottom),
            }))(req.node(node.Sticky)),
            Static: parseTextContainer(req.node(node.Static)),
            Avatar: ((node: Node) => ({
                Submitted: parseContainer(req.node(node.Submitted)),
                Waiting: parseContainer(req.node(node.Waiting)),
            }))(req.node(node.Avatar)),
        }))(req.node(root.PhotoSubmit)),

        Voting: ((node: Node) => ({
            Active: ((node: Node) => ({
                Heading: parseHeadingWithButton(req.node(node.Heading)),
                Photos: parseContainerSticky(req.node(node.Photos)),
            }))(req.node(node.Active)),
            Sticky: ((node: Node) => ({
                Heading: parseHeadingWithButton(req.node(node.Heading)),
                Photos: parseContainerSticky(req.node(node.Photos)),
            }))(req.node(node.Sticky)),
            Static: ((node: Node) => ({
                Heading: parseHeadingWithButton(req.node(node.Heading)),
                Photos: parseContainer(req.node(node.Photos)),
            }))(req.node(node.Static)),
        }))(req.node(root.Voting)),

        Results: ((node: Node) => ({
            Winner: parseHeadingWithButton(req.node(node.Winner)),
            Heading: parseHeadingWithButton(req.node(node.Heading)),
            Board: ((node: Node) => ({
                ...parseContainer(node),
                Place: req.font(req.node(node.Place)),
                Name: req.font(req.node(node.Name)),
                Score: req.font(req.node(node.Score)),
                Suffix:  ((node: Node) => ({
                    ...req.font(node),
                    text: req.string(node.content),
                }))(req.node(node.Suffix))
            }))(req.node(node.Board)),
        }))(req.node(root.Results)),

        Footer: ((node: Node) => ({
            ...req.colors(node, colors.fg_bg),

            Chat: ((node: Node) => ({
                Normal: req.colors(req.node(node.Normal), colors.fg_bg_hnt_acc),
                Active: req.colors(req.node(node.Active), colors.fg_bg_hnt_acc),
            }))(req.node(node.Chat)),

            Settings: ((node: Node) => ({
                Host: req.colors(req.node(node.Host), colors.fg_bg_hnt_acc),
                Player: req.colors(req.node(node.Player), colors.fg_bg_hnt_acc),
            }))(req.node(node.Settings)),
        }))(req.node(root.Footer)),
    };

    const reportUnused = (list: unknown[], name: string) =>
    {
        if (list.length === 0) return;

        Logger.warn(`${list.length} unused ${name}`, list);
    };

    reportUnused(root._unused(), "properties");
    reportUnused([...vars.values()].filter(({used}) => !used).map(({key}) => key), "variables");

    await (async () =>
    {
        const err = (await Promise.allSettled([
            ...fonts.values(),
            ...assets.values(),
        ]))
            .filter((p): p is PromiseRejectedResult => p.status === "rejected")
            .map(r => r.reason as Error);

        if (err.length > 0)
        {
            Logger.error(...err);

            throw new Error("Some Assets could not be loaded");
        }
    })();

    if (!Debug.enabled) return theme;

    return trackUsage(theme, ({used, unused}) =>
    {
        const progress = (used.length/(used.length + unused.length))*100;

        Logger.log(`Theme coverage: ${progress.toFixed(0)}%`, {used, unused});
    }, 1000);
}

export type Theme = Awaited<ReturnType<typeof _processTheme>>;

export const processTheme = async (
    theme: string,
    urlTransformer: (url: string) => string,
    transformTheme = true,
): Promise<Theme> =>
{
    Logger.log("loading", theme);

    const xmlData = await fetch(transformTheme? urlTransformer(theme): theme, {
        cache: "no-store",
    }).then(response => response.text());

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, "text/xml");

    const root = xmlDoc.documentElement;

    if (root.nodeName === "parsererror")
    {
        throw new Error(root.textContent ?? "Unknown error");
    }

    try
    {
        const data = await _processTheme(root, urlTransformer);

        Logger.log("loaded", {
            title: data.Meta.title,
            artist: data.Meta.artist,
            description: data.Meta.description,
        });

        return data;
    }
    catch (e)
    {
        Logger.error({theme_error: e});

        return e as Theme;
    }
};

