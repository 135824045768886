import type { WindTypes } from "@/util/Tailwind";
import type { Player } from "@/game/types/Game";
import type { ComponentProps } from "react";
import Box from "./Box";
import type { Remove } from "@/realize-shared/Types";

const Polaroid = ({
    player,
    photo,
    styles,
    ...props
}: {
    player?: Player,
    photo: string;
    styles: WindTypes,
} & Remove<ComponentProps<typeof Box>, "className">) => (
    <Box
        className={Wind.mergeProps({
            flexShrink: "shrink-0",
            display: "inline-flex",
            flexDirection: "flex-col",
            backgroundColor: "bg-white",
            padding: "p-1",
            boxShadow: "shadow",
            aspectRatio: "aspect-[9/13]",
            overflow: "overflow-hidden",
        }, styles)}
        {...props}
    >
        <img
            src={photo}
            alt={player ? player.name : "submission"}
            className={Wind.style({
                backgroundColor: "bg-gray-300",
                width: "w-full",
                objectFit: "object-cover",
                aspectRatio: "aspect-[3/4]",
                pointerEvents: "pointer-events-none",
            }).class}
        />
        {player && (
            <img
                src={player.avatar.path}
                className={Wind.style({
                    backgroundColor: "bg-white",
                    width: "w-[30%]",
                    padding: "p-0.5",
                    borderRadius: "rounded-full",
                    objectFit: "object-cover",
                    aspectRatio: "aspect-square",
                    pointerEvents: "pointer-events-none",
                    transformTranslateY: "-translate-y-1/2",
                    transformTranslateX: "-translate-x-0.5"
                }).class}
            />
        )}
    </Box>
);

export default Polaroid;
