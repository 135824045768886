import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";
import PlayerStatusMessage from "../components/PlayerStatusMessage";

const PhotoSubmit = (event: GameNodeProps<Type.PHOTO_SUBMIT>) =>
{
    return (
        <PlayerStatusMessage
            {...event}
            text="$PLAYER submitted a photo"
        />
    );
};

export default PhotoSubmit;
