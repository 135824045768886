// what do do if not Vite
const DROP_BY_DEFAULT = false;

// hack to make this work with both Vite and nodejs
const DROP_ASSERTIONS = ((import.meta) as { env?: { PROD?: boolean } }).env?.PROD ?? DROP_BY_DEFAULT;

type Message = undefined | string | (() => string | undefined);

function raise(
    this: void,
    message: Message,
): never
{
    if (typeof message === "function") message = message();

    throw new Error(message);
}

export function isTrue(
    this: void,
    condition: boolean,
    message?: Message,
): asserts condition is true
{
    if (DROP_ASSERTIONS) return;
    if (!condition) raise(message);
}

export function isFalse(
    this: void,
    condition: boolean,
    message?: Message,
): asserts condition is false
{
    if (DROP_ASSERTIONS) return;
    if (condition) raise(message);
}

export function isNonNullish<Type>(
    this: void,
    condition: Type,
    message?: Message,
): asserts condition is Exclude<NonNullable<Type>, undefined>
{
    if (DROP_ASSERTIONS) return;
    if (condition === null || typeof condition === "undefined") raise(message);
}

export function fail(
    this: void,
    message?: Message,
): never
{
    if (DROP_ASSERTIONS) return null as never;

    raise(message);
}

export function compareValues(
    this: void,
    matrix: Record<string, { actual: unknown, expected: unknown }>,
): void
{
    if (DROP_ASSERTIONS) return;

    for (const [name, entry] of Object.entries(matrix))
    {
        const { actual, expected } = entry;

        if ( actual !== expected) raise(`${name} not supported.`);
    }
}

/**
 * Compile-time test that T1 and T2 have identical/different keys
 * @param equality true if they are expected to have same keys, false otherwise
 */
export function similar<T1 extends object, T2 extends object>(
    equality: [
        keyof T1 extends keyof T2? true: false,
        keyof T2 extends keyof T1? true: false,
    ] extends [true, true]? true: false,
): void
{
    return void equality;
}
