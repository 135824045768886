import DotPattern from "@/web/img/central-dot-pattern.webp";
import Texture01 from "@/web/img/central-texture-01.webp";

export default function CentralBackground()
{
    return (
        <div
            className={Wind.style({
                position: "absolute",
                width: "w-full",
                height: "h-full",
                zIndex: "z-[1]",
                pointerEvents: "pointer-events-none"
            }).class}
            style={{
                // eslint-disable-next-line @stylistic/max-len
                background: "radial-gradient(100% 100% at 80.08% 0%, rgba(25, 72, 98, 0.40) 0%, rgba(25, 72, 98, 0.00) 100%)"
            }}
        >
            <div
                className={Wind.style({
                    position: "absolute",
                    width: "w-full",
                    height: "h-full",
                    pointerEvents: "pointer-events-none"
                }).class}
                style={{
                    background: `url(${Texture01}) 73px 73px repeat`,
                    mixBlendMode: "multiply",
                    opacity: 0.12,
                }}
            />
            <div
                className={Wind.style({
                    position: "absolute",
                    width: "w-full",
                    height: "h-1/2",
                    pointerEvents: "pointer-events-none"
                }).class}
                style={{
                    background: `url(${DotPattern})`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "top center",
                    opacity: 0.15,
                }}
            />
        </div>
    );
}
