type Name = string;

type URI = string;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
type Weight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type Style = "normal" | "italic" | "oblique";

type Weights = Partial<Record<Weight, URI>>;

type Styles = Partial<Record<Style, Weights>>;

type Fonts = Record<Name, Styles>;

const ensureFonts = async (callback: (
    load: (name: string, style: string, weight: string) => void
) => Promise<unknown>) =>
{
    const element = document.createElement("div");

    element.style.position = "fixed";
    element.style.top = "100%";
    element.style.left = "100%";
    element.style.height = "1px";
    element.style.width = "1px";
    element.style.overflow = "hidden";

    await callback((name: string, style: string, weight: string) =>
    {
        const line = document.createElement("p");

        line.textContent = `${name}${weight}${style}`;

        line.style.fontFamily = name;
        line.style.fontStyle = style;
        line.style.fontWeight = weight;

        element.append(line);
    });

    document.body.append(element);

    await document.fonts.ready;

    element.remove();
};

export const load = async (fonts: Fonts) =>
{
    await ensureFonts(async (load) =>
    {
        const promises: Promise<unknown>[] = [];

        for (const [name, styles] of Object.entries(fonts))
        {
            for (const [style, weights] of Object.entries(styles))
            {
                for (const [weight, uri] of Object.entries(weights))
                {
                    const url = `url(${uri}`;

                    const desc = { weight, style };

                    promises.push(
                        new FontFace(name, url, desc)
                            .load()
                            .then(
                                (font) => document.fonts.add(font),
                            )
                            .then(() =>
                            {
                                load(name, style, weight);
                            }),
                    );
                }
            }
        }

        await Promise.all(promises);
    });
};

export type Fallback =
    | "serif"
    | "sans-serif"
    | "monospace"
    | "cursive"
    | "fantasy"
    | "system-ui";

export const getGoogleFontName = (spec: string) => spec.split(":")[0].replaceAll("+", " ");

const _loadGoogleFont = async (spec: string) => new Promise<string>((resolve, reject) =>
{
    const family = getGoogleFontName(spec);
    const source = `https://fonts.googleapis.com/css2?family=${spec}&display=swap`;
    const link = document.createElement("link");

    link.addEventListener("load", () => void resolve(family));
    link.addEventListener("error", () => void reject(new Error(`Unable to load font ${family}`)));

    link.rel = "stylesheet";
    link.href = source;

    document.head.append(link);
});

export const loadGoogleFonts = async (...fonts: [spec: string, fallback: Fallback][]) =>
{
    const sheet = (() =>
    {
        const temp = document.head.querySelector<HTMLStyleElement>("style#fonts");

        if (temp) return temp;

        const sheet = document.createElement("style");

        sheet.id = "fonts";
        document.head.append(sheet);

        // webkit hack (.sheet does not exist without this)
        sheet.append(document.createTextNode(""));

        return sheet;
    })();

    await ensureFonts(async (load) =>
    {
        const promises: Promise<string>[] = [];

        for (const [ spec, fallback, ] of fonts)
        {
            promises.push(_loadGoogleFont(spec)
                .then((family) =>
                {
                    const name = `.${family.replaceAll(" ", "_")}`;
                    const rule = `font-family: "${family}", ${fallback}`;

                    sheet.sheet?.insertRule(`${name} { ${rule}; }`);

                    for (let i = 1; i < 10; i++)
                    {
                        load(family, "normal", String(i*100));
                        load(family, "italic", String(i*100));
                    }

                    return family;
                }));
        }

        await Promise.all(promises).then((fonts) => void Logger.log("Loaded", fonts));
    });
};

export const loadDefaults = async () =>
{
    await loadGoogleFonts([
        "Patrick+Hand",
        "cursive",
    ], [
        "Monoton",
        "fantasy",
    ], [
        "Playfair+Display:ital,wght@0,400..900;1,400..900",
        "serif",
    ], [
        // eslint-disable-next-line @stylistic/max-len
        "Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
        "sans-serif",
    ], [
        // eslint-disable-next-line @stylistic/max-len
        "Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
        "sans-serif",
    ], [
        "Courier+Prime:ital,wght@0,400;0,700;1,400;1,700",
        "monospace"
    ]);

    await load({
        "Open Dyslexic": {
            "normal": {
                "400": new URL("../assets/fonts/OpenDyslexic-Regular.otf", import.meta.url).href,
                "700": new URL("../assets/fonts/OpenDyslexic-Bold.otf", import.meta.url).href,
            },
            "italic": {
                "400": new URL("../assets/fonts/OpenDyslexic-Italic.otf", import.meta.url).href,
                "700": new URL("../assets/fonts/OpenDyslexic-Bold-Italic.otf", import.meta.url).href,
            },
        },
    });
};
