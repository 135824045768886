import { Type } from "@/realize-shared/model/Event";
import { EventState } from "./ProcessedEventMap";
import type { GameContext } from "../types/Game";
import Phase from "@/realize-shared/model/Phase";
import type { ProcessedEvent } from "./ProcessedEvent";

export default function updateEvents(
    this: void,
    event: Readonly<ProcessedEvent<Type>>,
    game: Readonly<GameContext>,
    interactive: boolean,
)
{
    const gameEnded = event.type === Type.GAME_END;
    const events = [...game.events, event];
    const {round, self, host} = game;

    const updated = events.map((event): ProcessedEvent<Type> | null =>
    {
        if (event.state === EventState.STATIC)
        {
            return null;
        }

        // todo: refactor into a table or something
        // this function is confusing
        // it is just a table of outcomes

        // eslint-disable-next-line complexity
        const state: EventState | null = (() =>
        {
            if (gameEnded) return EventState.STATIC;

            if (event.type === Type.GAME_START)
            {
                return (round)
                    ? EventState.STATIC
                    : (interactive && host === self)
                        ? EventState.ACTIVE
                        : EventState.STICKY;
            }

            if (round && event.round.number < round.number)
            {
                return EventState.STATIC;
            }

            const { editor, phase, submissions } = round ?? event.round;

            switch (event.type)
            {
                case Type.ROUND_START:
                    return (phase !== Phase.HEADLINE_CREATION)
                        ? EventState.STATIC
                        : (interactive && editor === self)
                            ? EventState.ACTIVE
                            : EventState.STICKY;

                case Type.HEADLINE_CREATE:
                    return (phase !== Phase.PHOTO_SUBMISSION)
                        ? EventState.STATIC
                        : (interactive && editor !== self && !submissions.has(self.id))
                            ? EventState.ACTIVE
                            : EventState.STICKY;

                case Type.VOTE_START:
                    return (phase !== Phase.VOTING)
                        ? EventState.STATIC
                        : (interactive && editor === self)
                            ? EventState.ACTIVE
                            : EventState.STICKY;

                case Type.VOTE_RESULT:
                    // state = EventState.STICKY;

                    break;
            }

            return null;
        })();

        if (state === null || state === event.state)
        {
            return null;
        }

        return {...event, state};
    }).reduce((updated, event, index) =>
    {
        if (event)
        {
            updated = true;

            events[index] = event;
        }

        return updated;
    }, false);

    return { events, updated };
}
