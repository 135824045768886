import type {
    UpdateRulesRequest,
    StartGameRequest,
    SubmitHeadlineRequest,
    SubmitPhotoRequest,
    SelectWinnerRequest,
} from "@/realize-shared/model/RequestTypes";
import type { Rules, Data, RoomID } from "@/realize-shared/model/Types";
import type { Player } from "@/game/types/Game";
import * as Requests from "./Requests";

export const sendRuleChanges = async (
    roomID: RoomID,
    rules: Rules,
) => Requests.post<UpdateRulesRequest>(
    true,
    "player/updateRules",
    { roomID, rules },
);

export const sendStartGameRequest = async (
    roomID: RoomID,
) => Requests.post<StartGameRequest>(
    true,
    "player/startGame",
    { roomID },
);

export const submitHeadlineRequest = async (
    roomID: RoomID,
    headline: string,
) => Requests.post<SubmitHeadlineRequest>(
    true,
    "player/submitHeadline",
    { roomID, headline },
);

export const submitPhotoRequest = async (
    roomID: RoomID,
    photoData: Data,
) => Requests.post<SubmitPhotoRequest>(
    true,
    "player/submitPhoto",
    { roomID, photoData },
);

export const selectWinner = async (
    roomID: RoomID,
    winner: Player,
) => Requests.post<SelectWinnerRequest>(
    true,
    "player/selectWinner",
    { roomID, winnerID: winner.id },
);
