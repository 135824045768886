// DO NOT EDIT THIS FILE
// IT IS GENERATED AT BUILD

import a$0 from "../assets/img/logo.svg";
import a$1 from "../assets/img/crown.svg";
import a$2 from "../assets/img/default_magazine.webp";
import a$3 from "../assets/img/editor-symbol.svg";
import a$4 from "../assets/img/jobo-beta.webp";
import a$5 from "../assets/img/loading-wheel.gif";
import a$6 from "../assets/img/audience-icon.svg";
import a$7 from "../assets/img/tutorial/step-2-label.webp";
import a$8 from "../assets/img/tutorial/step-4-label.webp";
import a$9 from "../assets/img/tutorial/realize-logo.webp";
import a$10 from "../assets/img/tutorial/settings-panel.webp";
import a$11 from "../assets/img/tutorial/grunge-texture.webp";
import a$12 from "../assets/img/tutorial/panel-1.webp";
import a$13 from "../assets/img/tutorial/step-3-label.webp";
import a$14 from "../assets/img/tutorial/how-to-play.webp";
import a$15 from "../assets/img/tutorial/panel-3.webp";
import a$16 from "../assets/img/tutorial/panel-4.webp";
import a$17 from "../assets/img/tutorial/panel-2.webp";
import a$18 from "../assets/img/tutorial/step-1-label.webp";
import a$19 from "../assets/img/layout/history-background.png";
import a$20 from "../assets/img/layout/raw-jagged-button.svg";
import a$21 from "../assets/img/layout/jagged-button-poppy.svg";
import a$22 from "../assets/img/layout/join-bottom-fray.svg";
import a$23 from "../assets/img/layout/modal-close-button-bg.svg";
import a$24 from "../assets/img/layout/join-top-fray.svg";
import a$25 from "../assets/img/layout/heading-background.svg";
import a$26 from "../assets/img/layout/black-triangle.svg";
import a$27 from "../assets/img/layout/how-to-button-bg.svg";
import a$28 from "../assets/img/layout/no-history-background-badge.svg";
import a$29 from "../assets/img/layout/pop-up-top-bar.svg";
import a$30 from "../assets/img/layout/jagged-button-red.svg";
import a$31 from "../assets/img/layout/menu/barcode.svg";
import a$32 from "../assets/img/layout/menu/article-image.png";
import a$33 from "../assets/img/layout/game/right-swipe-bg.png";
import a$34 from "../assets/img/layout/game/clock.svg";
import a$35 from "../assets/img/layout/game/winner-card-bg.svg";
import a$36 from "../assets/img/layout/game/winner.png";
import a$37 from "../assets/img/layout/game/left-swipe-bg.png";
import a$38 from "../assets/img/layout/game/game-over.webp";
import a$39 from "../assets/img/layout/game/create-headline-background.svg";
import a$40 from "../assets/img/layout/game/position-card-bg.svg";
import a$41 from "../assets/img/layout/game/canvas-color-selector-bg.svg";
import a$42 from "../assets/img/layout/login/top-left-shape.svg";
import a$43 from "../assets/img/layout/login/account-button-bg.svg";
import a$44 from "../assets/img/layout/login/login-mountain.webp";
import a$45 from "../assets/img/layout/shared/square-button-bg.svg";
import a$46 from "../assets/img/layout/shared/prompt-bg.svg";
import a$47 from "../assets/img/layout/shared/create-add.svg";
import a$48 from "../assets/img/layout/store/headline-icon.svg";
import a$49 from "../assets/img/layout/store/unlocked.svg";
import a$50 from "../assets/img/layout/store/item-background.svg";
import a$51 from "../assets/img/layout/history/stats-background.svg";
import a$52 from "../assets/img/layout/identity-selector/top-arrow.svg";
import a$53 from "../assets/img/layout/report/report-icon.svg";
import a$54 from "../assets/img/layout/report/ending-alert-bg.svg";
import a$55 from "../assets/img/magazine/barcode.png";
import a$56 from "../assets/img/magazine/logo.png";
import a$57 from "../assets/img/settings/background.webp";
import a$58 from "../assets/img/settings/cart.svg";
import a$59 from "../assets/img/account-restore/background-mountains.png";
import a$60 from "../assets/img/account-restore/background-top-texture.png";
import a$61 from "../assets/img/symbolic/loader.svg";
import a$62 from "../assets/img/symbolic/clock.svg";
import a$63 from "../assets/img/symbolic/flip.svg";
import a$64 from "../assets/img/symbolic/quit.svg";
import a$65 from "../assets/img/symbolic/caption-trash.svg";
import a$66 from "../assets/img/symbolic/dialog-button.svg";
import a$67 from "../assets/img/symbolic/fontselect.svg";
import a$68 from "../assets/img/symbolic/create-room-plus.svg";
import a$69 from "../assets/img/symbolic/chevron-left.svg";
import a$70 from "../assets/img/symbolic/pencil.svg";
import a$71 from "../assets/img/symbolic/thin-check.svg";
import a$72 from "../assets/img/symbolic/dice.svg";
import a$73 from "../assets/img/symbolic/share.svg";
import a$74 from "../assets/img/symbolic/trash-can.svg";
import a$75 from "../assets/img/symbolic/history.svg";
import a$76 from "../assets/img/symbolic/kick.svg";
import a$77 from "../assets/img/symbolic/camera.svg";
import a$78 from "../assets/img/symbolic/caret.svg";
import a$79 from "../assets/img/symbolic/accessibility.svg";
import a$80 from "../assets/img/symbolic/store.svg";
import a$81 from "../assets/img/symbolic/settings-cog.svg";
import a$82 from "../assets/img/symbolic/close.svg";
import a$83 from "../assets/img/symbolic/man-run-door.svg";
import a$84 from "../assets/img/symbolic/home.svg";
import a$85 from "../assets/img/symbolic/social-share.svg";
import a$86 from "../assets/img/symbolic/cross.svg";
import a$87 from "../assets/img/symbolic/light-bulb.svg";
import a$88 from "../assets/img/symbolic/info-icon.svg";
import a$89 from "../assets/img/symbolic/caption-letters.svg";
import a$90 from "../assets/img/symbolic/checkmark.svg";
import a$91 from "../assets/img/symbolic/error-icon.svg";
import a$92 from "../assets/img/symbolic/copy.svg";
import a$93 from "../assets/img/symbolic/flash.svg";
import a$94 from "../assets/img/symbolic/play.svg";
import a$95 from "../assets/img/symbolic/photo-retake.svg";
import a$96 from "../assets/img/symbolic/capture.svg";
import a$97 from "../assets/img/symbolic/report.svg";
import a$98 from "../assets/img/symbolic/color-palette.svg";
import a$99 from "../assets/img/symbolic/chevron-right.svg";
import a$100 from "../assets/img/icons/animated-r.svg";
import a$101 from "../assets/img/icons/permissions-camera.svg";
import a$102 from "../assets/img/icons/permissions-bell.svg";
import a$103 from "../assets/img/viewer/landing-bottom-sliver.svg";
import a$104 from "../assets/img/viewer/landing-top-sliver.svg";
import a$105 from "../assets/img/store/price-tag-left-pressed.png";
import a$106 from "../assets/img/store/store-header-icon.svg";
import a$107 from "../assets/img/store/card-title-slice.svg";
import a$108 from "../assets/img/store/header-background.svg";
import a$109 from "../assets/img/store/store-special-offer.svg";
import a$110 from "../assets/img/store/price-tag-left.png";
import a$111 from "../assets/img/store/section-arrow.svg";
import a$112 from "../assets/img/store/hanger.svg";
import a$113 from "../assets/img/store/cardboard-texture.png";
import a$114 from "../assets/img/store/filter-button-background.svg";
import a$115 from "../assets/img/store/filter-button-icon.svg";
import a$116 from "../assets/img/store/price-tag-center.png";
import a$117 from "../assets/img/store/price-tag-right-pressed.png";
import a$118 from "../assets/img/store/pack-background.webp";
import a$119 from "../assets/img/store/bg-pattern.png";
import a$120 from "../assets/img/store/owned-check.webp";
import a$121 from "../assets/img/store/price-tag-center-pressed.png";
import a$122 from "../assets/img/store/price-tag-right.png";
import a$123 from "../assets/img/store/icons/variations-icon.svg";
import a$124 from "../assets/img/store/icons/owned-items.svg";
import a$125 from "../assets/img/store/icons/all-items.svg";
import a$126 from "../assets/img/chat/send.svg";
import a$127 from "../assets/img/chat/pen-icon.svg";
import a$128 from "../assets/img/chat/collapse.svg";
import a$129 from "../assets/img/chat/expand.svg";
import a$130 from "../assets/img/chat/alert-background.svg";
import a$131 from "../assets/img/backgrounds/halftone-header.webp";
import a$132 from "../assets/img/backgrounds/mountains.webp";
import a$133 from "../assets/img/backgrounds/end-game-background.webp";
import a$134 from "../assets/img/backgrounds/viewer-background.webp";
import a$135 from "../assets/img/backgrounds/home-bg.webp";
import a$136 from "../assets/img/backgrounds/glow_small.webp";
import a$137 from "../assets/img/backgrounds/hatches.webp";
import a$138 from "../assets/img/backgrounds/glow_large.webp";
import a$139 from "../assets/img/backgrounds/space.webp";
import a$140 from "../assets/img/backgrounds/how-to-background.webp";
import a$141 from "../assets/img/backgrounds/tutorial-bg.webp";

const assets = [
    a$0,
    a$1,
    a$2,
    a$3,
    a$4,
    a$5,
    a$6,
    a$7,
    a$8,
    a$9,
    a$10,
    a$11,
    a$12,
    a$13,
    a$14,
    a$15,
    a$16,
    a$17,
    a$18,
    a$19,
    a$20,
    a$21,
    a$22,
    a$23,
    a$24,
    a$25,
    a$26,
    a$27,
    a$28,
    a$29,
    a$30,
    a$31,
    a$32,
    a$33,
    a$34,
    a$35,
    a$36,
    a$37,
    a$38,
    a$39,
    a$40,
    a$41,
    a$42,
    a$43,
    a$44,
    a$45,
    a$46,
    a$47,
    a$48,
    a$49,
    a$50,
    a$51,
    a$52,
    a$53,
    a$54,
    a$55,
    a$56,
    a$57,
    a$58,
    a$59,
    a$60,
    a$61,
    a$62,
    a$63,
    a$64,
    a$65,
    a$66,
    a$67,
    a$68,
    a$69,
    a$70,
    a$71,
    a$72,
    a$73,
    a$74,
    a$75,
    a$76,
    a$77,
    a$78,
    a$79,
    a$80,
    a$81,
    a$82,
    a$83,
    a$84,
    a$85,
    a$86,
    a$87,
    a$88,
    a$89,
    a$90,
    a$91,
    a$92,
    a$93,
    a$94,
    a$95,
    a$96,
    a$97,
    a$98,
    a$99,
    a$100,
    a$101,
    a$102,
    a$103,
    a$104,
    a$105,
    a$106,
    a$107,
    a$108,
    a$109,
    a$110,
    a$111,
    a$112,
    a$113,
    a$114,
    a$115,
    a$116,
    a$117,
    a$118,
    a$119,
    a$120,
    a$121,
    a$122,
    a$123,
    a$124,
    a$125,
    a$126,
    a$127,
    a$128,
    a$129,
    a$130,
    a$131,
    a$132,
    a$133,
    a$134,
    a$135,
    a$136,
    a$137,
    a$138,
    a$139,
    a$140,
    a$141,
];

export default assets;
