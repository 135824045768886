import type { GameContext, Round } from "@/game/types/Game";

const updateRound = (
    game: Readonly<GameContext>,
    state: Partial<GameContext>,
    number: number,
    updater: (round: Readonly<Round>) => Readonly<Partial<Round>>
) =>
{
    const { round, rounds } = game;

    AssertIsset(round, "round is null");
    AssertTrue(round.number === number, `round number mismatch s:${round.number} e:${number}`);

    const updated: Round = {
        ...round,
        ...updater(round)
    };

    Object.assign(round, updated);

    state.round = updated;

    state.rounds = rounds.map((curr) =>
    {
        if (curr === round) return updated;

        return curr;
    });

    return updated;
};

export default updateRound;
