import { useGameContext } from "@/game/GameContext";
import type { Type } from "@/realize-shared/model/Event";
import PenIcon from "@/assets/img/chat/pen-icon.svg?react";
import { GameUIState } from "@/game/GameUIState";
import { EventState } from "@/game/events/ProcessedEventMap";
import type { GameNodeProps } from "../GameNodeProps";
import useTheme from "@/game/themes/useTheme";
import Container from "../components/Container";
import EditorIcon from "@/assets/img/editor-symbol.svg?react";
import TextContainer from "../components/TextContainer";
import { Sticky } from "../util/Sticky";

const RoundStart = ({
    editor: { name },
    round,
    state,
    eventRef,
}: GameNodeProps<Type.ROUND_START>) =>
{
    const {
        HeadlineSubmit,
        CallToAction,
        System: { Chip }
    } = useTheme();

    const { room } = useGameContext();


    const CreateHeadlineControls = () => (
        <div
            className={Sticky.wind(true, {
                display: "flex",
                width: "w-full",
                flexDirection: "flex-col",
            }).class}
            style={Sticky.style(
                true,
                HeadlineSubmit.Active.stickyMargin,
                CallToAction.Active.stickyMargin,
            )}
        >
            <TextContainer
                theme={HeadlineSubmit.Active}
                style={{ zIndex: "z-[1]" }}
                message={"Hey **Chief**! It’s your call"}
            />
            <Container
                {...CallToAction.Active.container}
                slices={CallToAction.Active.slices}
                outer={{
                    onClick: () => void room.data.write({gameUIState: GameUIState.HEADLINE}, true)
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        alignItems: "items-center",
                        justifyContent: "justify-center",
                        gap: "gap-2",
                        fontFamily: "font-default",
                    }).class,
                    style: CallToAction.Active.Text?.apply({}),
                }}
            >
                <PenIcon />
                Create a Headline!
            </Container>
        </div>
    );

    const WaitingForHeadline = () => (
        <div
            className={Sticky.wind(true).class}
            style={Sticky.style(
                true,
                HeadlineSubmit.Sticky.stickyMargin,
                HeadlineSubmit.Sticky.stickyMarginBottom
            )}
        >
            <TextContainer
                theme={HeadlineSubmit.Sticky}
                prefix={(
                    <EditorIcon
                        style={HeadlineSubmit.Sticky.Accent?.apply()}
                        className={Wind.style({
                            width: "w-[1.5em]",
                            height: "h-[1.5em]",
                        }).class}
                    />
                )}
                message={`**${name}** is finding the next hit Headline`}
            />
        </div>
    );

    return (
        <>
            <div
                className={Wind.style({
                    display: "flex",
                    justifyContent: "justify-center",
                }).class}
                ref={eventRef}
            >
                <Container
                    {...Chip.container}
                    slices={Chip.slices}
                    outer={{
                        className: Wind.style({
                            width: "w-min",
                        }).class,
                    }}
                    inner={{
                        className: Wind.style({
                            textWrap: "text-nowrap",
                        }).class,
                        style: Chip.Text?.apply()
                    }}
                >
                    {`Round ${round.number + 1}`}
                </Container>
            </div>
            { (state === EventState.ACTIVE) && <CreateHeadlineControls /> }
            { (state === EventState.STICKY) && <WaitingForHeadline />}
        </>
    );
};

export default RoundStart;
