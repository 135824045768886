import useTheme from "@/game/themes/useTheme";
import Formatted from "@/components/shared/Formatted";

const Headline = ({
    text,
    mode = "color",
    stamp = true,
}: {
    text: string,
    mode?: "light" | "dark" | "color",
    stamp?: boolean
}) =>
{
    const { Text, Accent, Initial, Stamp } = useTheme().Headline;

    const firstLetter = text.match(/^\p{L}/gu)? text.charAt(0): null;

    const headline = (firstLetter!==null)? text.substring(1): text;

    const colors = (mode !== "color"? {
        color: mode === "light"? "white": "black",
    }: {}) satisfies React.CSSProperties;

    return (
        <div
            className={Wind.style({
                textWrap: "text-balance",
                pointerEvents: "pointer-events-none",
            }).class}
        >
            {firstLetter !== null && (
                <div
                    className={Wind.style({
                        fontFamily: "font-default",
                        float: "float-left",
                        lineHeight: "leading-none",
                        position: "relative",
                    }).class}
                    style={Initial.apply({
                        ...colors,
                        transform: Initial.transform,
                    })}
                >
                    {(stamp && Stamp) && (
                        <div
                            className={Wind.style({
                                position: "absolute",
                                height: "h-full",
                                width: "w-full",
                                display: "flex",
                                justifyContent: "justify-center",
                                alignItems: "items-center",
                            }).class}
                        >
                            <img
                                src={Stamp.img}
                                style={{
                                    height: Stamp.height,
                                    width: Stamp.width,
                                    transform: Stamp.transform,
                                }}
                            />
                        </div>
                    )}
                    <h1
                        className={Wind.style({
                            position: "relative",
                            zIndex: "z-[1]",
                        }).class}
                    >
                        {firstLetter}
                    </h1>
                </div>
            )}
            <Formatted
                components={{
                    p: { style: Text.apply(colors), className: "font-default" },
                    strong: { style: Accent.apply(colors), className: "font-default" },
                }}
            >
                {headline}
            </Formatted>
        </div>
    );
};

export default Headline;
