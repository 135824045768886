import { useGameContext } from "@/game/GameContext";
import { Type } from "@/realize-shared/model/Event";
import { formatTime } from "@/util/Util";
import type { GameNodeProps } from "../GameNodeProps";
import Formatted from "@/components/shared/Formatted";
import useTheme from "@/game/themes/useTheme";
import Container from "../components/Container";
import type { ProcessedEvent } from "@/game/events/ProcessedEvent";
import ChatContextMenu from "../components/ChatContextMenu";
import React from "react";
import { Clipboard } from "@capacitor/clipboard";
import { twMerge } from "tailwind-merge";
import useLongPress from "@/game/useLongPress";

const ALWAYS_TIME = true as boolean;

const bubble = Wind.rotary({
    base: {
        maxWidth: "max-w-[65%]",
    },
    full: {
        flexGrow: "grow",
        maxWidth: "max-w-full",
    },
    auto: { }, // default
    part: {
        flexGrow: "grow",
        minWidth: "min-w-[35%]",
    },
    join: {
        // todo
    },
});

// eslint-disable-next-line complexity
const Chat = ({
    time,
    sender,
    message,
    eventRef,
    index,
    event,
    reportDialogEvent,
}: GameNodeProps<Type.CHAT> & {
    event: ProcessedEvent<Type>;
    reportDialogEvent: React.Dispatch<React.SetStateAction<ProcessedEvent<Type> | null>>;
}) =>
{
    const {
        self, events,
    } = useGameContext();

    const {
        Sent, Received,
    } = useTheme().Chat;

    const own = sender === self;

    // fixme: traverse instead of grabbing direct, as there are events that are not displayed
    const prev = events[index - 1] as ProcessedEvent<Type> | undefined;
    const next = events[index + 1] as ProcessedEvent<Type> | undefined;

    const first = prev?.type !== Type.CHAT || prev.sender !== sender;
    const last = next?.type !== Type.CHAT || next.sender !== sender;

    const {
        Layout: {
            Header, Single, width, Below, Above, Between,
        },
        Content: {
            Message, Name, Time, Title, Avatar,
        }
    } = own? Sent: Received;

    const Bubble = first
        ? (last? Single: Above)
        : (last? Below: Between);

    const LongPressListener = useLongPress(() =>
    {
        setShowContext(true);
    });

    const [showContext, setShowContext ] = React.useState(false);

    return (
        <div
            className={twMerge(Wind.style({
                display: "flex",
                flexDirection: own
                    ? ((Avatar?.display === "start")? "flex-row": "flex-row-reverse")
                    : ((Avatar?.display === "end")? "flex-row-reverse": "flex-row"),
                justifyContent: own
                    ? ((Avatar?.display === "start")? "justify-end": "justify-start")
                    : ((Avatar?.display === "end")? "justify-end": "justify-start")
            }).class, "context-menu")}
            ref={eventRef}

            {...LongPressListener}
        >
            {Avatar && (
                <div
                    className={Wind.style({
                        height: "h-0",
                        overflow: "overflow-visible",
                        visibility: first? undefined: "invisible",
                    }).class}
                >
                    <Container
                        {...Avatar.container}
                        slices={Avatar.slices}
                        outer={{
                            className: Wind.style({
                                width: "w-9",
                                height: "h-9",
                            }).class,
                        }}
                        inner={{
                            className: Wind.style({
                                overflow: "overflow-hidden",
                            }).class
                        }}
                    >
                        <img
                            src={sender.avatar.path}
                            alt={sender.avatar.alt}
                            className={Wind.style({
                                width: "w-full",
                                height: "h-full",
                            }).class}
                        />
                    </Container>
                </div>
            )}
            <Container
                {...Bubble.container}
                slices={Bubble.slices}
                outer={{
                    className: bubble.class(width)
                }}

            >
                { first && (
                    <Container
                        {...Header.container}
                        slices={Header.slices}
                        inner={{
                            className: Wind.style({
                                display: "flex",
                                justifyContent: "justify-between",
                                alignItems: "items-center",
                            }).class,
                        }}
                    >
                        {Name && (
                            <span
                                className={Wind.style({
                                    fontFamily: "font-default",
                                    textWrap: "text-nowrap",
                                    textOverflow: "text-ellipsis",
                                    width: "w-0",
                                    flexGrow: "grow",
                                    overflow: "overflow-hidden",
                                }).class}
                                style={Name.apply({})}
                            >
                                {sender.name}
                            </span>
                        )}
                        {Title && (
                            <span
                                className={Wind.style({
                                    fontSize: "text-sm",
                                    fontFamily: "font-default",
                                }).class}
                                style={Title.apply({})}
                            >
                                {Title.text}
                            </span>
                        )}
                    </Container>
                )}
                <Container
                    {...Message.container}
                    slices={Message.slices}
                    inner={{
                        className: Wind.style({
                            fontFamily: "font-default",
                            overflow: "overflow-hidden",
                            position: "relative",
                            wordBreak: "break-words",
                        }).class,
                        style: Message.Text?.apply({}),
                    }}
                >
                    <Formatted
                        components={{
                            code: {},
                            em: {},
                            pre: {},
                            strong: {},
                            del: {},
                            blockquote: {className: "before:content-['>_']"},
                            li: {className: "list-inside"},
                        }}
                    >
                        {message}
                    </Formatted>
                    {(Time && (ALWAYS_TIME || last)) && (
                        <div
                            className={Wind.style({
                                float: "float-right",
                                fontFamily: "font-default",
                                fontSize: "text-xs",
                                lineHeight: "leading-3",
                                paddingTop: "pt-3",
                                paddingLeft: "pl-2",
                            }).class}

                            style={Time.apply({})}
                        >
                            {formatTime(time)}
                        </div>
                    )}
                </Container>
            </Container>
            {showContext && (
                <ChatContextMenu
                    onCopy={() =>
                    {
                        Clipboard.write({
                            string: message,
                        }).catch(Logger.error);

                        setShowContext(false);
                    }}
                    onReport={() =>
                    {
                        reportDialogEvent(event);
                        setShowContext(false);
                    }}
                    close={() =>
                    {
                        setShowContext(false);
                    }}
                />
            )}
        </div>
    );
};

export default Chat;
