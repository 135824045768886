import { Device, type DeviceInfo, type LanguageTag } from "@capacitor/device";
import type { Version } from "@/realize-shared/model/Types";
import version from "@/data/version.out.json";
import * as Cache from "@/util/Cache";

const KEY = "development";

export const VERSION: Version = version;

export const { DEV } = import.meta.env;

export const { PROD } = import.meta.env;

export const ENV = (() =>
{
    const temp: Record<string, string> = {};
    const okay = (str: string) => str === "BASE_URL" || str.startsWith("VITE_");

    Object.entries(import.meta.env).forEach((field) =>
    {
        if (okay(field[0])) temp[field[0]] = String(field[1]);
    });

    return temp as Readonly<typeof temp>;
})();

type Info = Readonly<{
    "user-agent": string;
    "info": DeviceInfo;
    "platform": DeviceInfo["platform"];
    "id": string;
    "language": LanguageTag;
}>;

export let DEVICE: Info;

export let PLATFORM: Info["platform"];

export const init = async () =>
{
    const deviceInfo = await Device.getInfo();
    const deviceID = (await Device.getId()).identifier;
    const deviceLanguage = await Device.getLanguageTag();

    PLATFORM = deviceInfo.platform;

    DEVICE = {
        "user-agent": window.navigator.userAgent,
        "info":       deviceInfo,
        "platform":   deviceInfo.platform,
        "id":         deviceID,
        "language":   deviceLanguage,
    } as const;

    return deviceID;
};

class DebugClass
{
    public readonly DEV = DEV;

    public readonly PROD = PROD;

    public readonly ENV = ENV;

    public readonly VERSION = VERSION;

    public readonly DEVICE = DEVICE;

    static #instance: DebugClass | undefined;

    private constructor ()
    {
        //
    }

    public static get instance ()
    {
        if (!DebugClass.#instance) DebugClass.#instance = new DebugClass();

        return DebugClass.#instance;
    }

    private get ()
    {
        return Cache.get<string>(KEY);
    }

    private set (enabled = true)
    {
        if (enabled)
        {
            Cache.set(KEY, KEY);
        }
        else
        {
            this.clear();
        }
    }

    private clear ()
    {
        Cache.remove(KEY);
    }

    public toggle ()
    {
        this.enabled = !this.enabled;
    }

    public get enabled ()
    {
        return Boolean(this.get()) !== DEV;
    }

    public set enabled (enabled: boolean)
    {
        this.set(DEV !== enabled);
    }
}

export const Debug = DebugClass.instance;
