import WinnerList, { Container } from "@/components/game/WinnerList";
import type { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "../GameNodeProps";
import background from "@/assets/img/backgrounds/end-game-background.webp";
import GameOver from "@/assets/img/layout/game/game-over.webp";
import IconQuit from "@/assets/img/symbolic/quit.svg?react";
import React from "react";
import NotchedBlock from "@/components/NotchedBlock";
import JaggedButton from "@/components/JaggedButton";

const GameEnd = ({
    reason,
    eventRef,
}: GameNodeProps<Type.GAME_END>) =>
{
    const [ height, setHeight ] = React.useState(0);

    React.useEffect(() =>
    {
        const parent = eventRef.current?.parentElement;

        if (!parent) return;

        const obs = new ResizeObserver((entries) =>
        {
            if (entries.length <= 0) return;

            const { blockSize } = entries[entries.length - 1].borderBoxSize[0];

            setHeight(blockSize);
        });

        obs.observe(parent);

        return () => void obs.disconnect();
    }, [eventRef]);

    return (
        <div
            className="flex items-center justify-center flex-col -mb-4"
            style={{
                height: `${height}px`
            }}
            ref={eventRef}
        >
            { reason === "completed" && (
                <WinnerList />
            )}
            { reason !== "completed" && (
                <Container
                    className={Wind.style({
                        backgroundPosition: "bg-center",
                        backgroundSize: "bg-cover",
                        paddingX: "px-4",
                    }).class}
                    style={{
                        backgroundImage: `url(${background})`,
                    }}
                >
                    <NotchedBlock
                        className={Wind.style({
                            backgroundColor: "bg-[#AE1433]",
                            paddingX: "px-8",
                            paddingY: "py-8",
                            textAlign: "text-center",
                            marginBottom: "mb-8",
                        }).class}
                    >
                        <img
                            src={GameOver}
                            alt="Game Over"
                            className={Wind.style({
                                width: "w-auto",
                                height: "h-auto",
                                maxWidth: "max-w-full",
                                marginBottom: "mb-3",
                            }).class}
                        />
                        <span
                            className={Wind.style({
                                color: "text-white",
                            }).class}
                        >
                            {`Game has ended due to ${reason}`}
                        </span>
                    </NotchedBlock>
                    <JaggedButton
                        color="text-poppy-yellow"
                        onClick={() =>
                        {
                            const topNavBack = document.querySelector<HTMLButtonElement>(".backButton");

                            if (topNavBack) topNavBack.click();
                        }}
                        className={Wind.style({
                            width: "w-2/3",
                            filterDropShadow: "drop-shadow-md",
                        }).class}
                    >
                        <div
                            className={Wind.style({
                                display: "flex",
                                gap: "gap-2",
                                alignItems: "items-center",
                                color: "text-[#011936]",
                                fontWeight: "font-semibold",
                                fontFamily: "font-default",
                                fontSize: "text-base",
                                lineHeight: "leading-[83%]",
                            }).class}
                        >
                            <IconQuit />
                            <span>Leave Room</span>
                        </div>
                    </JaggedButton>
                </Container>
            )}
        </div>
    );
};

export default GameEnd;
