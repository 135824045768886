import GameSettingsSelection from "@/components/settings/GameSettingsSelection";
import IconClock from "@/assets/img/symbolic/clock.svg?react";
import CopyIcon from "@/assets/img/symbolic/copy.svg?react";
import ShareIcon from "@/assets/img/symbolic/share.svg?react";
import AudienceIcon from "@/assets/img/audience-icon.svg?react";
import * as Time from "@/realize-shared/model/Time";
import * as PlayerRequests from "@/api/PlayerRequests";
import { useGameContext } from "@/game/GameContext";
import { formatRoomCode } from "@/util/Util";
import * as Clipboard from "@/util/Clipboard";
import { PLATFORM } from "@/data/Environment";
import { Share } from "@capacitor/share";
import type { Rules } from "@/realize-shared/model/Types";
import useTheme from "@/game/themes/useTheme";

const GAME_TIME_VALUES: number[] = (() =>
{
    try
    {
        const times = (JSON.parse(import.meta.env.VITE_GAME_TIME_VALUES as string) as number[])
            .filter((n): n is number =>
            {
                AssertTrue(typeof n === "number");
                n = Math.round(n);
                AssertTrue(0 < n && n < Number.MAX_SAFE_INTEGER);

                return true;
            })
            .sort((a, b) => a - b);

        AssertTrue(times.length >= 0);

        return times;
    }
    catch (e)
    {
        Logger.error(e);
        Logger.error("Did you remember to set VITE_GAME_TIME_VALUES?");

        return [10];
    }
})();

const LowerLobbyControls = ({
    locked,
    hideRoomCode,
}: {
    locked: boolean,
    hideRoomCode?: boolean,
}) =>
{
    const {
        rules,
        room,
        host,
        self
    } = useGameContext();

    const {
        background,
        foreground,
        Settings: { Host, Player },
    } = useTheme().Footer;

    const isHost = host.id === self.id;

    const Settings = isHost? Host: Player;

    const formattedRoomCode = formatRoomCode(room.id);

    const getButtonText = (visibility: "public" | "private" | "redacted") =>
    {
        switch (visibility)
        {
            case "public":
                return "Public";
            case "private":
                return "Private";
            case "redacted":
                return "Photos Only";
        }
    };

    const nextState = (visibility: "public" | "private" | "redacted") =>
    {
        switch (visibility)
        {
            case "public":
                return "redacted";
            case "private":
                return "public";
            case "redacted":
                return "private";
        }
    };

    return (
        <div
            className={Wind.style({
                width: "w-full",
                fontFamily: "font-sans",
            }).class}

            style={{
                backgroundColor: background,
                color: foreground,
            }}
        >
            <div
                className={Wind.style({
                    display: "flex",
                    gap: "gap-3",
                    alignItems: "items-center",
                    paddingY: "py-2",
                    paddingLeft: "pl-3",
                    overflow: "overflow-y-auto",
                    whitespace: "whitespace-nowrap",
                }).class}
            >
                <span className={Wind.style({fontSize: "text-base"}).class}>
                    Game Settings
                </span>
                <GameSettingsSelection
                    locked={locked}
                    icon={<IconClock className="w-5 h-5" />}
                    values={GAME_TIME_VALUES}
                    value={rules.roundLength / Time.MS_IN_M}
                    title="Round Length"
                    prefix=""
                    suffix="Minutes"
                    style={Settings}
                    onSubmit={(value) =>
                    {
                        if (typeof value !== "undefined" && typeof value === "number")
                        {
                            const updated: Rules = {
                                ...rules,
                                "roundLength": value * Time.MS_IN_M,
                            };

                            PlayerRequests.sendRuleChanges(room.id, updated).catch(Logger.error);
                            room.data.write({rules: updated}, true);
                        }

                        document.body.classList.remove("pop-over");
                    }}
                />
                <button
                    className={Wind.style({
                        paddingX: "px-2",
                        paddingY: "py-1",
                        borderRadius: "rounded-lg",
                        fontWeight: "font-bold",
                        display: "flex",
                        alignItems: "items-center",
                        gap: "gap-2"
                    }).class}

                    style={{
                        backgroundColor: Settings.background,
                        color: Settings.foreground,
                    }}

                    onClick={() =>
                    {
                        if (host.id !== self.id) return;

                        const newVisibility = nextState(rules.visibility);

                        const updated: Rules = {
                            ...rules,
                            visibility: newVisibility,
                        };

                        PlayerRequests.sendRuleChanges(room.id, updated).catch(Logger.error);
                        room.data.write({rules: updated}, true);
                    }}
                >
                    <AudienceIcon className="w-5 h-5" />
                    <span>
                        {getButtonText(rules.visibility)}
                    </span>
                </button>
            </div>
            {!hideRoomCode && (
                <div
                    className={Wind.style({
                        paddingX: "px-3",
                        paddingY: "py-2",
                        justifyContent: "justify-between",
                        display: "flex",
                    }).class}
                >
                    <span className={Wind.style({fontSize: "text-base"}).class}>
                        {"Room Code"}
                    </span>
                    <div
                        className={Wind.style({
                            fontWeight: "font-bold",
                            display: "flex",
                            alignItems: "items-center",
                            gap: "gap-3",
                        }).class}
                        style={{ color: Settings.accent }}
                        onClick={() =>
                        {
                            if (PLATFORM === "web")
                            {
                                Clipboard.copy(formattedRoomCode).catch(Logger.warn);

                                return;
                            }

                            Share.canShare().then((canShare) =>
                            {
                                if (canShare.value)
                                {
                                    Share.share({
                                        title: "Join my Realize Room",
                                        text: `Join my Realize Room: ${formattedRoomCode}`,
                                    }).catch(Logger.error);
                                }
                                else
                                {
                                    Clipboard.copy(formattedRoomCode).catch(Logger.warn);
                                }
                            })
                                .catch(Logger.error);
                        }}
                    >
                        {formattedRoomCode}
                        {PLATFORM === "web" ? <CopyIcon className="w-5 h-5" /> : <ShareIcon className="w-5 h-5" />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LowerLobbyControls;
