export default function trackUsage <T extends object>(
    root: T,
    reporter: (report: {
        used: string[],
        unused: string[],
    }) => unknown,
    debounce: number,
): T
{
    const unused = (
        function traverse( self: object, stack: string[], keys: Set<string>, objects: Set<object>)
        {
            for (const [k, v] of Object.entries(self) as [string, unknown][])
            {
                const s = [...stack, k];

                keys.add(s.join("."));

                if (!v || typeof v !== "object" || Array.isArray(v) || objects.has(v)) continue;

                objects.add(v);

                traverse(v, s, keys, objects);
            }

            return keys;
        }
    )(root, [], new Set(), new Set());

    const proxies = new Map<object, object>();
    const used = new Set<string>();

    let handle = 0;

    const report = (name: string) =>
    {
        if (unused.delete(name)) used.add(name);

        const report = () => reporter({
            used: [...used.values()],
            unused: [...unused.values()],
        });

        if (debounce <= 0) return report();

        clearTimeout(handle);

        handle = setTimeout(report, debounce);

        return;
    };

    const proxy = <U extends object>(
        self: U,
        stack: string[],
    ): U => new Proxy(self, {
        get(target, name: string, receiver)
        {
            const value = Reflect.get(target, name, receiver) as unknown;

            if (!Reflect.has(target, name)) return value;

            const newStack = [...stack, name];
            const newName = newStack.join(".");

            report(newName);

            if (!value || typeof value !== "object" || Array.isArray(value)) return value;

            const stored = proxies.get(value);

            if (stored) return stored;

            const newProxy = proxy(value, newStack);

            proxies.set(value, newProxy);

            return newProxy;
        }
    });

    return proxy(root, []);
}
