import type { ComponentProps, PropsWithChildren } from "react";
import type { WindTypes } from "@/util/Tailwind";
import {
    sizes2string,
    sizes2strings,
    type Sizes,
} from "@/util/shorthand";
import { twMerge } from "tailwind-merge";
import Box from "./Box";
import type { Remove } from "@/realize-shared/Types";

export type Sizing = "auto" | "stretch" | "cover" | "contain";

export type Repeat = "none" | "horizontal" | "vertical" | "both";

export type Anchor =
    | "center" | "left" | "right"
    | "top" | "top-left" | "top-right"
    | "bottom" | "bottom-left" | "bottom-right";

const sizingMap: Record<Sizing, WindTypes["backgroundSize"]> = {
    "auto": "bg-auto",
    "stretch": "bg-[length:100%_100%]",
    "cover": "bg-cover",
    "contain": "bg-contain",
};

const repeatMap: Record<Repeat, WindTypes["backgroundRepeat"]> = {
    "none": "bg-no-repeat",
    "horizontal": "bg-repeat-x",
    "vertical": "bg-repeat-y",
    "both": "bg-repeat",
};

const anchorMap: Record<Anchor, WindTypes["backgroundPosition"]> = {
    "center": "bg-center",
    "top": "bg-top",
    "top-left": "bg-left-top",
    "top-right": "bg-right-top",
    "bottom": "bg-bottom",
    "bottom-left": "bg-left-bottom",
    "bottom-right": "bg-right-bottom",
    "left": "bg-left",
    "right": "bg-right-bottom",
};

export type Slice = string | {
    img: string,
    size?: Sizing,
    anchor?: Anchor,
    repeat?: Repeat,
};

export interface Slices
{
    sizes: Sizes,
    insets: Sizes,
    images: [
        t: [l: Slice, c: Slice, r: Slice],
        c: [l: Slice, c: Slice, r: Slice],
        b: [l: Slice, c: Slice, r: Slice],
    ],
    layer: 0 | 1,
    filter: string,
    transform: string,
}

export interface ContainerProps
{
    slices: Slices[],
    margin: Sizes,
    padding: Sizes,
    background: string,
    border: string,
    radius: Sizes,
    shadow: string,
    filter: string,
    transform: string,
    inner?: Remove<ComponentProps<typeof Box>, "children">,
    outer?: Remove<ComponentProps<typeof Box>, "children">,
}

const Sliced = ({
    sizes,
    images,
    insets,
    layer,
    filter,
    transform,
}: Slices) =>
{
    const [st, sr, sb, sl] = sizes2strings(sizes);

    return (
        <div
            className={Wind.style({
                position: "absolute",
                display: "grid",
                pointerEvents: "pointer-events-none",
                zIndex: layer? "z-[1]": undefined,
            }).class}
            style={{
                gridTemplateColumns: `[l] ${sl} [c] auto [r] ${sr}`,
                gridTemplateRows: `[t] ${st} [c] auto [b] ${sb}`,
                inset: sizes2string(insets) || undefined,
                filter: filter? filter: undefined,
                transform: transform? transform: undefined,
            }}
        >
            {images.flatMap(s => s.flat())
                .map(img => (typeof img === "string")? {img}: img)
                .map((slice, i) => ({key: `slice-i${i}`, visible: !!slice.img, ...slice}))
                .map(({key, img, anchor, repeat, size, visible}) => (
                    <div
                        key={key}
                        className={!visible? "invisible": Wind.style({
                            backgroundPosition: anchorMap[anchor ?? "center"],
                            backgroundRepeat: repeatMap[repeat ?? "none"],
                            backgroundSize: sizingMap[size ?? "stretch"],
                        }).class}
                        style={{
                            backgroundImage: visible? `url("${img}")`: undefined,
                        }}
                    />
                ))}
        </div>
    );
};

const Container = ({
    children,
    slices,
    padding,
    margin,
    background,
    filter,
    transform,
    border,
    shadow,
    radius,
    outer: { style: outerStyle, className: outerClassName, ...outerProps } = {},
    inner: { style: innerStyle, className: innerClassName, ...innerProps } = {},
}: PropsWithChildren<ContainerProps>) => (
    <Box
        className={twMerge(Wind.style({
            position: "relative",
            // transformGPU: "transform-gpu",
        }).class, outerClassName)}
        style={{
            ...outerStyle,
            margin: sizes2string(margin) || undefined,
            filter: filter || undefined,
            transform: transform || undefined,
        }}
        {...outerProps}
    >
        {slices.map((props, i) => (
            <Sliced
                {...props}
                key={i}
            />
        ))}
        <Box
            className={twMerge(Wind.style({
                pointerEvents: ("onClick" in outerProps)
                    ? "pointer-events-none"
                    : undefined,
                position: "relative",
                width: "w-full",
                overflow: "overflow-hidden",
            }).class, innerClassName)}
            style={{
                ...innerStyle,
                padding: sizes2string(padding) || undefined,
                backgroundColor: background || undefined,
                border: border || undefined,
                borderRadius: sizes2string(radius) || undefined,
                boxShadow: shadow || undefined,
            }}
            {...innerProps}
        >
            {children}
        </Box>
    </Box>
);

export default Container;
