import { useGameContext } from "@/game/GameContext";
import GameContent from "@/pages/chat/GameContent";
import SideBar from "./components/Sidebar";
import React from "react";
import Phase from "@/realize-shared/model/Phase";

import ChatBackground from "@/web/img/chat-background.webp";
import CentralBackground from "./components/CentralBackground";
import BottomTexture from "@/web/img/bottom-panel-texture.webp";
import Mascot from "@/assets/img/jobo-beta.webp";

import RoundCentral from "./components/RoundCentral";
import PlayerSubmissionClock from "./components/PlayerSubmissionClock";
import { formatRoomCode } from "@/util/Util";
import CurrentEditor from "./components/CurrentEditor";
import GameBeingSetup from "./components/GameBeingSetup";
import VotingView from "./components/VotingView";
import { Type } from "@/realize-shared/model/Event";
import RoundWinnerWebView from "./components/RoundWinnerWebView";
import GameWinnerWebView from "./components/GameWinnerWebView";
import type { ProcessedEvent } from "@/game/events/ProcessedEvent";
import ThemeContext from "@/game/themes/ThemeContext";
import { Themes } from "@/game/themes/Themes";

const enum VirtualPages
{
    ROUND_WINNER=1,
    GAME_WINNER=2,
}

const VIRTUAL_PAGE_DELAY = 5000;

// Need a lobby phase, what's it look like?
const GameViewer = () =>
{
    const [pageSwitch, setPageSwitch] = React.useState<VirtualPages|null>(null);

    const {
        room,
        round,
        events,
        alerts,
        gamePhase,
        rendered,
    } = useGameContext();

    const gameWinner = events.find(e => e.type === Type.GAME_END) as
        ProcessedEvent<Type.GAME_END> & { reason: string } | undefined;


    React.useEffect(() =>
    {
        let index = rendered;

        while (index < events.length)
        {
            const event = events[index];

            index++;

            switch (event.type)
            {
                case Type.VOTE_RESULT:
                    setPageSwitch(VirtualPages.ROUND_WINNER);

                    break;
                case Type.GAME_END:
                    setPageSwitch(VirtualPages.GAME_WINNER);

                    break;
                default:
                    break;
            }
        }

        room.data.write({rendered: index}, true);
    }, [alerts, events, rendered, room]);

    const getCentralPage = () =>
    {
        const gameWinner = events.find(e => e.type === Type.GAME_END) as
        ProcessedEvent<Type.GAME_END> & { reason: string } | undefined;

        Logger.log("Game Winner", gameWinner);

        if (pageSwitch)
        {
            setTimeout(() =>
            {
                setPageSwitch(null);
            }, VIRTUAL_PAGE_DELAY);

            switch (pageSwitch)
            {
                case VirtualPages.ROUND_WINNER:
                    return <RoundWinnerWebView />;
                case VirtualPages.GAME_WINNER:
                    return <GameWinnerWebView />;
            }
        }

        if (gamePhase === Phase.CLOSED && gameWinner && gameWinner.reason === "completed")
        {
            return <GameWinnerWebView />;
        }

        switch (gamePhase)
        {
            case Phase.LOBBY:
                return <GameBeingSetup />;
            case Phase.HEADLINE_CREATION:
                return round && <GameBeingSetup round={round} />;
            case Phase.PHOTO_SUBMISSION:
                return round && <RoundCentral round={round} />;
            case Phase.VOTING:
                return round && <VotingView round={round} />;
            case Phase.CLOSED:
                return <div>Closed</div>;
        }
    };

    const theme = Themes.themes[0];

    Logger.log("Theme", theme.data);

    return (
        <ThemeContext.Provider value={theme.data}>
            <div
                className={Wind.style({
                    height: "h-full",
                    width: "w-full",
                    padding: "p-4",
                    backgroundColor: "bg-[#000D1D]",
                    display: "flex",
                    flexDirection: "flex-col",
                    gap: "gap-4",
                    backgroundPosition: "bg-bottom",
                    backgroundRepeat: "bg-no-repeat",
                }).class}

                style={{
                    backgroundImage: `url(${BottomTexture})`,
                }}
            >
                <div
                    className={Wind.style({
                        display: "flex",
                        flexGrow: "grow",
                        maxHeight: "max-h-[calc(100vh-8rem)]",
                        gap: "gap-4"
                    }).class}
                >
                    <div
                        className={Wind.style({
                            width: "w-[25rem]",
                            flex: "flex-[0_0_25rem]",
                            backgroundPosition: "bg-center",
                            backgroundSize: "bg-cover",
                            borderRadius: "rounded-lg",
                            display: "flex",
                            flexDirection: "flex-col",
                            overflow: "overflow-clip",
                        }).class}

                        style={{
                            backgroundImage: `url(${ChatBackground})`,
                        }}
                    >
                        <GameContent
                            className={`${Wind.style({
                                position: "relative",
                                height: "h-full",
                            }).class} clean-scrollbar`}
                            hideRoomCode
                        />
                    </div>

                    <div
                        className={Wind.style({
                            flex: "flex-[1_1_auto]",
                            padding: "p-4",
                            display: "flex",
                            flexDirection: "flex-col",
                            justifyContent: "justify-center",
                            alignItems: "items-center",
                            borderRadius: "rounded-lg",
                            overflow: "overflow-hidden",
                            position: "relative",
                            backgroundColor: gameWinner ? "bg-sharp-red" : "bg-[#011936]"
                        }).class}
                    >
                        <CentralBackground />
                        {getCentralPage()}
                    </div>
                    <SideBar />
                </div>
                <div
                    className={Wind.style({
                        color: "text-white",
                        height: "h-16",
                        display: "flex",
                        justifyContent: "justify-between",
                        alignItems: "items-center",
                    }).class}

                    style={{
                        backgroundImage: `url(${BottomTexture})`,
                    }}
                >
                    <div
                        className={Wind.style({
                            flex: "flex-[0_0_24rem]",
                        }).class}
                    >
                        {round && (<CurrentEditor editor={round.editor} />)}
                    </div>
                    <div>
                        {gamePhase !== Phase.LOBBY && (<PlayerSubmissionClock />)}
                    </div>
                    <div
                        className={Wind.style({
                            display: "flex",
                            alignItems: "items-baseline",
                            flex: "flex-[0_0_24rem]",
                            justifyContent: "justify-end",
                            height: "h-full",
                        }).class}
                    >
                        <div
                            className={Wind.style({
                                display: "flex",
                                alignItems: "items-end",
                                height: "h-full",
                                gap: "gap-2",
                            }).class}
                        >
                            <span
                                className={Wind.style({
                                    color: "text-parchment",
                                    fontFamily: "font-default",
                                    fontWeight: "font-normal",
                                    fontSize: "text-base",
                                    lineHeight: "leading-[150%]",
                                    paddingBottom: "pb-1"
                                }).class}
                            >
                                Room Code
                            </span>
                            <span
                                className={Wind.style({
                                    color: "text-parchment",
                                    fontFamily: "font-default",
                                    fontWeight: "font-extrabold",
                                    fontSize: "text-2xl",
                                    lineHeight: "leading-[150%]",
                                }).class}
                            >
                                {formatRoomCode(room.id)}
                            </span>
                        </div>
                        <div
                            className={Wind.style({
                                position: "relative",
                                width: "w-[6.438rem]",
                                height: "h-auto",
                                marginLeft: "-ml-7"
                            }).class}
                        >
                            <img
                                src={
                                    Mascot
                                }
                                className={Wind.style({
                                    position: "absolute",
                                    width: "w-[6.438rem]",
                                    height: "h-auto",
                                    bottom: "bottom-0",
                                    transformTranslateY: "translate-y-1/4",
                                }).class}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ThemeContext.Provider>
    );
};

export default GameViewer;
