import * as Time from "@/realize-shared/model/Time";
import * as DataRequests from "@/api/DataRequests";
import * as Types from "@/realize-shared/Types";
import * as Cache from "@/util/Cache";
import { Debug } from "@/data/Environment";

export const isKey = (key: string) =>
{
    const a = key.startsWith("%");
    const b = key.endsWith("%");

    if (a !== b) return;

    return a;
};

export const splitKey = (key: string) => [
    ...new Set(key.substring(1, key.length - 1).split("|")),
].sort();

export class Dataset
{
    public static instance?: Dataset;

    private loading = false;

    private loaded = false;

    #classes: string[] | undefined;

    #templates: string[][] | undefined;

    #words: Record<string, string[]> | undefined;

    public MAX_OPTS = Infinity;

    private constructor ()
    {
        // nothing
    }

    private process (raw: Record<string, unknown>)
    {
        if (this.#templates) return;

        const fail = () =>
        {
            this.clearCache();

            return new Error("Processing invalid dataset");
        };

        const PHRASE_KEY = "phrase";

        const classes = (() =>
        {
            const temp = new Set(Object.keys(raw));

            if (!temp.has(PHRASE_KEY)) throw fail();

            temp.delete(PHRASE_KEY);

            return temp;
        })();

        const templates = (() =>
        {
            try
            {
                return Types.asArray(raw.phrase).map(Types.asStringArray);
            }
            catch
            {
                throw fail();
            }
        })();

        templates
            .flatMap((template) => template)
            .filter((template) =>
            {
                const key = isKey(template);

                if (typeof key === "undefined") throw fail();

                return key;
            })
            .flatMap(splitKey)
            .forEach((template) =>
            {
                if (!classes.has(template)) throw fail();
            });

        const words = (() =>
        {
            const tempWords = new Map<string, string[]>();

            try
            {
                for (const [
                    key, value,
                ] of Object.entries(raw))
                {
                    if (key === PHRASE_KEY) continue;

                    tempWords.set(
                        key,
                        Types.asStringArray(value).map((str) => `**${str}**`),
                    );
                }
            }
            catch
            {
                throw fail();
            }

            return Object.fromEntries(tempWords);
        })();

        this.#classes = [
            ...classes,
        ];

        this.#templates = templates;
        this.#words = words;

        for (const list of Object.values(this.#words))
        {
            Object.freeze(list);
        }

        Object.freeze(this.#words);

        for (const template of this.#templates)
        {
            Object.freeze(template);
        }

        Object.freeze(this.#templates);

        Object.freeze(this.#classes);
    }

    private getCache ()
    {
        return Cache.get<Record<string, unknown>>("autocomplete-dataset");
    }

    private setCache (dataset: Record<string, unknown>)
    {
        const hours = 6;

        Cache.set("autocomplete-dataset", dataset, Time.S_IN_H * hours);
    }

    private clearCache ()
    {
        Cache.remove("autocomplete-dataset");
    }

    private async retrieve ()
    {
        const cached = (Debug.enabled)? false: this.getCache();

        if (cached) return cached;

        const data = await DataRequests.dataset();

        this.setCache(data);

        return data;
    }

    private async load ()
    {
        if (this.loaded) return;

        if (this.loading)
        {
            await new Promise((resolve) =>
            {
                const check = () =>
                {
                    if (this.loaded)
                    {
                        resolve(true);
                    }
                    else
                    {
                        setTimeout(check, Time.MS_IN_S / 2);
                    }
                };

                check();
            });
        }
        else
        {
            this.loading = true;
            this.process(await this.retrieve());
            this.loaded = true;
        }
    }

    public static async getInstance ()
    {
        if (!this.instance)
        {
            this.instance = new Dataset();

            await this.instance.load();
        }

        return this.instance;
    }

    public get (key: string)
    {
        return this.#words?.[key] ?? [];
    }

    public get classes ()
    {
        return this.#classes ?? [];
    }

    public get templates (): readonly string[][]
    {
        return this.#templates ?? [];
    }
}
