import {
    type PluginListenerHandle, registerPlugin,
} from "@capacitor/core";
import Logger from "@/util/Logger";

export enum PushyEvent
{
    OnNotificationReceived = "OnNotificationReceived",
    OnRegistered = "OnRegistered",
}

export interface DeviceInfo
{
    deviceToken: string;
}

export type PushyError =
{
    errorMessage: string
};

export type NotificationCallback = () => void;

export type RegistrationCallback = (info: DeviceInfo) => void;

export interface TopicInfo
{
    roomID: string;
}

export interface PushyPlugin
{

    /**
     * subscribes client to any notifications sent to this Topic
     */
    subscribeToTopic: (options: TopicInfo) => Promise<void>;
    unsubscribeToTopic: (options: TopicInfo) => Promise<void>;
    initialize: () => Promise<DeviceInfo>;

    /**
     * NOTE: no callbacks will be recieved if Initialize is never called
     *
     * Return: Handle that can be used to later unsubscribe from event
     */
    addListener: ((
        eventName: PushyEvent.OnNotificationReceived,
        Func: NotificationCallback
    ) => Promise<PluginListenerHandle>) & ((
        eventName: PushyEvent.OnRegistered,
        Func: RegistrationCallback
    ) => Promise<PluginListenerHandle>);
}

const pushy = registerPlugin<PushyPlugin>("PushyPlugin", { "web": async () => import("./PushyWeb")
    .then((webimpl) => new webimpl.PushyWeb())
    .catch((err) =>
    {
        Logger.error(err);
    }) });

export { pushy };
