import { useState, useEffect } from "react";

const DEFAULT_DELAY = 300;

export default function useLongPress(callback = (_event: MouseEvent|TouchEvent|undefined) =>
{
    // DO Nothing
}, ms = DEFAULT_DELAY)
{
    const [startLongPress, setStartLongPress] = useState(false);

    useEffect(() =>
    {
        let timerId: number|undefined;

        if (startLongPress)
        {
            timerId = setTimeout(callback, ms);
        }
        else
        {
            if (!timerId) return;
            clearTimeout(timerId);
        }

        return () =>
        {
            clearTimeout(timerId);
        };
    }, [callback, ms, startLongPress]);

    return {
        onMouseDown: () => void setStartLongPress(true),
        onMouseUp: () => void setStartLongPress(false),
        onMouseLeave: () => void setStartLongPress(false),
        onTouchStart: () => void setStartLongPress(true),
        onTouchEnd: () => void setStartLongPress(false),
    };
}
