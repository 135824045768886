import type { Player } from "@/game/types/Game";
import WinnerBanner from "@/assets/img/layout/game/winner.png";
import IconCrownNew from "@/assets/img/crown.svg?react";
import PlayerCardBG from "@/assets/img/layout/game/position-card-bg.svg?react";
import WinnerCardBG from "@/assets/img/layout/game/winner-card-bg.svg?react";
import Ordinal from "@/util/Ordinal";
import { twMerge } from "tailwind-merge";
import { useGameContext } from "@/game/GameContext";

const FinalPositionCard = ({
    position,
}: {
    position: number;
}) => (
    <div
        className={`
            absolute
            top-0
            left-0
            w-11
            text-center
            -translate-y-2
            -translate-x-6
            bg-mint-green
            drop-shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]
            -rotate-2
            font-bold
            py-2
            px-1
            text-dark-blue
        `}
    >
        <span
            className={`
                text-2xl
            `}
        >
            {position}
        </span>
        <span
            className={`
                align-super
                text-base
            `}
        >
            {Ordinal.from(position)}
        </span>
    </div>
);


const PlayerPositionCard = ({
    player,
    position,
    isWinner,
    animateIn,
    className,
}: {
    isWinner?: boolean;
    player: Player;
    position: number;
    className?: string;
    animateIn?: boolean;
}) => (
    <div
        className={twMerge(
            `
                relative
                aspect-[366/85]
                ml-8
                mr-4
                w-[calc(100vw_-_3rem)]
                max-w-[366px]
                h-auto
                bg-no-repeat
                bg-cover
                bg-center
                flex
                items-center
                justify-between
                px-6
                gap-3
                drop-shadow-[2px_2px_8px_rgba(0,0,0,0.60)]
                ${isWinner? "text-dark-blue": "text-white"}
                ${animateIn? "animate__animated animate__rubberBand animate_slideDownIn": ""}
            `,
            className,
        )}
    >
        {isWinner ? (
            <WinnerCardBG className="absolute inset-0 w-full -translate-y-2" />
        ) : (
            <PlayerCardBG className="absolute inset-0 w-full -translate-y-2" />
        )}
        <div
            className={`
                rounded
                w-12
                h-12
                flex-shrink-0
                bg-cover
                bg-center
                relative
            `}
            style={{
                "backgroundImage": `url(${player.avatar.path})`,
            }}
        />
        <div
            className={`
                flex-grow
                font-default
                font-medium
                text-2xl
                relative
            `}
        >
            {player.name}
        </div>
        <div
            className={`
                font-default
                font-medium
                leading-[83%]
                text-base
            `}
        >
            <span
                className={`
                    font-bold
                    text-2xl
                `}
            >
                {player.score}
            </span>
            pts
        </div>
        <FinalPositionCard position={position} />
        {isWinner && (
            <IconCrownNew
                className={`
                    absolute
                    top-0
                    right-0
                    translate-x-4
                    -translate-y-8
                `}
            />
        )}
    </div>
);

export const Container = ({
    children,
    className,
    style,
}: {
    className?: string,
    style?: React.CSSProperties,
} & React.PropsWithChildren) => (
    <div
        className={twMerge("flex bg-sharp-red h-full w-full items-center justify-center flex-col", className)}
        style={style}
    >
        {children}
    </div>
);

export default function WinnerList()
{
    const { scoreboard } = useGameContext();

    return (
        <Container>
            <img
                src={WinnerBanner}
                alt="Winner"
                className="mb-4"
            />
            {scoreboard.map(({player}, index) => (
                <PlayerPositionCard
                    key={player.id}
                    isWinner={index===0}
                    player={player}
                    position={index + 1}
                    className={index===0? "mb-12": ""}
                    animateIn={index===0}
                />
            ))}
        </Container>
    );
}
