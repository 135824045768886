/* eslint-disable require-atomic-updates */
/* eslint-disable @typescript-eslint/no-magic-numbers */
export interface MagazineOptions
{
    issueNumber: number;
    timestamp: number;
    username: string;
    headline?: string;
    logo?: string|null;
    barCode?: string|null;
    photoPath: string;
    isWinner?: boolean;
}

/**
 * Loads an image from a url
 * @param {string} src The image url
 * @returns {Promise<ImageBitmap|null>}
 */
const loadImage = async (src: string) =>
{
    // Load the image with fetch
    const img = await fetch(src);
    const fileBlob = await img.blob();

    if (
        fileBlob.type === "image/jpeg"
        || fileBlob.type === "image/png"
        || fileBlob.type === "image/webp"
    )
    {
        return await createImageBitmap(fileBlob);
    }

    return null;
};

const leftPadNumber = (num: number, length = 2) =>
{
    return num.toString().padStart(length, "0");
};

const formatIssueDate = (date: Date) =>
{
    // Return format is MMM DD, HH:MM:AM
    const month = date.toLocaleString("default", { "month": "short" });
    const day = leftPadNumber(date.getDate());

    let hours = date.getHours();

    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12 hour time
    if (hours > 12) hours -= 12;
    else if (hours === 0) hours = 12;


    const time = `${hours}:${leftPadNumber(minutes)}${ampm}`;

    return `${month} ${day}, ${time}`;
};

const drawShadow = (
    ctx: CanvasRenderingContext2D,
    text: string,
    x = 0,
    y = 0,
    color = "#000",
) =>
{
    ctx.fillStyle = "rgba(0,0,0,0.8)";
    ctx.shadowBlur = 4;
    ctx.shadowColor = "rgba(0,0,0,0.9)";
    ctx.fillText(text, x + 1, y + 1);
    ctx.shadowBlur = 0;
    ctx.fillStyle = color;
    ctx.fillText(text, x, y);
};

const drawPhotoCropped = (
    ctx: CanvasRenderingContext2D,
    img: ImageBitmap,
    x = 0,
    y = 0,
    width = 100,
    height = 100,
) =>
{
    ctx.save();
    ctx.rect(x, y, width, height);
    ctx.clip();


    // Draw Image center to the container
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = imgWidth / imgHeight;
    const containerRatio = width / height;

    let drawWidth = width;
    let drawHeight = height;
    let drawX = x;
    let drawY = y;

    // Draw the image covering the container
    if (imgRatio > containerRatio)
    {
        // Image is wider than container
        drawWidth = height * imgRatio;
        drawX = x - ((drawWidth - width) / 2);
    }
    else
    {
        // Image is taller than container
        drawHeight = width / imgRatio;
        drawY = y - ((drawHeight - height) / 2);
    }

    ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);
    ctx.restore();
};

// @TODO reduce complexity
// eslint-disable-next-line complexity
const magazineTemplate = async (
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D,
    options?: MagazineOptions,
): Promise<string|null> =>
{
    ctx.clearRect(0, 0, width, height);

    // Draw the paper-like backing
    ctx.fillStyle = "#9B9A99";
    ctx.fillRect(2, 2, width - 2, height - 2);
    ctx.fillStyle = "#CECDCA";
    ctx.fillRect(1, 1, width - 2, height - 2);
    ctx.fillStyle = "#F9F5ED";
    ctx.fillRect(0, 0, width - 2, height - 2);


    // Draw Left Dark Blue Bar
    ctx.fillStyle = "#011936";
    ctx.fillRect(0, 0, 2, height - 2);

    // Draw Head Lines (Top 3 Banners)
    ctx.fillStyle = "#011936";
    ctx.font = "400 9px Poppins";

    // Issue Number
    const issueNumber = options?.issueNumber ?? 1;
    const issueTxt = `Issue ${leftPadNumber(issueNumber)}`;

    ctx.fillText(
        issueTxt,
        16,
        8 + (ctx.measureText(issueTxt).actualBoundingBoxAscent * 1.2),
    );

    ctx.fillStyle = "#011936";

    // JumpButton Studio
    const JBS = "JumpButton Studio".toUpperCase();

    ctx.fillText(
        JBS,
        (width / 2) - (ctx.measureText(JBS).width / 2),
        8 + (ctx.measureText(JBS).actualBoundingBoxAscent * 1.2),
    );

    // Issue Date Stamp
    const date = new Date(options?.timestamp ?? Date.now());
    const dateTxt = formatIssueDate(date);

    ctx.fillText(
        dateTxt,
        width - 18 - ctx.measureText(dateTxt).width,
        8 + (ctx.measureText(dateTxt).actualBoundingBoxAscent * 1.2),
    );

    // Draw the Realize logo from the svg file

    if (options?.logo)
    {
        const logo = await loadImage(options.logo);

        if (logo)
        {
            ctx.drawImage(logo, (width / 2) - (logo.width / 2), 24);
        }
    }

    // Draw the sharp-red square
    ctx.fillStyle = "#ED254E";
    ctx.fillRect(92, 100, 292, 298);

    // Draw the mint green square
    ctx.fillStyle = "#C2EABD";
    ctx.fillRect(12, 396, 166, 144);

    // Draw the poppy-yellow square
    ctx.fillStyle = "#F9DC5C";
    ctx.fillRect(8, 450, 113, 94);


    // Draw Magazine Photo
    const photo = await loadImage(options?.photoPath ?? "https://picsum.photos/1080/1920");


    // ctx.drawImage(photo, 16, 110, 358, 426);
    if (photo)
    {
        drawPhotoCropped(ctx, photo, 16, 110, 358, 426);
    }

    // Draw The Photographer Name
    ctx.font = "900 27px Poppins";

    const txtUsername = options?.username.toLocaleUpperCase() ?? "";

    // Draw Photographer Name Shadow
    const nameHeight = (ctx.measureText(txtUsername).actualBoundingBoxAscent * 1.2);

    drawShadow(ctx, txtUsername, 32, 410 + nameHeight, "#F9DC5C");

    // Draw the Headline
    const headline = (options?.headline
     ?? "Realize something you would never want your self-expression to know about").trim();

    ctx.font = "600 16px Poppins";

    // Split the headline into multiple lines with the max width of 193px
    const words = headline.split(" ");
    const lines = [];

    let line = "";

    for (const word of words)
    {
        const nextLine = `${line} ${word}`.trim();
        const nextLineWidth = ctx.measureText(nextLine).width;

        if (nextLineWidth > 193)
        {
            lines.push(line);
            line = word;
        }
        else
        {
            line = nextLine;
        }
    }

    lines.push(line);

    // Draw all the lines
    for (let i=0; i<lines.length; i++)
    {
        const currentLine = lines[i];

        drawShadow(ctx, currentLine, 32, 415 + nameHeight + ((16 * 1.2) * (i + 1)), "#FFF");
    }

    // Draw white stroked square

    // Clip this square to the first square 0, 0, width - 2, height - 2
    ctx.save();
    ctx.rect(0, 0, width - 2, height - 2);
    ctx.clip();
    ctx.strokeStyle = "#FFF";
    ctx.lineWidth = 2;
    ctx.strokeRect(24, 118, 403, 410);
    ctx.restore();

    if (options?.isWinner)
    {
    // Draw the winner badge
        ctx.fillStyle = "#ED254E";
        ctx.beginPath();

        const winnerBadge = new Path2D();

        winnerBadge.addPath(
            new Path2D(
                "M-28 5.81081L97.0625 0L110 6.97297V37.1892L-15.0625 43L-28 36.027V5.81081Z",
            ),
            new DOMMatrix([1, 0, 0, 1, 0, 362]),
        );

        ctx.fill(winnerBadge);

        ctx.font = "bold 16px Poppins";
        ctx.fillStyle = "#FFF";

        ctx.fillText(
            "Winner",
            31,
            374 + (ctx.measureText("Winner").actualBoundingBoxAscent * 1.2),
        );
    }

    // Draw Bar Code
    ctx.fillRect(342, 472, 40, 72);


    if (options?.barCode)
    {
        const barCode = await loadImage(options.barCode);

        if (barCode)
        {
            ctx.drawImage(barCode, 347, 478, 32, 56);
        }
    }

    // Return the canvas as a base64 encoded image
    return ctx.canvas.toDataURL("image/jpeg", 0.85);
};

/**
 * Draws a magazine cover with the given options
 * @param {MagazineOptions} magazine The magazine options
 * @returns {Promise<Blob|null>} The base64 encoded image or null if the canvas is not supported
 */
export const drawMagazine = async (magazine: MagazineOptions) =>
{
    const canvas = document.createElement("canvas");

    canvas.width = 390;
    canvas.height = 552;

    const ctx = canvas.getContext("2d");

    if (!ctx) throw new Error("Canvas context not acquired");

    return magazineTemplate(canvas.width, canvas.height, ctx, magazine);
};
