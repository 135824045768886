import CheckIcon from "@/assets/img/symbolic/thin-check.svg?react";
import { MINIMUM_PLAYERS_FOR_GAME } from "@/data/Config";
import * as PlayerRequests from "@/api/PlayerRequests";
import { useGameContext } from "@/game/GameContext";
import React from "react";
import type { Type } from "@/realize-shared/model/Event";
import { EventState } from "@/game/events/ProcessedEventMap";
import type { GameNodeProps } from "../GameNodeProps";
import useTheme from "@/game/themes/useTheme";
import Container from "../components/Container";
import StyledText from "../components/StyledText";
import { noop } from "@/util/Util";
import { Sticky } from "../util/Sticky";

const GameStart = ({
    state,
    eventRef,
}: GameNodeProps<Type.GAME_START>) =>
{
    const {
        currentPlayers,
        room,
        host,
    } = useGameContext();

    const {
        GameStart,
        GameWaiting,
    } = useTheme().CallToAction;

    const [isGameStarted, setIsGameStarted] = React.useState(false);

    if (state === EventState.STATIC) return null;

    const startGame = () =>
    {
        Logger.log("Start Game");
        setIsGameStarted(true);

        PlayerRequests.sendStartGameRequest(room.id)
            .then((data) => void Logger.log("Game Started", data))
            .catch((error) =>
            {
                Logger.error("Failed to start game", error);
                setIsGameStarted(false);
            });
    };

    const mode = (state === EventState.ACTIVE)
        ? ((currentPlayers.size < MINIMUM_PLAYERS_FOR_GAME || isGameStarted)
            ? "host-waiting"
            : "host-ready")
        : "player";

    const {
        container,
        slices,
        Text,
        Accent
    } = (mode === "player" || mode === "host-waiting")? GameWaiting: GameStart;

    return (
        <div
            className={Sticky.wind(true, {
                display: "flex",
                top: "top-0",
                width: "w-full",
                flexDirection: "flex-col",
            }).class}
            ref={eventRef}
        >
            <Container
                {...{...container, slices}}
                outer={{
                    disabled: mode !== "host-ready",
                    onClick: mode === "host-ready"? startGame: noop,
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        alignItems: "items-center",
                        justifyContent: "justify-center",
                        gap: "gap-2",
                        fontFamily: "font-default",
                    }).class,
                    style: Text?.apply({}),
                }}
            >
                { mode !== "player" && <CheckIcon className="h-6 w-6" /> }
                <StyledText
                    accent={Accent?.apply({})}
                    text={mode === "player"
                        ? `**${host.name}** is setting up the room`
                        : "**Start Game**"
                    }
                />
            </Container>
            {/* { mode === "player" && (
                <SystemMessage message={`Hang Tight! **${host.name}** is setting up the room`} />
            ) } */}
        </div>
    );
};

export default GameStart;
