import type { ObjectID, RoomID } from "@/realize-shared/model/Types";
import type {
    GetRoomDataRequest,
    GetRoomDataResponse,
    HumanDeletionRequest,
    ReportedSubmissionDataType,
    ReportRequest,
} from "@/realize-shared/model/RequestTypes";
import * as Requests from "./Requests";

export const getRoomData = async (
    rooms: [room: RoomID, last: ObjectID][]
) => Requests.post<GetRoomDataRequest, GetRoomDataResponse>(
    false,
    "human/getRoomData",
    { current: rooms.flat() },
);

export const flagForDeletion = async (
    shouldDelete: boolean,
) => Requests.post<HumanDeletionRequest>(
    false,
    "human/flagForDeletion",
    { delete: shouldDelete },
);

export const sendReport = async (
    room: RoomID,
    reportData: ReportedSubmissionDataType,
) => Requests.post<ReportRequest>(
    false,
    "human/sendReport",
    {room, reportData },
);
