import { twMerge } from "tailwind-merge";
import Jagged from "@/assets/img/layout/raw-jagged-button.svg?react";
import type { WindTypes } from "@/util/Tailwind";

const fill = Wind.style({
    position: "absolute",
    width: "w-full",
    height: "h-full",
    top: "top-0",
    left: "left-0",
    pointerEvents: "pointer-events-none",
    transition: "transition-colors ease-in-out",
    transitionDuration: "duration-75",
}).style;

const childrenCentered = Wind.style({
    display: "flex",
    flexDirection: "flex-col",
    alignItems: "items-center",
    justifyContent: "justify-center",
}).style;

const JaggedButton = ({
    color,
    className,
    disabled,
    onClick,
    children,
}: React.PropsWithChildren<{
    className?: string;
    disabled?: boolean;
    onClick: () => unknown;
    color: WindTypes["color"];
}>) => (
    <button
        className={twMerge("font-default h-16 mb-4 translate-x-1", className)}
        disabled={disabled}
        onClick={onClick}
    >
        <Jagged
            className={Wind.mergeProps(fill, { color })}
        />
        <div
            className={Wind.mergeProps(childrenCentered, fill)}
        >
            {children}
        </div>
    </button>
);

export default JaggedButton;
