import type { Type } from "@/realize-shared/model/Event";
import PlayerStatusMessage from "../components/PlayerStatusMessage";
import type { GameNodeProps } from "../GameNodeProps";

const PlayerJoin = (
    event: GameNodeProps<Type.PLAYER_JOIN>
) => (
    <PlayerStatusMessage
        {...event}
        text="$PLAYER has joined"
    />
);

export default PlayerJoin;
