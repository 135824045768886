import React from "react";

import type { Type } from "@/realize-shared/model/Event";
import Ordinal from "@/util/Ordinal";
import type { GameNodeProps } from "../GameNodeProps";
import { useGameContext } from "@/game/GameContext";
import useTheme from "@/game/themes/useTheme";
import Container from "../components/Container";
import ExpandIcon from "@/assets/img/chat/expand.svg?react";
import CollapseIcon from "@/assets/img/chat/collapse.svg?react";
import Polaroid from "../components/Polaroid";

const VoteResult = ({
    winner,
    scoreboard,
    photo,
    eventRef,
}: GameNodeProps<Type.VOTE_RESULT>) =>
{
    const [expanded, setExpanded] = React.useState(false);
    const toggleExpand = () => void setExpanded(!expanded);

    const {rules} = useGameContext();

    const {
        Winner, Heading, Board
    } = useTheme().Results;

    return (
        <div
            ref={eventRef}
        >
            <Container
                {...Winner.container}
                slices={Winner.slices}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        alignItems: "items-center",
                        justifyContent: "justify-between",
                        gap: "gap-5",
                        fontFamily: "font-default",
                    }).class
                }}
            >
                <Polaroid
                    photo={photo}
                    styles={{
                        width: "w-24",
                        transformRotate: "-rotate-6",
                        margin: "m-2",
                        marginTop: "mt-4",
                    }}
                />
                <div>
                    <h1
                        className="text-2xl p-1"
                        style={Winner.Text?.apply()}
                    >
                        <span style={Winner.Accent?.apply()}>
                            {winner.name}
                        </span>
                        {" was chosen!"}
                    </h1>
                    <Container
                        {...Winner.Button.container}
                        slices={Winner.Button.slices}
                        outer={{
                            className: Wind.style({
                                position: "relative",
                                display: "inline-block",
                            }).class,
                            style: Winner.Button.Text?.apply(),
                        }}
                        inner={{
                            className: Wind.style({
                                display: "flex",
                                justifyContent: "justify-center",
                                alignItems: "items-center",
                            }).class,
                        }}
                    >
                        {"+1 Point"}
                    </Container>
                </div>
            </Container>
            <Container
                {...Heading.container}
                slices={Heading.slices}
                outer={{
                    className: Wind.style({
                        zIndex: "z-[1]",
                    }).class
                }}
                inner={{
                    className: Wind.style({
                        display: "flex",
                        alignItems: "items-center",
                        justifyItems: "justify-items-center",
                        justifyContent: "justify-center",
                        alignContent: "content-center",
                        position: "relative",
                        verticalAlign: "align-middle",
                    }).class,
                    style: Heading.Text?.apply({}),
                }}
            >
                <div
                    className={Wind.style({
                        flexGrow: "grow",
                        textAlign: "text-center",
                    }).class}
                >
                    {"Scoreboard"}
                </div>
                <Container
                    {...Heading.Button.container}
                    slices={Heading.Button.slices}
                    outer={{
                        onClick: toggleExpand,
                        style: Heading.Button.Text?.apply(),
                    }}
                >
                    {expanded ? <CollapseIcon className="w-5 h-5" /> : <ExpandIcon className="w-5 h-5" />}
                </Container>
            </Container>
            <Container
                {...Board.container}
                slices={Board.slices}
                outer={{
                    className: Wind.toggle({
                        base: {
                            transition: "transition-opacity ease-in-out",
                            transitionDuration: "duration-100",
                        },
                        falsy: {
                            opacity: "opacity-0",
                            height: "h-0",
                        },
                        truthy: {
                            opacity: "opacity-100",
                        },
                    }).class(expanded)
                }}
                inner={{
                    className: Wind.style({
                        display: "grid",
                        gridTemplateColumns: "grid-cols-[1fr,_2fr,_1fr]",
                        gap: "gap-2",
                        alignItems: "items-end",
                    }).class
                }}
            >
                {scoreboard.map(({ player, score }, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={Wind.style({
                                textAlign: "text-right",
                            }).class}
                            style={Board.Place.apply()}
                        >
                            {/* FIXME: this does not account for ties */}
                            {`${index+1}${Ordinal.from(index+1)}`}
                        </div>
                        <div
                            className={Wind.style({
                                textAlign: "text-left",
                                textWrap: "text-nowrap",
                                overflow: "overflow-hidden",
                                textOverflow: "text-ellipsis",
                            }).class}
                            style={Board.Name.apply()}
                        >
                            {player.name}
                        </div>
                        <div
                            className={Wind.style({
                                textAlign: "text-left",
                            }).class}
                        >
                            <span style={Board.Score.apply()}>
                                {score}
                            </span>
                            <span style={Board.Suffix.apply()}>
                                {Board.Suffix.text.replaceAll("%%", String(rules.pointsToWin))}
                            </span>
                        </div>
                    </React.Fragment>
                ))}
            </Container>
        </div>
    );
};

export default VoteResult;
