import type { DBHeadlinePack } from "@/realize-shared/model/DBHeadlinePack";
import type { DBIconPack } from "@/realize-shared/model/DBIconPack";
import type { DBThemePack } from "@/realize-shared/model/DBThemePack";
import type { Resource, Text, Hash } from "@/realize-shared/model/Types";
import type { UserIcon } from "@/realize-shared/model/UserIcon";
import { processTheme, type Theme } from "@/game/themes/ProcessTheme";
import { fileStore } from "@/api/Requests";

interface Shared<T extends string>
{
    type: T
    slug: Text;
    name: Text;
    blurb: Text,
    locked: boolean;
    price: Text;
    product_id: Text;
}

export type IconPack = Shared<"icon"> & {
    icons: {
        name: Text,
        icons: UserIcon[];
    }[];
};

export type ThemePack = Shared<"theme"> & {
    hash: Hash,
    preview: Resource;
    icon: Resource;
    data: Theme;
};

export type HeadlinePack = Shared<"headline"> & {
    headlines: Text[];
};

export type ShopItem = IconPack | ThemePack | HeadlinePack;

const processOwnership = (
    {product_id, price}: { product_id: string, price: number },
): { product_id: string, price: string; locked: boolean; } =>
{
    const {store} = CdvPurchase;

    // Check if the pack is owned
    const owned = price <= 0 || store.get(product_id)?.owned;

    // TODO: currency conversion
    const localPrice = "$" + (price - 1/100).toFixed(2);

    if (owned)
    {
        return {
            product_id,
            locked: false,
            price: "0",
        };
    }

    return {
        product_id,
        locked: true,
        price: localPrice,
    };
};

export const toHeadlinePack = (data: DBHeadlinePack): HeadlinePack =>
{
    data = { ...data };

    data.slug = `headline-${data.slug}`;

    const product_id = `headline_pack_${data.slug.replace(/-/ug, "_").replace(/headline_|_pack/ug, "")}`;

    return {
        type: "headline",
        ...data,
        blurb: data.blurb ?? `${data.headlines.length} Headlines`,
        ...processOwnership({...data, product_id}),
    };
};

export const toIconPack = (data: DBIconPack): IconPack =>
{
    data = { ...data };

    data.slug = `icon-${data.slug}`;

    if (data.blurb === null)
    {
        const total = data.icons.reduce((c, { icons }) => (c + icons.length), 0);
        const base = data.icons.length;

        data.blurb = `${base} Icons${total-base > 0 ? `, ${total-base} Variants` : ""}`;
    }

    const product_id = `icon_pack_${data.slug.replace(/-/ug, "_").replace(/icon_|_pack/ug, "")}`;

    return {
        type: "icon",
        ...data,
        icons: data.icons.map(({name, icons}) => ({
            name,
            icons: icons.map((hash, i) => ({
                alt: `${name}${i===0?"":` ${i+1}`}`,
                hash,
                path: fileStore(`static/icons/${hash}.webp`)
            })),
        })),
        blurb: data.blurb || "default blurb",
        ...processOwnership({...data, product_id}),
    };
};

export const toThemePack = async (data: DBThemePack): Promise<ThemePack> =>
{
    data = { ...data };

    const product_id = `theme_pack_${data.slug.replace(/-/ug, "_")}`;

    const theme = await processTheme(`${data.definition}.xml`, (i) => fileStore(`static/themes/${i}`));

    return {
        type: "theme",
        ...data,
        hash: data.definition,
        name: theme.Meta.title,
        blurb: theme.Meta.description,
        preview: fileStore(`static/themes/${data.preview}.webp`),
        icon: fileStore(`static/themes/${data.icon}.webp`),
        data: theme,
        ...processOwnership({...data, product_id}),
    };
};
