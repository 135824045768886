import GameStart from "./nodes/GameStart";
import RoundStart from "./nodes/RoundStart";
import Chat from "./nodes/Chat";
import HeadlineCreate from "./nodes/HeadlineCreate";
import PhotoSubmit from "./nodes/PhotoSubmit";
import VoteStart from "./nodes/VoteStart";
import VoteResult from "./nodes/VoteResult";
import PlayerJoin from "./nodes/PlayerJoin";
import PlayerLeave from "./nodes/PlayerLeave";
import GameEnd from "./nodes/GameEnd";
import RoundSkip from "./nodes/RoundSkip";
import RuleChange from "./nodes/RuleChange";

import { Type } from "@/realize-shared/model/Event";
import type { GameNodeProps } from "./GameNodeProps";
import type { ProcessedEvent } from "@/game/events/ProcessedEvent";

const GameNode = (event: GameNodeProps<Type> & {
    setDialogEvent: React.Dispatch<React.SetStateAction<ProcessedEvent<Type> | null>>;
}) =>
{
    switch (event.type)
    {
        case Type.GAME_START:
            return <GameStart {...event} />;

        case Type.ROUND_START:
            return <RoundStart {...event} />;

        case Type.HEADLINE_CREATE:
            return <HeadlineCreate {...event} />;

        case Type.VOTE_START:
            return <VoteStart {...event} />;

        case Type.VOTE_RESULT:
            return <VoteResult {...event} />;

        case Type.PHOTO_SUBMIT:
            return <PhotoSubmit {...event} />;

        case Type.CHAT:
            return (
                <Chat
                    {...event}
                    event={event}
                    reportDialogEvent={event.setDialogEvent}
                />
            );

        case Type.PLAYER_JOIN:
            return <PlayerJoin {...event} />;

        case Type.PLAYER_LEAVE:
            return <PlayerLeave {...event} />;

        case Type.GAME_END:
            return <GameEnd {...event} />;

        case Type.ROUND_SKIP:
            return <RoundSkip {...event} />;

        case Type.RULE_CHANGE:
            return <RuleChange {...event} />;
    }
};

export default GameNode;
