import { useMemo } from "react";
import Polaroid from "./Polaroid";
import HeadlineDisplay from "./HeadlineDisplay";
import { Type } from "@/realize-shared/model/Event";
import { useGameContext } from "@/game/GameContext";
import type { Player, Round } from "@/game/types/Game";
import type { Resource } from "@/realize-shared/model/Types";
import glow from "@/web/img/glow.webp";
import Aspect from "@/components/shared/Aspect";

export default function RoundWinnerWebView()
{
    const { round, events } = useGameContext();

    const lastResult = useMemo(() =>
    {
        if (!round) return null;

        return events.findLast((event) => event.type === Type.VOTE_RESULT) as {
            round: Round;
            winner: Player;
            photo: Resource;
            time: number;
        };
    }, [events, round]);

    if (!lastResult || !round) return null;

    return (
        <div
            className={Wind.style({
                position: "relative",
                display: "flex",
                flexDirection: "flex-col",
                justifyContent: "justify-center",
                alignItems: "items-center",
                width: "w-full",
                height: "h-full"
            }).class}
        >
            <Aspect
                aspect={{w: 1, h: 1}}
                className={Wind.style({
                    position: "absolute",
                    top: "top-[55%]",
                    left: "left-1/2",
                    transformTranslateX: "-translate-x-1/2",
                    transformTranslateY: "-translate-y-1/2",
                    transformScale: "scale-125"
                }).class}
            >
                <img
                    src={glow}
                    className={Wind.style({
                        animation: "animate-spin",
                    }).class}
                    style={{
                        animationDuration: "5s"
                    }}
                />
                <img
                    src={glow}
                    style={{
                        animationDuration: "5s",
                        animation: "spin 5s linear infinite"
                    }}
                />
            </Aspect>
            <HeadlineDisplay headline={lastResult.round.headline ?? ""} />
            <Polaroid
                player={lastResult.winner}
                className="minWidth: max-w-[16.3rem]"
                imagePath={lastResult.photo}
            />
        </div>
    );
}
