import HeadlineBacking from "@/web/img/headline-background.svg?react";
import PinkDots from "@/web/img/pink-dots.svg?react";
import { twMerge } from "tailwind-merge";

const parseText = (text: string) =>
{
    const regex = /\*(?<word>[^*]+)\*/ug;

    const matches = text.matchAll(regex);

    const result: React.ReactNode[] = [];

    let lastIndex = 0;

    for (const match of matches)
    {
        if (match.index > -1)
        {
            const { word } = match.groups as { word: string };

            result.push(text.slice(lastIndex, match.index));

            result.push(
                <span
                    key={word}
                    className={Wind.style({
                        fontWeight: "font-bold",
                        textTransform: "uppercase"
                    }).class}
                >
                    {word}
                </span>,
            );

            lastIndex = match.index + match[0].length;
        }
    }

    result.push(text.slice(lastIndex));

    return result;
};

const HeadlineDecal = () =>
{
    return (
        <div
            className={Wind.style({
                position:"absolute",
                left: "left-1/2",
                top: "top-0",
                transformTranslateX: "-translate-x-1/2",
                transformTranslateY: "-translate-y-1/2",
                backgroundColor: "bg-black",
                paddingY:"py-[0.375rem]",
                paddingX: "px-4",
                filterDropShadow: "drop-shadow-[0px_4px_64px_rgba(244,233,181,0.2)]",
            }).class}

            style={{
                borderBottomRightRadius: "2.75rem",
            }}
        >
            <PinkDots
                className={Wind.style({
                    position: "absolute",
                    right: "right-0",
                    top:"top-0",
                    transformTranslateY: "-translate-y-1/2",
                    transformTranslateX: "-translate-x-1/4"
                }).class}
            />
            <span
                className={Wind.style({
                    fontFamily: "font-default",
                    fontSize:"text-xl",
                    fontWeight: "font-bold",
                    color:"text-parchment",
                }).class}
            >
                Headline
            </span>
        </div>
    );
};

export default function HeadlineDisplay({
    headline,
    className,
}: {
    headline: string;
    className?: string;
})
{
    return (
        <div
            className={twMerge(Wind.style({
                fontFamily: "font-default",
                fontWeight: "font-normal",
                fontSize: "text-xl",
                lineHeight: "leading-[130%]",
                color: "text-dark-blue",
                backgroundColor: "bg-parchment",
                maxWidth: "max-w-[23.875rem]",
                position:"relative",
                paddingY: "py-6",
                paddingX: "px-4",
            }).class, className)}
        >
            <HeadlineBacking
                className={Wind.style({
                    position: "absolute",
                    top: "top-[2.5rem]",
                    left: "left-[2.188rem]",
                    zIndex: "z-0",
                }).class}
            />
            <HeadlineDecal />
            <div
                className={Wind.style({
                    display: "flex",
                    gap: "gap-1",
                    alignItems: "items-stretch",
                    position:"relative"
                }).class}
            >
                <span
                    className={Wind.style({
                        fontFamily: "font-serif",
                        fontSize: "text-[3.5rem]",
                        fontWeight: "font-bold",
                        textTransform: "uppercase",
                    }).class}
                >
                    {headline.substring(0, 1)}
                </span>
                <div>
                    {parseText(headline.substring(1))}
                </div>
            </div>
        </div>
    );
}
