import type { Differentiate } from "@/realize-shared/Types";
import React from "react";

type Div = React.ComponentProps<"div">;
type Button = React.ComponentProps<"button">;

const Box = React.forwardRef((node: Differentiate<Div, Button, "onClick">, ref) =>
    React.createElement(
        ("onClick" in node && node.onClick)
            ? "button"
            : "div",
        { ...node, ref },
    ));

Box.displayName = "Box";

export default Box;
