import * as Cache from "@/util/Cache";
import DefaultRules from "@/realize-shared/model/DefaultRules";
import { Type, parse, type RawEvent } from "@/realize-shared/model/Event";
import * as EventRequests from "@/api/EventRequests";
import type { HumanID, ObjectID, RoomID } from "@/realize-shared/model/Types";
import type { GetRoomEventsResponse } from "@/realize-shared/model/RequestTypes";
import Phase from "@/realize-shared/model/Phase";
import NonePlayer from "@/game/NonePlayer";
import type { GameContext, Room } from "@/game/types/Game";
import { GameUIState } from "../GameUIState";
import processEvent from "./processEvent";
import updateEvents from "./updateEvents";
import { MS_IN_S } from "@/realize-shared/model/Time";
import React from "react";
import { Themes } from "@/game/themes/Themes";

export default class
{
    readonly #alertCache: string;

    #timer: ReturnType<typeof setTimeout> = 0;

    #data: GameContext;

    #dispatch: React.DispatchWithoutAction = () => void 0;

    #alive = true;

    public constructor (
        public readonly room: Room,
        private readonly roomID: RoomID,
        humanID: HumanID,
        private readonly interactive: boolean,
    )
    {
        this.#alertCache = `${roomID}-alerts`;

        this.#data = {
            rendered: 0,

            processed: new Set(),

            alerts: new Set(Cache.get<string[]>(this.#alertCache)),

            gameUIState: GameUIState.CHAT,

            gamePhase: Phase.LOBBY,

            self: { ...NonePlayer },

            host: NonePlayer,

            rules: { ...DefaultRules },

            clock: 0,

            round: null,

            currentPlayers: new Map(),

            scoreboard: [],

            rounds: [],

            events: [] as unknown as GameContext["events"],

            room,

            theme: Themes.default,
        };

        this.#data.self.id = humanID;
    }

    public dispose()
    {
        clearInterval(this.#timer);
        Cache.remove(this.#alertCache);
    }

    public get alive ()
    {
        return this.#alive;
    }

    public get state (): [roomID: RoomID, lastEvent: ObjectID]
    {
        const events = this.#data.events;

        return [ this.roomID, events[events.length - 1].id];
    }

    public get read(): Readonly<GameContext>
    {
        return { ...this.#data };
    }

    private clock()
    {
        const { round, gamePhase } = this.#data;

        clearInterval(this.#timer);

        const timeout = round?.timeout ?? 0;

        if (!timeout || gamePhase === Phase.CLOSED)
        {
            return void this.write({clock: 0}, true);
        }

        const PARTIAL = MS_IN_S / 10;

        const tick = () =>
        {
            const time = Math.max(0, timeout - Date.now());

            const seconds = Math.round(time / MS_IN_S);
            const ms = Math.round(time % MS_IN_S);
            const milliseconds = ms < PARTIAL? MS_IN_S: ms;

            this.write({clock: seconds}, true);

            if (seconds <= 0) return;

            this.#timer = setTimeout(tick, milliseconds);
        };

        tick();
    }

    public write(
        data: Partial<GameContext> | ((current: GameContext) => Partial<GameContext>),
        dispatch: boolean,
    )
    {
        if (typeof data === "function")
        {
            data = data({...this.#data});
        }

        Object.assign(this.#data, data);

        if (typeof data.alerts !== "undefined")
        {
            Cache.set<string[]>(this.#alertCache, [...data.alerts.values()]);
        }

        if (typeof data.gamePhase !== "undefined") this.clock();

        if (dispatch) this.dispatch();
    }

    public get dispatch()
    {
        return this.#dispatch;
    }

    public mount (dispatch: React.DispatchWithoutAction)
    {
        this.#dispatch = dispatch;
    }

    public update = async (data: GetRoomEventsResponse) =>
    {
        const events = data.events;

        let hasMore = data.hasMore;

        Logger.debug("update", this.roomID);

        while (hasMore)
        {
            const lastHash = events[events.length - 1][1];

            Logger.debug("chunk", this.roomID, lastHash);

            data = await EventRequests.getEvents(this.roomID, lastHash);

            events.push(...data.events);
            hasMore = data.hasMore;
        }

        this.receiveData(events);
    };

    /**
     * Processes incoming chat data and stores it
     */
    public readonly receiveData = (raw: RawEvent[]) =>
    {
        AssertFalse(raw.length === 0, "Empty event array");

        Logger.log("received", raw.length);
        Logger.debug("received", raw);

        const incoming = raw.map((raw) =>
        {
            const event = parse(raw[0], raw);

            if (event.type === Type.GAME_END)
            {
                this.#alive = false;
            }

            return event;
        }).sort((a, b) =>
        {
            AssertTrue(a.id !== b.id, "Duplicate ids");

            return a.time - b.time;
        })
            .filter((event) =>
            {
                if (this.#data.processed.has(event.id))
                {
                    Logger.warn(`Found duplicate event ${JSON.stringify(event)}`);

                    return false;
                }

                this.#data.processed.add(event.id);

                return true;
            });

        if (incoming.length === 0) return;

        for (const rawEvent of incoming)
        {
            const {event, state} = processEvent(this.#data, rawEvent);

            const shouldUpdate = Object.keys(state).length > 0;

            this.write(state, false);

            const { updated, events } = updateEvents(event, this.read, this.interactive);

            this.write({
                events: events as GameContext["events"],
            }, shouldUpdate || updated);
        }

        this.write(({processed}) => ({ processed: new Set(processed)}), true);
    };
}
