import type { Rules } from "../model/Types";

type RuleFlags = Readonly<{
    [key in keyof Rules]: boolean;
}>;

const LOBBY_ONLY_RULES: RuleFlags = {
    pointsToWin: true,
    roundLength: true,
    visibility: false,
    editor: true,
};

const getRuleUpdates = (previous: Rules, current: Rules) =>
{
    let lobbyChanges = false, changes = false;

    const changed: RuleFlags = {
        pointsToWin: previous.pointsToWin !== current.pointsToWin,
        roundLength: previous.roundLength !== current.roundLength,
        visibility: previous.visibility !== current.visibility,
        editor: previous.editor !== current.editor,
    };

    Object.keys(changed)
        .forEach((rule) =>
        {
            if (!changed[rule as keyof RuleFlags]) return;
            changes = true;
            if (!LOBBY_ONLY_RULES[rule as keyof RuleFlags]) return;
            lobbyChanges = true;
        });

    return { lobbyChanges, changes };
};

export default getRuleUpdates;
