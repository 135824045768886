import CopyIcon from "@/assets/img/symbolic/copy.svg?react";
import ReportIcon from "@/assets/img/symbolic/report.svg?react";
import React from "react";
import { twMerge } from "tailwind-merge";

const ChatContextMenu = ({
    close,
    onCopy,
    onReport,
}: {
    close: () => void;
    onCopy: () => void;
    onReport: () => void;
}) =>
{
    const iconStyles = Wind.style({
        color: "text-[#6784B0]",
        width: "w-4",
        height: "h-4",
        marginRight: "mr-1"
    }).class;

    const buttonStyles = Wind.style({
        display: "flex",
        alignItems: "items-center",
        justifyContent: "justify-start",
        padding: "p-1",
        paddingRight: "pr-0",
        width: "w-[100%]",
        height: "h-full"

    }).class;


    // Create a mouse listener to close the context menu when the user clicks outside of it
    React.useEffect(() =>
    {
        const listener = (e: MouseEvent) =>
        {
            if (!(e.target as HTMLElement).closest(".context-menu"))
            {
                e.stopPropagation();
                close();
            }
        };

        document.addEventListener("click", listener);

        return () =>
        {
            document.removeEventListener("click", listener);
        };
    }, [close]);

    return (
        <nav
            aria-label="context menu"
            className={twMerge(
                "context-menu",
                Wind.style({
                    backgroundColor: "bg-[#1B2F4E]",
                    color: "text-white",
                    fontSize: "text-xs",
                    borderRadius: "rounded",
                    padding: "p-1",
                    width: "w-28",
                    alignSelf: "self-center",
                    marginLeft: "ml-1",
                    marginRight: "mr-1",
                    marginBottom: "mb-2",
                    position: "fixed",
                    zIndex: "z-50",
                    boxShadow: "shadow-md",
                    boxShadowColor: "shadow-[rgba(0,0,0,0.35)]",
                }).class
            )}
        >
            <ul className="chat-context-menu__items">
                <li className="chat-context-menu__item">
                    <button
                        className={buttonStyles}
                        onClick={onCopy}
                    >
                        <CopyIcon
                            className={iconStyles}
                            aria-hidden="true"
                        />
                        {" "}
                        <span>Copy Text</span>
                    </button>
                </li>
                <li className="chat-context-menu__item">

                    <button
                        className={buttonStyles}
                        onClick={onReport}
                    >
                        <ReportIcon
                            className={iconStyles}
                            aria-hidden="true"
                        />
                        {" "}
                        Report
                    </button>
                </li>
            </ul>
        </nav>

    );
};

export default ChatContextMenu;
