import type { HumanID, RoomID, Rules, Text } from "./Types";
import * as Time from "./Time.js";
import Phase from "./Phase.js";
import { model } from "./model.js";

export interface DBRoom
{
    _v: 1,
    roomID: RoomID;
    issue: number;
    roundCount: number;
    headlines: Text[];
    rules: Rules;
    state:
    {
        clockEnd: number;
        phase: Phase;
    };
    staleCount: number;
    host: HumanID;
    editor: HumanID;
    editorOrder: HumanID[];
    players: HumanID[];
}

export const [dbRoom, buildDBRoom] = model<
    DBRoom,
    | "_v"
    | "roundCount"
    | "headlines"
    | "rules"
    | "staleCount"
    | "editor"
    | "editorOrder"
    | "players"
>({
    _v: 1,
    roomID: "",
    issue: 0,
    roundCount: 0,
    headlines: [],
    rules: {
        pointsToWin: 3,
        roundLength: 10 * Time.MS_IN_M,
        visibility: "private",
        editor: "random",
    },
    state: {
        clockEnd: 0,
        phase: Phase.LOBBY,
    },
    staleCount: 0,
    host: "",
    editor: "",
    editorOrder: [],
    players: [],
});
