import type { Player, Round } from "@/game/types/Game";
import RoundBackground from "@/web/img/round-bg.svg?react";
import SubmissionBackground from "@/web/img/submission-background.svg?react";

const RoundTitle = ({
    roundNumber,
}: {
    roundNumber: number;
}) =>
{
    return (
        <div className="relative">
            <h1
                className={Wind.style({
                    fontFamily: "font-default",
                    fontWeight: "font-black",
                    fontSize: "text-8xl",
                    color: "text-white",
                    marginBottom: "mb-6",
                    filterDropShadow: "drop-shadow-[0px_4px_64px_rgba(244,233,181,0.20)]",
                }).class}
            >
                <RoundBackground
                    className={Wind.style({
                        position: "absolute",
                        left: "left-0",
                        bottom: "bottom-0",
                        transformTranslateX: "-translate-x-[1.5rem]",
                        transformTranslateY: "translate-y-[0.25rem]",
                    }).class}
                />
                <div className="relative">
                    {`Round ${roundNumber + 1}`}
                </div>
            </h1>
        </div>
    );
};

const PlayerSubmitted = ({
    totalSubmissions,
    players,
}: {
    totalSubmissions: number;
    players: Player[];
}) =>
{
    return (
        <div className="relative">
            <SubmissionBackground />
            <span
                className={Wind.style({
                    position: "absolute",
                    inset: "inset-0",
                    display: "flex",
                    alignItems: "items-center",
                    justifyContent: "justify-center",
                    color: "text-white",
                    gap: "gap-4"
                }).class}
            >
                <div
                    className={Wind.style({
                        fontFamily: "font-default",
                        fontWeight: "font-bold",
                        fontSize: "text-[2rem]",
                        lineHeight: "leading-6",
                        color: "text-white",
                    }).class}
                >
                    {`${totalSubmissions}/${players.length - 1}`}
                </div>
                <span
                    className={Wind.style({
                        color: "text-[#9AAEC6]",
                        fontFamily: "font-default",
                        fontWeight: "font-normal",
                        fontSize: "text-2xl",
                        lineHeight: "leading-6",
                    }).class}
                >
                    Players Submitted
                </span>
            </span>
        </div>
    );
};

export default function RoundCentral({
    round,
}: {
    round: Round;
})
{
    const submissions = round.submissions.size;

    return (
        <div
            className={Wind.style({
                position: "relative",
                display: "flex",
                flexDirection: "flex-col",
            }).class}
        >
            <div>
                <RoundTitle roundNumber={round.number} />
            </div>
            <div
                className={Wind.style({
                    position:"relative",
                    display: "flex",
                    flexDirection: "flex-row",
                    width: "w-full",
                    gap: "gap-8",
                    alignItems: "items-center",
                    justifyContent: "justify-center",
                    marginBottom: "mb-7"
                }).class}
            >
                {Array.from(round.players.values()) .map((player) =>
                {
                    if (player.id === round.editor.id) return;

                    const hasSubmitted = round.submissions.has(player.id);

                    return (
                        <img
                            key={player.id}
                            src={player.avatar.path}
                            alt={`Avatar of ${player.name}`}
                            className={Wind.style({
                                width: "w-12",
                                height: "h-12",
                                borderRadius: "rounded-full",
                                borderWidth: "border-4",
                                borderColor: "border-[#1B3555]",
                                borderStyle: "border-solid",
                                filterGrayscale: hasSubmitted ? "grayscale-0": "grayscale",
                                opacity: hasSubmitted ? "opacity-100": "opacity-50",
                            }).class}
                        />
                    );
                })}
            </div>
            <div>
                <PlayerSubmitted
                    totalSubmissions={submissions}
                    players={Array.from(round.players.values())}
                />
            </div>
        </div>
    );
}
