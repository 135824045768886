import React from "react";
import ThemeContext from "./ThemeContext";

export default function useTheme()
{
    const context = React.useContext(ThemeContext);

    AssertIsset(context, "useTheme must be used within a ThemeContext");

    return context;
}
