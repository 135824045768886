import * as React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

type Component = ({ children }: React.PropsWithChildren) => React.ReactElement;
type ComponentTag = keyof React.JSX.IntrinsicElements;
type ComponentEntry = [ComponentTag, React.HTMLAttributes<ComponentTag>];
type ComponentSpec = Partial<Record<ComponentEntry[0], ComponentEntry[1]>>;
type ComponentData = Partial<Record<ComponentEntry[0], Component | ComponentTag>>;

// https://github.com/remarkjs/react-markdown?tab=readme-ov-file#appendix-b-components
const DISABLE_ALL_FORMATTING: React.ComponentProps<typeof ReactMarkdown>["components"] = {
    // base
    a: "span",
    blockquote: "span",
    br: "span",
    code: "span",
    em: "span",
    h1: "span",
    h2: "span",
    h3: "span",
    h4: "span",
    h5: "span",
    h6: "span",
    hr: "span",
    img: "span",
    li: "span",
    ol: "span",
    p: "span",
    pre: "span",
    strong: "span",
    ul: "span",

    // gfm
    del: "span",
    input: "span",
    table: "span",
    tbody: "span",
    td: "span",
    th: "span",
    thead: "span",
    tr: "span"
};

const BASIC_COMPONENTS: ComponentSpec = {
    code: {},
    em: {},
    pre: {},
    strong: {},
    del: {},
    blockquote: {},
    li: {},
};

export default function Formatted({
    components: rawComponents,
    basic,
    children,
}: {
    components?: ComponentSpec,
    basic?: boolean,
    children: string,
})
{
    const components: ComponentData = {};

    rawComponents = basic? { ...BASIC_COMPONENTS, ...rawComponents }: { ...rawComponents };

    for (const [tag, props] of Object.entries(rawComponents) as ComponentEntry[])
    {
        components[tag] = ({children}) => React.createElement(tag, props, children);
    }

    return (
        <ReactMarkdown
            components={{
                ...DISABLE_ALL_FORMATTING,
                ...components,
            }}
            unwrapDisallowed
            skipHtml
            remarkPlugins={[ remarkGfm ]}
        >
            {children}
        </ReactMarkdown>
    );
}
