import {type Event, Type } from "@/realize-shared/model/Event";
import * as EventPair from "@/realize-shared/model/EventPair";

import type { GameContext, Round } from "@/game/types/Game";

import { EventState } from "./ProcessedEventMap";
import Phase from "@/realize-shared/model/Phase";
import type { Player } from "@/game/types/Game";
import type { HumanID, Resource } from "@/realize-shared/model/Types";
import { Icons } from "../Icons";
import type { ProcessedEvent } from "./ProcessedEvent";
import { Themes } from "@/game/themes/Themes";
import getRuleUpdates from "@/realize-shared/helpers/getRuleUpdates";
import buildScoreboard from "./helpers/buildScoreboard";
import updateRound from "./helpers/updateRound";
import { fileStore } from "@/api/Requests";

const base = <EventType extends Type>(
    event: Event<EventType>,
    game: Readonly<GameContext>
): Pick<ProcessedEvent<EventType>, "index" | "time" | "id" | "type"> => ({
    id: event.id,
    time: event.time,
    type: event.type,
    index: game.events.length,
});

const ExtendedEventBuilderMap: {
    [EventType in Type]: (
        game: Readonly<GameContext>,
        event: Event<EventType>,
        state?: Partial<GameContext>,
    ) => {
        event: ProcessedEvent<EventType>,
        state: Partial<GameContext>,
    };
} = {
    [Type.GAME_START]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { round, theme } = game;
        const [ issue, serverVersionInfo ] = data;

        state.gamePhase = Phase.LOBBY;

        AssertTrue(round === null, "game started during game");

        return {
            event: {
                round: null,
                state: EventState.ACTIVE,
                theme,
                issue,
                serverVersionInfo,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.PLAYER_JOIN]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { round, currentPlayers, self, theme } = game;
        const [ clientVersionInfo, playerID, playerName, playerAvatar, playerThemeID ] = data;

        AssertTrue(round === null, "player joined during game");

        const playerTheme = (() =>
        {
            try
            {
                return Themes.get(playerThemeID);
            }
            catch (e)
            {
                Logger.warn(e);

                return Themes.default;
            }
        })();

        const playerData: Player = {
            avatar: Icons.get(playerAvatar),
            theme: playerTheme,
            id: playerID,
            name: playerName,
            score: 0,
        };

        const player = Object.assign(
            (self.id === playerID) ? self : {},
            playerData,
        );

        if (currentPlayers.size === 0)
        {
            state.host = player;
            state.theme = playerTheme;
        }

        currentPlayers.set(playerID, player);
        state.currentPlayers = new Map(currentPlayers);

        return {
            event: {
                round: null,
                state: EventState.STATIC,
                theme,
                clientVersionInfo,
                player,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.CHAT]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { round, currentPlayers, theme } = game;
        const [ senderID, message ] = data;

        const sender = currentPlayers.get(senderID);

        AssertIsset(sender, "player not found");

        return {
            event: {
                round,
                state: EventState.STATIC,
                theme,
                message,
                sender,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.PLAYER_LEAVE]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { round, currentPlayers, theme } = game;
        const [ playerID, hostID, reason ] = data;

        const player = currentPlayers.get(playerID);

        AssertIsset(player, "player not found");

        const host = (() =>
        {
            if (hostID === "") return game.host;

            const host = currentPlayers.get(hostID);

            AssertIsset(host, "player not found");
            state.host = host;

            return host;
        })();

        currentPlayers.delete(playerID);
        state.currentPlayers = new Map(currentPlayers);

        let updatedRound = round;

        if (round)
        {
            const active = new Set(round.active);

            active.delete(player);

            updatedRound = updateRound(game, state, game.rounds.length - 1, () => ({ active }));
        }

        return {
            event: {
                round: updatedRound,
                state: EventState.STATIC,
                theme,
                player,
                reason,
                host,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.GAME_END]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { round, theme } = game;
        const [ reason ] = data;

        state.gamePhase = Phase.CLOSED;

        return {
            event: {
                round,
                state: EventState.STATIC,
                theme,
                reason,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.RULE_CHANGE]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { round, theme, rules } = game;
        const [ changes ] = data;

        AssertTrue(
            round === null || !getRuleUpdates(rules, changes).lobbyChanges,
            "settings changed during game",
        );

        state.rules = changes;

        return {
            event: {
                round: null,
                state: EventState.STATIC,
                theme,
                changes,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.ROUND_START]: (game, event, state = {}) =>
    {
        const { time, data } = event;
        const [ roundNumber, editorID ] = data;
        const { currentPlayers, rounds, rules, scoreboard, events } = game;

        const editor = currentPlayers.get(editorID);

        AssertIsset(editor, "player not found");

        AssertTrue(
            rounds.length === roundNumber,
            `round number mismatch s:${rounds.length} e:${roundNumber}`,
        );

        const round: Round = {
            number: roundNumber,
            submissions: new Map<HumanID, Resource>(),
            players: new Map(currentPlayers),
            active: new Set(currentPlayers.values()),
            editor,
            headline: null,
            phase: Phase.HEADLINE_CREATION,
            timeout: time + rules.roundLength,
            scoreboard,
            startIndex: events.length,
            endIndex: -1,
        };

        state.rounds = [...rounds, round];
        state.round = round;
        state.gamePhase = Phase.HEADLINE_CREATION;
        state.theme = editor.theme;

        return {
            event: {
                round,
                state: EventState.ACTIVE,
                theme: editor.theme,
                editor,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.HEADLINE_CREATE]: (game, event, state = {}) =>
    {
        const { time, data } = event;
        const { rules, theme } = game;
        const [ roundNumber, headline ] = data;

        const updatedRound = updateRound(game, state, roundNumber, () => ({
            headline: headline,
            phase: Phase.PHOTO_SUBMISSION,
            timeout: time + rules.roundLength,
        }));

        state.gamePhase = Phase.PHOTO_SUBMISSION;

        return {
            event: {
                round: updatedRound,
                state: EventState.ACTIVE,
                theme,
                headline,
                ...base(event, game),
            },
            state
        };
    },

    [Type.PHOTO_SUBMIT]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { theme } = game;
        const [ roundNumber, playerID, rawPhoto ] = data;

        const photo = fileStore(rawPhoto);

        const updatedRound = updateRound(game, state, roundNumber, ({submissions}) =>
        {
            submissions = new Map(submissions);
            submissions.set(playerID, photo);

            return { submissions };
        });

        const player = updatedRound.players.get(playerID);

        AssertIsset(player, "player not found");

        return {
            event: {
                round: updatedRound,
                state: EventState.STATIC,
                theme,
                player,
                photo,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.VOTE_START]: (game, event, state = {}) =>
    {
        const { time, data } = event;
        const { rules, theme } = game;
        const [ roundNumber ] = data;

        const updatedRound = updateRound(game, state, roundNumber, () => ({
            phase: Phase.VOTING,
            timeout: time + rules.roundLength,
        }));

        state.gamePhase = Phase.VOTING;

        const submissions = [...updatedRound.submissions.entries()]
            .map(([id, photo]) =>
            {
                const player = updatedRound.players.get(id);

                AssertIsset(player, "player not found");

                return { player, photo };
            });

        return {
            event: {
                round: updatedRound,
                state: EventState.ACTIVE,
                theme,
                ...base(event, game),
                submissions,
            },
            state,
        };
    },

    [Type.VOTE_RESULT]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { currentPlayers, theme, round, events } = game;
        const [ roundNumber, winnerID ] = data;

        AssertIsset(round, "round is null");

        const winner = round.players.get(winnerID);
        const photo = round.submissions.get(winnerID);

        AssertIsset(winner, "player not found");
        AssertIsset(photo, "submission not found");

        winner.score++;

        const scoreboard = buildScoreboard(game);

        const updatedRound = updateRound(game, state, roundNumber, () => ({
            phase: Phase.CLOSED,
            scoreboard,
            endIndex: events.length,
        }));

        state.currentPlayers = new Map(currentPlayers);
        state.scoreboard = scoreboard;

        return {
            event: {
                round: updatedRound,
                state: EventState.ACTIVE,
                theme,
                winner,
                photo,
                scoreboard,
                ...base(event, game),
            },
            state,
        };
    },

    [Type.ROUND_SKIP]: (game, event, state = {}) =>
    {
        const { data } = event;
        const { theme } = game;
        const [ roundNumber, reason ] = data;

        const updatedRound = updateRound(game, state, roundNumber, () => ({
            phase: Phase.CLOSED,
        }));

        return {
            event: {
                round: updatedRound,
                state: EventState.STATIC,
                theme,
                reason,
                ...base(event, game),
            },
            state,
        };
    },
};

export default function processEvent<EventType extends Type>(
    this: void,
    game: Readonly<GameContext>,
    rawEvent: Event<EventType>,
)
{
    const { event, state } = ExtendedEventBuilderMap[rawEvent.type](game, rawEvent);
    const { events, gamePhase } = game;

    const currentPhase = state.gamePhase ?? gamePhase;
    const previous = events[events.length - 1];

    Logger.debug(events.length, previous, event);

    if (events.length > 0)
    {
        AssertTrue(
            EventPair.check(currentPhase, previous.type, event.type),
            () =>
            {
                const err = `Invalid event pairing: state=${currentPhase}, last=${previous.type}, now=${event.type}`;

                Logger.error(err, events);

                return err;
            },
        );
    }
    else
    {
        AssertTrue(
            event.type === Type.GAME_START && currentPhase === Phase.LOBBY,
            () =>
            {
                const err = `Invalid first event: state=${gamePhase}, now=${event.type}`;

                Logger.error(err, events);

                return err;
            },
        );
    }

    return {
        event: {
            ...event,
            index: events.length,
        },
        state,
    };
}
