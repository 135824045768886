import React, { type ChangeEvent } from "react";
import type { ReportedSubmissionDataType } from "./ReportTypes";
import type { ReactState } from "@/game/types/ReactTypes";

type Props = {

} & ReactState<"reportStage", number> & ReactState<"reportSubmissionData", ReportedSubmissionDataType>;

const ReportCategory: React.FC<Props> = ({
    reportStage,
    setReportStage,
    reportSubmissionData,
    setReportSubmissionData
}) =>
{
    const reportCategories = [
        //Each sub-array is a column
        [
            "Harassment",
            "Threats",
            "Illicit Materials",
        ],
        [
            "I Don't Like This",
            "Other"
        ]
    ];

    const handleReportSelection = (event: ChangeEvent<HTMLInputElement>) =>
    {
        setReportStage(reportStage + 1);
        setReportSubmissionData({ ...reportSubmissionData, category: event.target.value });
    };

    const renderOptGroups = (groups: string[][]) =>
        groups.map((group, index: number) =>
        {
            return (
                <div
                    id={`report-optgroup-${index + 1}`}
                    key={group[1]}
                    className={Wind.style({
                        fontFamily: "font-default",
                        fontSize: "text-base",
                        fontWeight: "font-semibold",
                    }).class}
                >
                    {renderOptions(group, index + 1)}
                </div>
            );
        });


    const renderOptions = (categories: string[], groupNum: number) =>
        categories.map((category, index) =>
        {
            return (
                <label
                    id={`report-optgroup-${groupNum}-opt-${index + 1}`}
                    className={Wind.style({
                        display: "block",
                        marginBottom: "mb-6",
                        ":hover": {
                            cursor: "hover:cursor-wait"
                        }
                    }).class}
                    key={category}
                >
                    <input
                        type='radio'
                        id={`option-${category.toLowerCase().replace(" ", "-")}`}
                        name='category'
                        value={category}
                        className={Wind.style({
                            paddingBottom: "pb-5",
                            visibility: "invisible",
                            width: "w-0"
                        }).class}
                        onChange={(event) => void handleReportSelection(event)}
                    />
                    {category}
                </label>
            );
        });

    return (
        <>
            <p
                className={Wind.style({
                    color: "text-[#B3C2DB]",
                    display: "block",
                    marginBottom: "mb-8",
                    fontFamily: "font-default",
                    fontWeight: "font-normal",
                    fontSize: "text-base",
                }).class}
            >
                Let us know what seems to be the issue:
            </p>
            <div
                id='report-categories'
                className={Wind.style({
                    backgroundColor: "bg-[#1B2F4E]",
                    fontSize: "text-sm",
                    columns: "columns-2"
                }).class}
            >
                {reportCategories.length > 0 && renderOptGroups(reportCategories)}
            </div>
        </>

    );
};

export default ReportCategory;
