import type { Player } from "@/game/types/Game";
import EditorBackground from "@/web/img/editor-background.svg?react";
import EditorIcon from "@/assets/img/editor-symbol.svg?react";

export default function CurrentEditor({
    editor,
}: {
    editor: Player;
})
{
    return (
        <div
            className={Wind.style({
                position:"relative",
            }).class}
        >
            <EditorBackground
                className={Wind.style({
                    position: "absolute",
                    top: "top-1/2",
                    left: "left-0",
                    transformTranslateY: "-translate-y-1/2",
                    transformTranslateX: "-translate-x-4",
                    width: "w-full",
                }).class}
            />
            <div
                className={Wind.style({
                    position: "relative",
                    display: "flex",
                    alignItems: "items-center",
                    justifyContent: "justify-start",
                    height: "h-14",
                    color: "text-white",
                    gap:"gap-6"
                }).class}
            >
                <img
                    src={editor.avatar.path}
                    className={Wind.style({
                        width: "w-14",
                        height:"h-14",
                        borderRadius: "rounded-full",
                    }).class}
                />
                <div>
                    <div
                        className={Wind.style({
                            fontSize: "text-xl",
                            lineHeight: "leading-6",
                            fontWeight: "font-bold",
                            color: "text-sharp-red",
                            display:"flex",
                            alignItems: "items-center",
                            gap: "gap-2",
                        }).class}
                    >
                        <EditorIcon />
                        {editor.name}
                    </div>
                    <div
                        className={Wind.style({
                            fontSize: "text-sm",
                            lineHeight: "leading-6",
                            color: "text-parchment",
                            fontFamily: "font-default",
                            fontWeight: "font-medium",
                        }).class}
                    >
                        current Editor-in-Chief
                    </div>
                </div>
            </div>
        </div>
    );
}
